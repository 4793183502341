// src/components/ChatMessage/index.tsx
import React from "react";
import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

interface ChatMessageProps {
  role: string;
  content: string;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ role, content }) => {
  const isUser = role === "user";
  const className = `inline-block p-2 ${
    isUser
      ? "bg-white rounded-tl-xl rounded-tr-xl rounded-bl-xl ml-auto"
      : "bg-pf-purple rounded-tl-xl rounded-tr-xl rounded-br-xl mr-auto"
  }`;

  const textClassName = `${
    isUser ? "text-pf-purple text-right" : "text-white text-left pb-2"
  }`;

  console.log(content);

  return (
    <div className={`${className} text-xl font-bold mb-3`}>
      {content === "" ? (
        <div className="text-2xl px-2 animate-bounce text-white">...</div>
      ) : (
        <ReactMarkdown
          components={{
            h1: ({ node, ...props }) => (
              <h1 className={`${textClassName} text-xl font-bold`} {...props} />
            ),
            h2: ({ node, ...props }) => (
              <h2 className={`${textClassName} text-lg font-bold`} {...props} />
            ),
            h3: ({ node, ...props }) => (
              <h3 className={`${textClassName} text-md font-bold`} {...props} />
            ),
            h4: ({ node, ...props }) => (
              <h4 className={`${textClassName} text-md font-bold`} {...props} />
            ),
            p: ({ node, ...props }) => (
              <p
                className={`${textClassName} text-base font-normal`}
                {...props}
              />
            ),
            a: ({ node, ...props }) => (
              <a
                className={`${textClassName} text-base text-blue-500`}
                {...props}
              />
            ),
            img: ({ node, ...props }) => (
              <img
                className={`${textClassName} text-base rounded-full`}
                {...props}
              />
            ),
            ul: ({ node, ...props }) => (
              <ul
                className={`${textClassName} text-base font-normal list-disc`}
                {...props}
              />
            ),
            ol: ({ node, ...props }) => (
              <ol
                className={`${textClassName} text-base font-normal list-decimal`}
                {...props}
              />
            ),
            li: ({ node, ...props }) => (
              <li
                className={`${textClassName} text-base font-normal ml-5`}
                {...props}
              />
            ),
            blockquote: ({ node, ...props }) => (
              <blockquote
                className={`${textClassName} text-base pl-4 italic border-l-4`}
                {...props}
              />
            ),
            pre: ({ node, ...props }) => (
              <pre
                className={`${textClassName} text-base p-2 rounded bg-gray-800 text-white`}
                {...props}
              />
            ),
            code: ({ node, ...props }) => (
              <code
                className={`${textClassName} text-base font-mono`}
                {...props}
              />
            ),
            em: ({ node, ...props }) => (
              <em className={`${textClassName} text-base italic`} {...props} />
            ),
            strong: ({ node, ...props }) => (
              <strong
                className={`${textClassName} text-base font-bold`}
                {...props}
              />
            ),
            del: ({ node, ...props }) => (
              <del
                className={`${textClassName} text-base line-through`}
                {...props}
              />
            ),
            table: ({ node, ...props }) => (
              <table
                className={`${textClassName} text-base table-auto`}
                {...props}
              />
            ),
            thead: ({ node, ...props }) => (
              <thead
                className={`${textClassName} text-base bg-gray-200`}
                {...props}
              />
            ),
            tbody: ({ node, ...props }) => (
              <tbody className={textClassName} {...props} />
            ),
            tr: ({ node, ...props }) => (
              <tr className={textClassName} {...props} />
            ),
            td: ({ node, ...props }) => (
              <td
                className={`${textClassName} text-base border px-4 py-2`}
                {...props}
              />
            ),
            th: ({ node, ...props }) => (
              <th
                className={`${textClassName} text-base border px-4 py-2`}
                {...props}
              />
            ),
          }}
        >
          {content}
        </ReactMarkdown>
      )}
    </div>
  );
};

export default ChatMessage;
