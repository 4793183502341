import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import UserInput from "./UserInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faSquare,
  faMicrophone,
  faImage,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import RecordRTC from "recordrtc";
import AWS from "aws-sdk";
import { CircularProgress } from "@mui/material";
import { Tooltip } from "flowbite-react";
import { ReactComponent as WriteHere2 } from "../../../assets/write-here-2.svg";
import { ReactComponent as WriteHere } from "../../../assets/write-here.svg";
import { ReactComponent as Circled } from "../../../assets/border-circle.svg";
import { ReactComponent as SpeakToMe } from "../../../assets/speak-to-me.svg";
import { ReactComponent as SendToMe } from "../../../assets/send-to-me.svg";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import PathFinderButton from "../../../components/common/PathFinderButton";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

type PromptInputProps = {
  userPrompt: string;
  setUserPrompt: (prompt: string) => void;
  onPromptSubmission: () => void;
  onFileUpload: (file: File) => void;
  assistantWriting: boolean;
  handleCancelRun: () => void;
  runActive: boolean;
  numMessages: number; // 1 is initial screen, 2 is first reply and 3 is onwards
  focus?: "neutral" | "chat" | "page" | "full";
  setFocus?: React.Dispatch<
    React.SetStateAction<"neutral" | "chat" | "page" | "full">
  >;
  isCancelling?: boolean;
  userEmail?: string;
  handleImagePromptSubmission: (
    imageResponse: string,
    s3Key?: string,
    override?: boolean
  ) => Promise<void>;
  handleImagePromptSubmissionHelper: (
    imageUrl?: string,
    override?: boolean
  ) => Promise<void>;
  handleFindImagesClick?: () => void;
  handleCreatePageClick?: () => void;
  handleSuggestOptionsClick?: () => void;
};

const PromptInput: React.FC<PromptInputProps> = ({
  userPrompt,
  setUserPrompt,
  onPromptSubmission,
  onFileUpload,
  assistantWriting,
  handleCancelRun,
  runActive,
  numMessages,
  focus,
  setFocus,
  isCancelling,
  userEmail,
  handleImagePromptSubmission,
  handleImagePromptSubmissionHelper,
  handleFindImagesClick,
  handleCreatePageClick,
  handleSuggestOptionsClick,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [addedHeight, setAddedHeight] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const recorder = useRef(null);
  const recordingInterval = useRef(null);
  const [isProcessingMic, setIsProcessingMic] = useState(false);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [imageInputPrompt, setImageInputPrompt] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const accessKey = "AKIA4DD5CFI6WKNH4HXC";
  const secretAccessKey = "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";

  const s3Client = new S3Client({
    region: "us-east-1",
    credentials: {
      accessKeyId: accessKey,
      secretAccessKey: secretAccessKey,
    },
  });

  // Configure AWS SDK
  AWS.config.update({
    accessKeyId: accessKey,
    secretAccessKey: secretAccessKey,
    region: "us-east-1",
    signatureVersion: "v4",
  });

  const s3 = new AWS.S3();

  const uploadImageToS3 = async (file: File, userEmail: string) => {
    const fileName = `${new Date().getTime()}-${file.name}`;
    const folderName = userEmail || "noEmail";
    const s3Key = `${folderName}/${fileName}`;

    const putObjectCommand = new PutObjectCommand({
      Bucket: "pathfinder-kid-files",
      Key: s3Key,
      Body: file,
      ContentType: file.type,
      ACL: "private",
    });

    try {
      // handleImagePromptSubmissionHelper(imageURL)
      setImageInputPrompt(null);
      await s3Client.send(putObjectCommand);
      const presignedUrl = await getPresignedUrl(s3Key);
      handleImagePromptSubmissionHelper(presignedUrl);

      const backendResponse = await axios.get(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getImageResponse",
        {
          params: {
            presignedUrl: presignedUrl,
            userPrompt: userPrompt,
          }
        }
      );

      // console.log("Image response in prompt input: ", backendResponse.data.imgResponse)
      // console.log("File uploaded successfully:", data);
      return {
        imgResponse: backendResponse.data.imgResponse,
        presignedUrl,
        s3Key,
      };
    } catch (error) {
      // console.error("Error uploading to S3:", error);
      throw error;
    }
  };

  const getPresignedUrl = async (key: string) => {
    const params = {
      Bucket: "pathfinder-kid-files",
      Key: key,
      Expires: 60 * 5, // the URL will expire in 5 minutes
    };

    try {
      const url = await s3.getSignedUrlPromise("getObject", params);
      return url;
    } catch (error) {
      console.error("Error generating pre-signed URL", error);
      return null;
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setImageURL(url);
      setImageInputPrompt(url);
    }
  };

  async function incrementMicUsed() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/incrementMicUsed",
          { email: userEmail }
        );
      }
    } catch (error) {
      console.error("Error incrementing micUsed:", error);
    }
  }

  const onPromptSubmissionHandler = async () => {
    let localImageResponse = null;
    let localS3Key = null;
    setImageURL(null);

    if (fileInputRef.current?.files?.[0]) {
      const file = fileInputRef.current.files[0];
      // console.log("Starting s3 upload")
      const uploadResult = await uploadImageToS3(file, userEmail);
      // console.log("Finished s3 upload")
      localImageResponse = uploadResult.imgResponse;
      localS3Key = uploadResult.s3Key;
    }

    if (localImageResponse) {
      handleImagePromptSubmission(localImageResponse, localS3Key);
    } else {
      onPromptSubmission();
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);

    const file = event.dataTransfer.files[0];

    if (file) {
      onFileUpload(file);
    }
  };

  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const handleMicrophoneClick = async () => {
    // console.log("Microphone click handled. Is recording:", isRecording);
    if (!isRecording) {
      // console.log("Attempting to start recording...");
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setMediaStream(stream); // Store the stream in the state
        // console.log("Stream obtained:", stream);
        recorder.current = RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/webm",
        });
        recorder.current.startRecording();
        // console.log("Recording started.");
        setIsRecording(true);
        recordingInterval.current = setInterval(() => {
          setRecordingDuration((prevDuration) => prevDuration + 1);
        }, 1000);
      } catch (error) {
        console.error("Error obtaining media stream:", error);
      }
    } else {
      if (recorder.current) {
        recorder.current.stopRecording(async function () {
          setIsProcessingMic(true);
          setRecordingDuration(0);
          let blob = recorder.current.getBlob();

          // Generate a unique key for this file
          const fileKey = `recorded_audio_${Date.now()}.webm`;

          // Generate pre-signed URL for upload
          const params = {
            Bucket: "pathfinder-speech-to-text",
            Key: fileKey,
            Expires: 60, // The URL will be valid for 60 seconds
            ContentType: "audio/webm",
          };

          s3.getSignedUrl("putObject", params, async function (err, url) {
            if (err) {
              // console.log("Error getting pre-signed URL", err);
              return;
            }

            // Upload the blob to S3 using the pre-signed URL
            try {
              await axios.put(url, blob, {
                headers: {
                  "Content-Type": "audio/webm",
                },
              });

              // Send the file key to the backend
              const backendResponse = await axios.get(
                "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getTextFromSpeech",
                {
                  params: {
                    fileKey: fileKey,
                  },
                }
              );

              // Get the text string from the response
              const text = backendResponse.data;
              const trimmedText = text.trim();

              // Autofill the UserInput box with the transcript
              setUserPrompt(trimmedText);
              setIsProcessingMic(false);
              incrementMicUsed();
            } catch (error) {
              console.error(
                "Error uploading file to S3 or sending file key to backend:",
                error
              );
              console.error("Error details:", error.response);
            }

            // Use the stored stream reference to stop the tracks
            if (mediaStream) {
              mediaStream.getTracks().forEach((track) => {
                track.stop();
              });
              setMediaStream(null); // Clear the stored stream
            } else {
              // console.log("No mediaStream found.");
            }
          });
        });
        setIsRecording(false);
        clearInterval(recordingInterval.current);
        setRecordingDuration(0);
      } else {
        // console.log("Recorder not initialized or already stopped.");
      }
    }
  };

  // Inside the PromptInput component, before the return statement, add the following JSX for the buttons
  const topRightButtons = (
    <div className="flex space-x-2 px-2 justify-end mb-3 mr-2">
      <PathFinderButton
        outlined={true}
        onClick={handleCreatePageClick}
        color="pf-dark-grey dark:pf-grey"
      >
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faPlus} className="text-xs" />
          <p className="text-sm font-bold text-pf-dark-grey dark:text-pf-grey">
            Find resources
          </p>
        </div>
      </PathFinderButton>
      <PathFinderButton
        outlined={true}
        onClick={handleSuggestOptionsClick}
        color="pf-dark-grey dark:pf-grey"
      >
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faQuestion} className="text-xs" />
          <p className="text-sm font-bold text-pf-dark-grey dark:text-pf-grey">
            Suggest options
          </p>
        </div>
      </PathFinderButton>
      <PathFinderButton
        outlined={true}
        onClick={handleFindImagesClick}
        color="pf-dark-grey dark:pf-grey"
      >
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faImage} className="text-xs" />
          <p className="text-sm font-bold text-pf-dark-grey dark:text-pf-grey">
            Find images
          </p>
        </div>
      </PathFinderButton>
    </div>
  );

  return (
    <div className="relative w-full h-full bg-white dark:bg-pf-navy">
      {numMessages > 0 && numMessages < 3 && !imageURL ? (
        <>
          <div className="absolute h-[6rem] bottom-[4rem] left-14 w-[12rem] z-0">
            <WriteHere2
              className="text-transparent"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          <div
            className="absolute overflow-hidden h-[8rem] bottom-[-1.5rem] left-[-1rem] w-[100%] z-0 "
            style={{ pointerEvents: "none" }}
          >
            <Circled
              className="text-transparent"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </>
      ) : numMessages !== 0 ? (
        <>
          <div className=""> {topRightButtons}</div>
          <hr className="border-t-2 mr-4 border-pf-dark-grey dark:border-pf-grey" />
        </>
      ) : (
        <></>
      )}
      {imageURL && (
        <img
          src={imageURL}
          alt="Uploaded"
          className="mb-2 my-4 max-h-60 mx-auto"
        />
      )}
      <div
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`flex mt-2 border ${
          isDragOver
            ? "border-solid border-pf-grey dark:border-pf-dark-grey"
            : "border-transparent"
        } pr-2 h-sm:py-2 bg-transparent rounded-md w-full ml-[-6px]`}
      >
        <div className="flex items-center pt-1 pr-1 z-10">
          {isProcessingMic ? (
            <div className="flex items-center justify-center h-10 w-10">
              <CircularProgress
                style={{
                  color: "#6B7280",
                  height: "66%",
                  width: "66%",
                  margin: "auto",
                }}
              />
            </div>
          ) : (
            <Tooltip
              content={
                isRecording
                  ? "Click me to stop recording"
                  : "Click me to start recording"
              }
            >
              <button
                onClick={handleMicrophoneClick}
                className="flex items-center justify-center h-10 w-10"
              >
                <FontAwesomeIcon
                  icon={isRecording ? faSquare : faMicrophone}
                  className="text-pf-dark-grey dark:text-pf-grey"
                  width="100%"
                  height="100%"
                  size="lg"
                />
              </button>
            </Tooltip>
          )}
          <Tooltip content="Upload an image">
            <button
              onClick={() => document.getElementById("image-upload").click()}
              className="flex items-center justify-center h-10 w-10 mx-2"
            >
              <FontAwesomeIcon
                icon={faImage}
                className="text-pf-dark-grey dark:text-pf-grey"
                size="lg"
              />
            </button>
          </Tooltip>
          <input
            type="file"
            id="image-upload"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageUpload}
            ref={fileInputRef}
          />
        </div>
        <UserInput
          placeholder="Start typing..."
          value={
            isRecording
              ? `Recording duration: ${recordingDuration} seconds`
              : isProcessingMic
              ? "The translation can take up to 30 seconds."
              : userPrompt
          }
          onChange={(event) => {
            if (focus === "page") {
              setFocus && setFocus("chat");
            }
            setUserPrompt(event.target.value);
          }}
          onSubmit={onPromptSubmissionHandler}
          setHeight={setAddedHeight}
          className={`${
            isRecording || isProcessingMic
              ? `text-md text-pf-dark-grey dark:text-pf-grey pt-1`
              : "text-2xl z-10"
          } ${numMessages === 0 && "ml-2 mr-8"} `}
        />
        {assistantWriting || runActive ? (
          <div className="mr-4 pt-1">
            <button
              onClick={handleCancelRun}
              className="flex items-center justify-center h-8 w-8"
            >
              {isCancelling ? (
                <div className="flex items-center justify-center h-10 w-10">
                  <CircularProgress
                    style={{
                      color: "#6B7280",
                      height: "66%",
                      width: "66%",
                      margin: "auto",
                    }}
                  />
                </div>
              ) : (
                <FontAwesomeIcon
                  icon={faSquare}
                  className="text-pf-dark-grey dark:text-pf-grey"
                  size="lg"
                />
              )}
            </button>
          </div>
        ) : (
          <div className={`mr-4 pt-1 z-10 ${numMessages === 0 && "ml-2"}`}>
            <button
              onClick={onPromptSubmissionHandler}
              className="flex items-center justify-center h-8 w-8"
            >
              <FontAwesomeIcon
                icon={faPaperPlane}
                className={`${
                  userPrompt
                    ? "text-pf-purple dark:text-pf-green"
                    : "text-pf-dark-grey dark:text-pf-grey"
                }`}
                size="lg"
              />
            </button>
          </div>
        )}
      </div>
      {numMessages === 0 && (
        <div className="flex">
          {addedHeight < 50 && (
            <div
              className={`absolute left-[-4rem] w-[10rem] mt-[-3rem] h-sm:mt-[-3.75rem]`}
              style={{ pointerEvents: "none" }}
            >
              <SpeakToMe
                className="text-transparent"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          )}
          <div
            className={`absolute left-20 w-[20rem] mt-[-8.5rem] h-sm:mt-[-9rem]`}
            style={{ pointerEvents: "none" }}
          >
            <WriteHere
              className="text-transparent"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          {addedHeight < 50 && (
            <div
              className={`absolute right-[-2rem] w-[10rem] mt-[-10rem] h-sm:mt-[-10.75rem]`}
              style={{ pointerEvents: "none" }}
            >
              <SendToMe
                className="text-transparent"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />{" "}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PromptInput;
