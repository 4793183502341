import React, { FC, TextareaHTMLAttributes } from "react";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  h?: string;
}

const TextArea: FC<TextAreaProps> = ({
  h,
  placeholder,
  value,
  onChange,
  id,
  required,
}) => {
  return (
    <div className="border flex-grow p-0 bg-pf-grey border-pf-navy rounded-xl">
      <textarea
        id={id}
        placeholder={placeholder}
        required={required}
        className="rounded-xl flex-grow bg-pf-grey border-none p-3 mb-0 pb-0 text-sm focus:outline-0 focus:ring-0 hover:outline-0 hover:border-none hover:ring-0 active:ring-0 focus:border-1 active:outline-0 active:border-1"
        style={{ boxSizing: "border-box", height: h }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TextArea;
