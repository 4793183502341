import React, { useEffect, useState } from "react";
import Add from "../../../assets/add.png";
import { ProjectUploadModal } from "../../ProjectUploadModal";
import Authenticator from "../../../services/authenticator";
import axios from "axios";

const AddProjectCard = ({ setProjects }) => {
  const [isProjectUploadModalOpen, setIsProjectUploadModalOpen] =
    useState(false);
  //   const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [uploadedProjectName, setUploadedProjectName] = useState<string>("");
  const [projectPathRelation, setProjectPathRelation] = useState<string>("");
  const [userDetails, setUserDetails] = useState<any>({
    id: -1,
    name: "",
    username: "",
    email: "",
    description: "",
    signupDate: "",
    profilePictureUrl: "",
    githubUrl: "",
    linkedInUrl: "",
    twitterUrl: "",
    otherUrl: "",
    paths: [],
    projects: [],
  });

  const handleModalToggle = () => {
    setIsProjectUploadModalOpen((prev) => !prev);
  };

  const getUserDetails = async () => {
    try {
      const userId = await Authenticator.getCurrentUserId();
      const response = await axios.post(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getUserData",
        {
          userId: userId,
        }
      );
      if (response.data) {
        setUserDetails(response.data);
      }
    } catch (error) {
      console.error("Error getting user details:", error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div className="max-w-sm">
      <button
        className="w-full h-full flex items-center justify-center font-black rounded-3xl bg-white shadow-lg hover:shadow-xl"
        onClick={handleModalToggle}
        style={{
          borderColor: "#6B7280",
          borderWidth: "4px",
          borderStyle: "dashed",
        }}
      >
        <div className="flex flex-col items-center p-4">
          <img src={Add} alt="Add" style={{ marginBottom: "8px" }} />
          <h5 className="text-2xl" style={{ color: "#6B7280" }}>
            Add a project
          </h5>
        </div>
      </button>
      <ProjectUploadModal
        isOpen={isProjectUploadModalOpen}
        onClose={() => setIsProjectUploadModalOpen(false)}
        setProjects={setProjects}
        // setIsProjectShareModalOpen={setIsShareModalOpen} // Pass down the setter function
        setUploadedProjectName={setUploadedProjectName} // Pass the setter to ProjectUploadModal
        // setShareModalPathId={setProjectPathRelation} // Pass the setter to ProjectUploadModal
      />
    </div>
  );
};

export default AddProjectCard;
