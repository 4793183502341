import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RootPage from "../root";

const ApplicationSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <RootPage header="home">
      <div className="flex flex-col items-center justify-center min-h-screen px-[10%]">
        <h1 className="xs:text-5xl text-6xl md:text-8xl font-bold mb-6 flex items-center text-center">
          Thank you for applying! You will hear back from us by the 20th of
          July.
        </h1>
        <button
          className="px-8 py-4 my-[4%] bg-pf-purple text-white rounded-lg text-lg font-semibold"
          onClick={() => navigate("/")}
        >
          lets take you home
        </button>
      </div>
    </RootPage>
  );
};

export default ApplicationSuccessPage;
