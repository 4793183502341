import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { ToolOutput, Message } from "./objects";
import ChatHistory from "./components/ChatHistory";
import PromptInput from "./components/PromptInput";
import InteractiveBox from "./components/InteractiveBox";
import { Run } from "./objects";
import PathFinderButton from "../../components/common/PathFinderButton";
import DeleteModal from "../../components/common/DeleteModal";
import OptionButton from "./components/OptionButton";
import Cookie from "js-cookie";
import { useUser } from "../../contexts/UserContext";
import { CircularProgress, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import emailjs from "@emailjs/browser";
import Authenticator from "../../services/authenticator";
import amplitudeInstance, {
  logEvent,
  generateUniqueUserId,
} from "../../services/amplitudeService";
import SideMenu from "./components/SideMenu";
import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: '#913B93', // Your pf-purple equivalent
        },
        track: {
          color: '#913B93',
        },
        rail: {
          color: '#913B93',
        },
      },
    },
  },
});

type AssistantComponentProps = {
  threadId: string;
  createThread: () => Promise<string>;
  setThreadId?: (threadId: string) => void;
};

const AssistantComponent: React.FC<AssistantComponentProps> = ({
  threadId,
  createThread,
  setThreadId,
}) => {
  // Define your state variables here
  const apiKey = "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";
  // let assistantId = "asst_w3IlizKOlHomWQ73JtUyA4yP";
  const calculatedHeight = "h-[calc(100vh-108px)]";
  const calculatedHeight2 = "h-[calc(100vh-100px)]";

  const testEmails = [
    "amaankahmad@gmail.com",
    "amaankahmad2@gmail.com",
    "sid4@gmail.com",
    "Sid5@gmail.com",
    "sidharthjain20022@gmail.com",
  ];

  const [userPrompt, setUserPrompt] = useState<string>("");
  const [currentRun, setCurrentRun] = useState<Run>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [assistantWriting, setAssistantWriting] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [runActive, setRunActive] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [resource, setResource] = useState<{
    title: string;
    url: string;
    description: string;
  }>();
  const [objectives, setObjectives] = useState<string[]>([""]);
  const [potentialPaths, setPotentialPaths] = useState<string[]>([]);
  const [onPathSelected, setOnPathSelected] = useState<
    ((path: string) => void) | null
  >(null);
  const [potentialResources, setPotentialResources] = useState<
    { title: string; url: string; description: string }[]
  >([]);
  const [onResourceSelected, setOnResourceSelected] = useState<
    | ((resource: { title: string; url: string; description: string }) => void)
    | null
  >(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [hasPreviousNode, setHasPreviousNode] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [showChats, setShowChats] = useState(messages.length === 0);
  const [userChatsId, setUserChatsId] = useState<string>("");
  const [newChatLoading, setNewChatLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [imageTitle, setImageTitle] = useState<string>("");

  const exampleProjects = [
    "💃 break dancing",
    "☕ making the best espresso",
    "🎥 movie production",
  ];
  const [selectedExampleProject, setSelectedExampleProject] =
    useState<string>("");
  const [notionLink, setNotionLink] = useState<string>(
    `https://www.notion.so/thepathfinderproject/${threadId}`
  );
  const [showOverview, setShowOverview] = useState<boolean>(false);
  const [focus, setFocus] = useState<"neutral" | "chat" | "page">("chat");
  const { user } = useUser();
  const [parentId, setParentId] = useState<string>("");
  const [userEmail, setUserEmail] = useState(null);
  const [suggestedPrompts, setSuggestedPrompts] = useState<string[]>([]);
  const [aiResponseReceived, setAiResponseReceived] = useState<boolean>(false);
  const [imageResponse, setImageResponse] = useState<string>("");
  const imageResponseRef = useRef<string>("");
  var firstMessageContent = "";
  const [actonToggle, setActonToggle] = useState<boolean>(false);
  const [assistantId, setAssistantId] = useState<string>("asst_w3IlizKOlHomWQ73JtUyA4yP");
  const [heading, setHeading] = useState<string>("");
  const [videosCount, setVideosCount] = useState(3);
  const [articlesCount, setArticlesCount] = useState(3);
  const [displayedText, setDisplayedText] = useState<string>("");
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState<number>(0);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const phrases = [
    "What project do you want to build?",
    "What are you wildly curious about?",
    "What do you want to desperately get good at?",
  ];

  useEffect(() => {
    const handleTyping = () => {
      const currentPhrase = phrases[currentPhraseIndex];
      if (isDeleting) {
        setDisplayedText((prev) => prev.slice(0, -1));
      } else {
        setDisplayedText((prev) => currentPhrase.slice(0, prev.length + 1));
      }
  
      if (!isDeleting && displayedText === currentPhrase) {
        setTimeout(() => setIsDeleting(true), 10000); // Pause before deleting
      } else if (isDeleting && displayedText === "") {
        setIsDeleting(false);
        setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
      }
    };
  
    const typingSpeed = isDeleting ? 20 : 75; // Faster typing and deleting speed
    const typingTimeout = setTimeout(handleTyping, typingSpeed);
  
    return () => clearTimeout(typingTimeout);
  }, [displayedText, isDeleting, currentPhraseIndex]);

  // Handler for changing the number of videos
  const handleVideosChange = (event: Event, newValue: number | number[]) => {
    setVideosCount(newValue as number);
    setArticlesCount(6 - (newValue as number));
  };

  // Handler for changing the number of articles
  const handleArticlesChange = (event: Event, newValue: number | number[]) => {
    setArticlesCount(newValue as number);
    setVideosCount(6 - (newValue as number));
  };

  useEffect(() => {
    const emailFromStorage = localStorage.getItem("userEmail");
    if (emailFromStorage && (emailFromStorage.includes("actonacademyfl") || testEmails.includes(emailFromStorage))) {
      setUserEmail(emailFromStorage);
      // Set the initial state of the toggle based on some condition or user preference
      // For demonstration, it's set to false initially
      setActonToggle(false); // Or true, based on your application's needs
    }
  }, []);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActonToggle(event.target.checked);
    // Additional logic if needed to force update or handle UI changes
  };

  useEffect(() => {
    if (actonToggle) {
      setAssistantId("asst_rL3sAZ5g5UGFvsRnUmY79E7i"); // The alternative assistant ID
      setHeading("Acton Mode Title"); // Change title for Acton mode
    } else {
      setAssistantId("asst_w3IlizKOlHomWQ73JtUyA4yP"); // Default assistant ID
      setHeading(""); // Default title
    }
  }, [actonToggle]);

  useEffect(() => {
    if (!userEmail) {
      const emailFromStorage = localStorage.getItem("userEmail");
      if (emailFromStorage) {
        setUserEmail(emailFromStorage);
      }
    }
  }, [userEmail, user]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (messages.length === 0) {
        if (userEmail && (userEmail.includes("actonacademyfl") || testEmails.includes(userEmail)) && actonToggle) {
          setUserPrompt("I learnt about ");
        } else {
          setUserPrompt("I am interested in ");
        }
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [messages, userEmail, actonToggle]); 

  useEffect(() => {
    const cachedTitle = Cookie.get("title");
    const cachedDescription = Cookie.get("description");
    const cachedResource = Cookie.get("resource");
    const cachedImages = Cookie.get("images");
    const cachedImageTitle = Cookie.get("imageTitle");
    const cachedObjectives = Cookie.get("objectives");
    const cachedExtraResources = Cookie.get("extraResources");
    const cachedUserChatsId = Cookie.get("userChatsId");

    if (cachedTitle) {
      setTitle(cachedTitle);
      setPageLoading(true);
    }

    if (cachedDescription) {
      setDescription(cachedDescription);
      setPageLoading(true);
    }

    if (cachedResource) {
      setResource(JSON.parse(cachedResource));
      setPageLoading(true);
    }

    if (cachedImages) {
      setImages(JSON.parse(cachedImages));
      setPageLoading(true);
    }

    if (cachedImageTitle) {
      setImageTitle(cachedImageTitle);
      setPageLoading(true);
    }

    if (cachedObjectives) {
      setObjectives(JSON.parse(cachedObjectives));
      setPageLoading(true);
    }

    if (cachedExtraResources) {
      setPotentialResources(JSON.parse(cachedExtraResources));
      setPageLoading(true);
    }

    const userEmail = localStorage.getItem("userEmail");
    if (userEmail !== "") {
      (async () => {
        const userPageId = await Authenticator.getUserPageIdByEmail(userEmail);
        setUserChatsId(userPageId);
      })();
    } else if (cachedUserChatsId) {
      setUserChatsId(cachedUserChatsId);
    }
  }, []);

  // useEffect(() => {
  //   hydrateMessages();
  // }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  }, [messages]);

  useEffect(() => {
    if (!userChatsId) {
      const userChatsId = Cookie.get("userChatsId");
      setUserChatsId(userChatsId);
    }
  }, [userChatsId]);

  // useEffect(() => {
  //   if (messagesEndRef.current) {
  //     setTimeout(() => {
  //       messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  //     }, 0); // Timeout set to 0 to delay the scroll until after the paint
  //   }
  // }, [pageLoading]);

  useEffect(() => {
    // Reset the state variables
    setTitle("");
    setDescription("");
    setResource(null);
    setObjectives([""]);
    setImages([]);
    setPotentialResources([]);
    setRunActive(false);
    setAssistantWriting(false);
    setCurrentRun(null);
    setMessages([]);
    setPotentialPaths([]);
    fetchMessages();
    setNotionLink("https://www.notion.so/thepathfinderproject/" + threadId);
    setShowChats(false);
  }, [threadId]);

  async function updateLastLoggedIn() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/updateLastLoggedIn",
          { email: userEmail }
        );
      }
    } catch (error) {
      console.error("Error updating last logged in:", error);
    }
  }

  async function incrementTimesPrompted() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/incrementTimesPrompted",
          { email: userEmail }
        );
      }
    } catch (error) {
      console.error("Error incrementing timesPrompted:", error);
    }
  }

  async function incrementPagesCreated() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/incrementPagesCreated",
          { email: userEmail }
        );
      }
    } catch (error) {
      console.error("Error incrementing pagesCreated:", error);
    }
  }

  async function incrementImgUploaded() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/incrementImgUploaded",
          { email: userEmail }
        );
      }
    } catch (error) {
      console.error("Error incrementing pagesCreated:", error);
    }
  }

  async function incrementSeeAllPages() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/incrementSeeAllPagesClick",
          { email: userEmail }
        );
      }
    } catch (error) {
      console.error("Error incrementing pagesCreated:", error);
    }
  }

  // Function to fetch numberArticles
  const fetchNumberArticles = async () => {
    const email = localStorage.getItem("userEmail");
    try {
      const response = await axios.get(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getNumberArticles",
        {
          params: {
            userEmail: email,
          },
        }
      );     
      const numberArticles = response.data.numberArticles;
      setArticlesCount(numberArticles);
      setVideosCount(6 - numberArticles); 
    } catch (error) {
      console.error('Failed to fetch number of articles', error);
    }
  };

  // Fetch numberArticles on component mount
  useEffect(() => {
    fetchNumberArticles();
  }, [userEmail]); 

  const addChatSummaryToUser = async (
    userEmail: string,
    messagesString: string
  ) => {
    try {
      await axios.post(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/addChatSummaryToUser",
        {
          userEmail,
          chatMessages: messagesString,
        }
      );
      // console.log("Chat summary added: ", response.data);
    } catch (error) {
      console.error("Error adding chat summary to user:", error);
    }
  };

  const handlePromptSubmission = useCallback(
    async (override?: boolean) => {
      if (userPrompt === "") return;
      setFocus("chat");

      if (messages.length === 0) {
        setShowChats(false);
      }

      if (!runActive || override) {
        setAssistantWriting(true);
        setPotentialPaths([]);
        const userMessage = userPrompt;
        await createMessage(threadId, "user", userMessage);
        setRunActive(true);
        setUserPrompt("");
        await awaitAssistantActions(threadId);
        await fetchMessages();

        // async function for efficiency
        const updateUserActivity = async () => {
          if (userEmail) {
            updateLastLoggedIn();
            incrementTimesPrompted();
            const uniqueUserId = generateUniqueUserId(userEmail);
            amplitudeInstance.setUserId(uniqueUserId.toString());
            logEvent("PROMPT_SUBMISSION", {
              userId: uniqueUserId.toString(),
            });
          }
        };
        updateUserActivity();
      } else {
        handleCancelRunModal();
      }
    },
    [userPrompt]
  );

  const handleImagePromptSubmissionHelper = useCallback(
    async (imageURL: string, override?: boolean) => {
      if (userPrompt === "") return;
      setFocus("chat");

      if (messages.length === 1) {
        setShowChats(false);
      }

      if (!runActive || override) {
        setAssistantWriting(true);
        setPotentialPaths([]);
        setRunActive(true);
        const userMessage = userPrompt;
        await createMessage(threadId, "user", userMessage, [], {}, imageURL);
        setUserPrompt("");
      } else {
        handleCancelRunModal();
      }
    },
    [userPrompt]
  );

  const handleImagePromptSubmission = useCallback(
    async (imageResponse: string, s3Key: string, override?: boolean) => {
      setImageResponse(imageResponse);
      await new Promise((resolve) => setTimeout(resolve, 0)); // Ensure state update is processed

      if (!runActive || override) {
        await awaitAssistantActions(threadId, true);
        await fetchMessages(true, s3Key);

        // async function for efficiency
        const updateUserActivity = async () => {
          if (userEmail) {
            updateLastLoggedIn();
            incrementTimesPrompted();
            incrementImgUploaded();
            const uniqueUserId = generateUniqueUserId(userEmail);
            amplitudeInstance.setUserId(uniqueUserId.toString());
            logEvent("PROMPT_SUBMISSION", {
              userId: uniqueUserId.toString(),
            });
          }
        };
        updateUserActivity();
      } else {
        handleCancelRunModal();
      }
    },
    [userPrompt]
  );

  // Update the ref whenever imageResponse state changes
  useEffect(() => {
    imageResponseRef.current = imageResponse;
  }, [imageResponse]);

  const createMessage = async (
    threadId: string,
    role: string,
    content: string,
    file_ids: string[] = [],
    metadata: any = {},
    imageURL?: string
  ) => {
    try {
      const newMessage: Message = {
        id: "", // This will be updated after the axios request
        object: "message",
        created_at: Math.floor(Date.now() / 1000),
        thread_id: threadId,
        role: role,
        content: [{ type: "text", text: { annotations: [], value: content } }],
        assistant_id: null,
        run_id: null,
        file_ids: file_ids,
        metadata: metadata,
        imageUrl: imageURL,
      };

      setMessages([...messages, newMessage]);
      setUserPrompt("");
      setAssistantWriting(true);
      setRunActive(true);

      const response = await axios
        .post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/createMessage",
          {
            threadId,
            role,
            content,
            file_ids,
            metadata,
          }
        )
        .then((response) => {
          const message: Message = response.data;
        })
        .catch((error) => {
          console.error(error);
          setAssistantWriting(false);
          alert(
            "PathFinder is a little slow right now. Your response may come slower, or you may have to resubmit your query. We're sorry. Please let us know about this by clicking on the 'Give Feedback' button!"
          );
        });
      if (newMessage.role === "user") {
        setAiResponseReceived(true);
      }
      return response;
    } catch (error) {
      console.error("Error in createMessage:", error);
      handleConfirmCancelRun();
      createMessage(threadId, role, content, file_ids, metadata);
    }
  };

  const handleCancelRunModal = () => {
    setShowCancelModal(true);
  };

  const handleConfirmCancelRun = async () => {
    const runId: string = currentRun.id;
    try {
      setIsCancelling(true);
      await axios.post(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/cancelCurrentRun",
        { threadId, runId }
      );
      setPotentialPaths([]);
      // setPotentialResources([]);
      // Remove the latest messages that are not of type "dialog"
      let index = messages.length - 1;
      while (index >= 0 && messages[index].type !== "dialog") {
        index--;
      }
      setAssistantWriting(false);

      setMessages(messages.slice(0, index + 1));
      if (userPrompt !== "") {
        handlePromptSubmission(true);
        setIsCancelling(false);
        setRunActive(true);
      }
      setRunActive(false);
      setIsCancelling(false);
      setShowCancelModal(false);
    } catch (error) {
      setIsCancelling(false);
      console.error("Error cancelling run:", error);
    }
  };

  const runThread = useCallback(
    async (threadId: string) => {
      try {
        const userEmail = localStorage.getItem("userEmail");
        // if (userEmail && userEmail.includes("actonacademyfl")) {
        //   // eslint-disable-next-line react-hooks/exhaustive-deps
        //   assistantId = "asst_rL3sAZ5g5UGFvsRnUmY79E7i";
        // }
        const response = await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/runThread",
          { threadId, assistantId, userEmail }
        );
        const run: Run = response.data.run;
        setCurrentRun(run);
        return run;
      } catch (error) {
        // console.error("runThread error:", error);
        alert(
          "PathFinder is a little slow right now. Your response may come slower, or you may have to resubmit your query. We're sorry. Please let us know about this by clicking on the 'Give Feedback' button!"
        );
      }
    },
    [threadId, assistantId]
  );

  const getRunStatus = async (threadId: string, runId: string) => {
    try {
      // Make the API request to get the run status
      // Replace with your actual API call
      return await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v1",
          },
        }
      );
    } catch (error) {
      console.error("Error getting run status:", error);
      throw error;
    }
  };

  const awaitAssistantActions = async (
    threadId: string,
    isImageAction: boolean = false
  ) => {
    // Run the thread
    const runResponse = await runThread(threadId);
    let runStatus = runResponse.status;
    let runId = runResponse.id;

    let iterationCount = 0;
    const maxIterations = 500; // Adjust this value as needed

    // Constantly check the status of the run until it has the status "complete"
    while (
      runStatus !== "completed" &&
      iterationCount < maxIterations &&
      runStatus !== "cancelled"
    ) {
      // console.log(`Iteration ${iterationCount}: Checking run status...`);
      // const checkStatusStartTime = performance.now();
      const runStatusResponse = await getRunStatus(threadId, runId);
      // const checkStatusEndTime = performance.now();
      // console.log(`Run status checked in ${(checkStatusEndTime - checkStatusStartTime).toFixed(2)}ms. Status: ${runStatus}`);
      runStatus = runStatusResponse.data.status;
      if (runStatus === "completed") {
        break;
      }

      if (runStatus === "requires_action" && !isImageAction) {
        setAssistantWriting(true);
        const outputs = await executeFunctionCalls(runStatusResponse.data);
        // Wait for a second before submitting the outputs
        await new Promise((resolve) => setTimeout(resolve, 100));
        await submitFunctionOutputs(outputs, runId);
      }

      // Wait for a second before checking the status again to avoid hitting rate limits
      await new Promise((resolve) => setTimeout(resolve, 100));
      iterationCount++;
    }
    setRunActive(false);
    setAssistantWriting(false);
    setShowCancelModal(false);
  };

  // const hydrateMessages = useCallback(async () => {
  //   fetchMessages()
  //     .then(() => fetchOpenRun())
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);

  const fetchMessages = useCallback(
    async (isImageAction: boolean = false, s3Key?: string) => {
      try {
        const response = await axios.get(
          `https://api.openai.com/v1/threads/${threadId}/messages`,
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
              "Content-Type": "application/json",
              "OpenAI-Beta": "assistants=v1",
            },
          }
        );

        let newMessages: Message[] = response.data.data.map(
          (message: Message) => ({
            ...message,
            type: "dialog",
          })
        );

        Promise.all(
          newMessages.map(async (message) => {
            if (message.role !== "assistant") {
              try {
                const imageUrlResponse = await axios.get(
                  "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/fetchImageURL",
                  {
                    params: {
                      msgId: message.id
                    }
                  }
                );
                if (imageUrlResponse.data.imgURL) {
                  message.imageUrl = imageUrlResponse.data.imgURL;
                }
              } catch (error) {
                // Handle error if necessary
              }
            } else {
              try {
                const updateResponse = await axios.get(
                  "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/fetchImageMessage",
                  {
                    params: {
                      msgId: message.id
                    }
                  }
                );
                if (updateResponse.data.newMsg) {
                  message.content[0].text.value = updateResponse.data.newMsg;
                }
              } catch (error) {
                // Handle error if necessary
              }
            }
            return message;
          })
        ).then((updatedMessages) => {
          newMessages = updatedMessages;

        });

        // const fetchUpdatedMessages = await Promise.all(
        //   newMessages.map(async (message) => {
        //     if (message.role !== "assistant") {
        //       try {
        //         console.log("HERE 1")
        //         const imageUrlResponse = await axios.post(
        //           "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/fetchImageURL",
        //           {
        //             msgId: message.id,
        //           }
        //         );
        //         if (imageUrlResponse.data.imgURL) {
        //           message.imageUrl = imageUrlResponse.data.imgURL;
        //         }
        //       } catch (error) {
        //         // console.error("Error fetching updated message:", error);
        //       }
        //     } else {
        //       try {
        //         console.log("HERE 2")
        //         const updateResponse = await axios.post(
        //           "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/fetchImageMessage",
        //           {
        //             msgId: message.id,
        //           }
        //         );
        //         if (updateResponse.data.newMsg) {
        //           message.content[0].text.value = updateResponse.data.newMsg;
        //         }
        //       } catch (error) {
        //         // console.error("Error fetching updated message:", error);
        //       }
        //     }
        //     return message;
        //   })
        // );

        // newMessages = fetchUpdatedMessages;


        let emptyTextValue = newMessages.some(
          (msg) => msg.content[0].text.value === ""
        );

        if (!emptyTextValue) {
          setMessages((prevMessages) => {
            let missingMessages = newMessages.filter(
              (msg) => !prevMessages.find((m) => m.id === msg.id)
            );

            if (missingMessages.length > 1 && isImageAction) {
              const userMessageId = missingMessages[1].id; // Get the ID of the message at index 1
              const assistantId = missingMessages[0].assistant_id; // Get the assistantId from the message at index 0

              // Now, use this userMessageId and assistantId to call addImageURLUpdate
              (async () => {
                // console.log("ADDING S3 KEY: ", s3Key);
                try {
                  await axios.post(
                    "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/addImageURLUpdate",
                    {
                      assistantId: assistantId,
                      msgId: userMessageId,
                      imgURL: s3Key,
                    }
                  );
                } catch (error) {
                  // console.error("Error adding image URL update:", error);
                }
              })();
            }

            // Filter out duplicates based on content for messages without an ID
            missingMessages = missingMessages.filter((newMsg) => {
              return !prevMessages.some(
                (prevMsg) =>
                  prevMsg.content[0].text.value ===
                    newMsg.content[0].text.value && prevMsg.id === ""
              );
            });

            if (imageResponseRef.current !== "" && isImageAction) {
              const lastAssistantMessageIndex = missingMessages.findIndex(
                (msg) => msg.role === "assistant"
              );
              if (lastAssistantMessageIndex !== -1) {
                missingMessages[lastAssistantMessageIndex] = {
                  ...missingMessages[lastAssistantMessageIndex],
                  content: [
                    {
                      type: "text",
                      text: {
                        annotations: [],
                        value: imageResponseRef.current,
                      },
                    },
                  ],
                };
                const updatedMessage =
                  missingMessages[lastAssistantMessageIndex];
                updatedMessage.content = [
                  {
                    type: "text",
                    text: {
                      annotations: [],
                      value: imageResponseRef.current,
                    },
                  },
                ];
                imageResponseRef.current = "";

                (async () => {
                  try {
                    await axios.post(
                      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/addImageMessageUpdate",
                      {
                        assistantId: updatedMessage.assistant_id,
                        msgId: updatedMessage.id,
                        newMsg: updatedMessage.content[0].text.value,
                      }
                    );
                  } catch (error) {
                    // console.error("Error adding image message update:", error);
                  }
                })();
              }
            }

            const outputMessages = [...prevMessages, ...missingMessages].sort(
              (a, b) => a.created_at - b.created_at
            );

            const userEmail = localStorage.getItem("userEmail");
            if (
              userEmail &&
              outputMessages.length % 4 === 0 &&
              outputMessages.length !== 0
            ) {
              const lastFourMessages = outputMessages.slice(-4);
              const messagesString = lastFourMessages
                .map((msg) => msg.content[0].text.value)
                .join(" ");

              addChatSummaryToUser(userEmail, messagesString).catch((error) => {
                console.error("Error adding chat summary to user:", error);
              });
            }

            return outputMessages;
          });
          setDisplay(true);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [threadId, apiKey]
  );

  // const fetchOpenRun = useCallback(async () => {
  //   const response = await axios.post(
  //     "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/fetchOpenRun",
  //     { threadId }
  //   );
  //   const latestRun: Run = response.data.latestRun;
  //   setCurrentRun(latestRun);
  //   return response;
  // }, [threadId]);

  const submitFunctionOutputs = useCallback(
    async (outputs: ToolOutput[], runId: string) => {
      await submitOutputs(runId, outputs);
    },
    [threadId]
  );

  const submitOutputs = useCallback(
    async (runId: string, outputs: ToolOutput[]) => {
      try {
        await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/submitOutputs",
          { runId, outputs, threadId }
        );
      } catch (error) {
        alert(
          "PathFinder is a little slow right now. Your response may come slower, or you may have to resubmit your query. We're sorry. Please let us know about this by clicking on the 'Give Feedback' button!"
        );
      }
    },
    [threadId]
  );

  const handleFileSubmission = useCallback(async (file: File) => {
    // console.log("handleFileSubmission is running...");
    // console.log("handleFileSubmission input:", file);
    // Handle the submission of a file by the user
    // This will depend on what you want to do with the file
    // For example, you might want to upload it to a server
  }, []);

  const handleCreatePageClick = async (
    titl: string,
    desc: string,
    r: { title: string; url: string; description: string },
    obs: string[],
    extraRs: Array<{ title: string; url: string; description: string }>
  ) => {
    try {
      const userEmail = localStorage.getItem("userEmail");
      const firstUserMessage = messages.find(
        (message) => message.role === "user"
      );
      if (firstUserMessage) {
        firstMessageContent = firstUserMessage.content[0].text.value;
      }
      if (userEmail === "") {
        const response = await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/createProjectNotionPage",
          {
            title: titl,
            description: desc,
            resource: r,
            objectives: obs,
            extraResources: extraRs,
            threadId: threadId,
            firstMessage: firstMessageContent,
          }
        );
        const { pageId } = response.data;
        setParentId(pageId);
      } else {
        if (
          titl &&
          desc &&
          r &&
          obs &&
          extraRs &&
          threadId &&
          userEmail &&
          firstMessageContent
        ) {
          const response = await axios.post(
            "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/createProjectNotionPage1",
            {
              title: titl,
              description: desc,
              resource: r,
              objectives: obs,
              extraResources: extraRs,
              threadId: threadId,
              userEmail: userEmail,
              firstMessage: firstMessageContent,
            }
          );
          const { userPageId, threadPageId } = response.data;
          incrementPagesCreated();
          setParentId(threadPageId);
          setUserChatsId(userPageId);
          Cookie.set("userChatId", userPageId);
        } else {
          // console.log("Not all fields are filled in, not saving to notion...");
        }
      }
    } catch (error) {
      console.error("Error creating Notion page:", error);
    }
  };

  const executeFunctionCalls = useCallback(
    async (run: Run): Promise<ToolOutput[]> => {
      if (!run.required_action) return [];

      const outputs: ToolOutput[] = [];
      const actions = run.required_action.submit_tool_outputs.tool_calls;
      // if (messagesEndRef.current) {
      //   setTimeout(() => {
      //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      //   }, 0); // Timeout set to 0 to delay the scroll until after the paint
      // }

      for (let i = 0; i < actions.length; i++) {
        const action = actions[i];
        // If the action is a function, execute it
        if (action.type === "function") {
          const args = JSON.parse(action.function.arguments);
          if (action.function && "name" in action.function) {
            if (action.function.name === "displayTitleandDescription") {
              if (hasPreviousNode) {
                setHasPreviousNode(false);
              }
              setPageLoading(true);
              setTitle("");
              setDescription("");
              setResource(null);
              setObjectives([""]);
              setImages([]);
              setImageTitle("");
              setPotentialResources([]);
              setFocus("neutral");
              const response = await createFunctionMessage(
                action,
                "Writing",
                "Title and description created",
                "writing",
                () => displayTitleandDescription(args.title, args.description)
              );
              outputs.push({
                tool_call_id: action.id,
                output: response,
              });
              //Amplitude for page created
              logEvent("PAGE_CREATED_RHS");
            } else if (action.function.name === "findTopResources") {
              setResource(null);
              setPotentialResources([]);
              const response = await createFunctionMessage(
                action,
                "Finding the best resources for you to choose on the right (can take up to 15 seconds)",
                "Resources found! Creating learning objectives...",
                "search",
                () => findTopResources(args.searchPrompt, args.extraInfo)
              );
              outputs.push({
                tool_call_id: action.id,
                output: response,
              });
            } else if (action.function.name === "findImages") {
              setImages(null);
              setImageTitle("");
              const response = await createFunctionMessage(
                action,
                "Finding the most relevant images",
                "Images found!",
                "search",
                () => findImages(args.searchPrompt, args.imageTitleDisplay)
              );
              outputs.push({
                tool_call_id: action.id,
                output: response,
              });
            } else if (action.function.name === "displayLearningObjectives") {
              const response = await createFunctionMessage(
                action,
                "Creating learning objectives",
                "Learning objectives created",
                "list",
                () => displayLearningObjectives(args.objectives)
              );
              outputs.push({
                tool_call_id: action.id,
                output: response,
              });
            } else if (action.function.name === "showPotentialPaths") {
              const response = await createFunctionMessage(
                action,
                "Suggestions you can choose from",
                "Suggestion chosen",
                "generate",
                () => showPotentialPaths(args.potentialPaths)
              );
              outputs.push({
                tool_call_id: action.id,
                output: response,
              });
            } else if (action.function.name === "notifySafetyRisk") {
              const response = await sendRiskEmail(
                args.risk,
                args.risk_explanation,
                args.messages
              );
              outputs.push({
                tool_call_id: action.id,
                output: response,
              });
            } else {
              // console.log("Function not found! Function called:", action.function);
            }
          }
        }
      }

      return outputs;
    },
    []
  );

  const createFunctionMessage = async (
    action: any,
    inProgressMessage: string,
    doneMessage: string,
    functionType: string,
    functionExecutor: () => Promise<string>
  ) => {
    // Create a temporary message
    const tempMessage: Message = {
      id: "", // This will be updated after the axios request
      object: "message",
      created_at: Math.floor(Date.now() / 1000),
      thread_id: threadId,
      role: "pathfinder",
      content: [
        {
          type: "text",
          text: { annotations: [], value: `${inProgressMessage}...` },
        },
      ],
      assistant_id: null,
      run_id: null,
      file_ids: [],
      metadata: {},
      type: functionType,
    };

    setMessages((prevMessages) => [...prevMessages, tempMessage]);
    // if (messagesEndRef.current) {
    //   setTimeout(() => {
    //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    //   }, 0); // Timeout set to 0 to delay the scroll until after the paint
    // }
    // Execute the function
    const response = await functionExecutor();

    // Update the message
    setMessages((prevMessages) =>
      prevMessages.map((message) =>
        message === tempMessage
          ? {
              ...message,
              content: [
                {
                  type: "text",
                  text: {
                    annotations: [],
                    value: `${doneMessage}`,
                  },
                },
              ],
            }
          : message
      )
    );

    return response;
  };

  function displayTitleandDescription(
    title: string,
    description: string
  ): Promise<string> {
    return new Promise((resolve) => {
      setPageLoading(true);
      setTitle(title);
      setDescription(description);
      setResource(null);
      setObjectives([""]);
      setImages([]);
      setImageTitle("");
      setPotentialResources([]);
      Cookie.set("title", title);
      Cookie.set("description", description);
      resolve(
        "displayTitleandDescription created successfully. Now run the findTopResources function."
      );
    });
  }

  const findTopResources = async (
    searchPrompt: string,
    extraInfo?: string
  ): Promise<string> => {
    try {
      setPageLoading(true);
      let response;
      try {
        const email = localStorage.getItem("userEmail");
        let numberArticles = 3; // Default value if email does not exist
        if (email) {
          const articlesResponse = await axios.get(
            "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getNumberArticles",
            {
              params: {
                userEmail: email,
              },
            }
          );
          numberArticles = articlesResponse.data.numberArticles;
        }
        // First attempt to use the Tavily endpoint
        response = await axios.get(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getLinkAndTranscriptTavily",
          {
            params: {
              prompt: searchPrompt,
              numberArticles: numberArticles,
            },
          }
        );
      } catch (error) {
        // Check if the error is one of the specified types to fallback
        if (
          [400, 401, 403, 404, 405, 429, 500, 503, 504].includes(
            error.response?.status
          )
        ) {
          response = await axios.get(
            "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getLinkAndTranscript",
            {
              params: {
                prompt: searchPrompt,
              },
            }
          );
        } else {
          // If the error is not one of the specified types, rethrow it
          throw error;
        }
      }
      const outputLinks = response.data.outputLinks;
      // const outputLinks = Array(6).fill("https://www.worldhistory.org/article/1022/ancient-egyptian-mortuary-rituals/");
      const titles = response.data.titles;
      const summaries = response.data.summaries;

      const resources = outputLinks.map((url: string, index: number) => ({
        title: titles[index],
        url,
        description: summaries[index],
      }));

      setPotentialPaths([]);
      setPotentialResources(resources);
      setHasPreviousNode(true);
      setObjectives([""]);
      setImages([]);
      setImageTitle("");
      Cookie.set("extraResources", JSON.stringify(resources));
      setFocus("page");

      return new Promise((resolve) => {
        setOnResourceSelected(
          () =>
            (selectedResource: {
              title: string;
              url: string;
              description: string;
            }) => {
              setResource(selectedResource);
              const extraResources = resources.filter(
                (resource) => resource.url !== selectedResource.url
              );
              Cookie.set("title", title);
              Cookie.set("resource", JSON.stringify(selectedResource));
              setPotentialResources(extraResources);
              Cookie.set("extraResources", JSON.stringify(extraResources));
              resolve(
                `findTopResources created successfully. Now run the displayLearningObjectives function. Here is the resource they chose: title= "${selectedResource.title}" and transcript= "${selectedResource.description}". Do not display the transcript to the user but keep it in memory so that you have context of what the user is watching in case they have any questions.`
              );
            }
        );
      });
    } catch (error) {
      console.error("Error in findTopResources:", error);
      return Promise.resolve("Error in findTopResources");
    }
  };
  const findImages = async (
    searchPrompt: string,
    imageTitleDisplay: string
  ): Promise<string> => {
    try {
      setImageTitle(imageTitleDisplay);
      setTitle("");
      setPageLoading(true);
      let response;
      try {
        // First attempt to use the Tavily endpoint
        response = await axios.get(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getImageUrls",
          {
            params: {
              prompt: searchPrompt,
            },
          }
        );
      } catch (error) {
        // Check if the error is one of the specified types to fallback
        if (
          [400, 401, 403, 404, 405, 429, 500, 503, 504].includes(
            error.response?.status
          )
        ) {
          response = await axios.get(
            "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getImageUrls",
            {
              params: {
                prompt: searchPrompt,
              },
            }
          );
        } else {
          // If the error is not one of the specified types, rethrow it
          throw error;
        }
      }
      const outputLinks = response.data.imageUrls;

      setImages(outputLinks);
      setHasPreviousNode(true);
      setObjectives([""]);
      Cookie.set("images", JSON.stringify(outputLinks));
      setFocus("page");

      return new Promise((resolve) => {
        resolve(`findImages created successfully.`);
      });
    } catch (error) {
      console.error("Error in findImages:", error);
      return Promise.resolve("Error in findImages");
    }
  };

  function displayLearningObjectives(objectives: string[]): Promise<string> {
    setPageLoading(true);
    setObjectives(objectives);
    Cookie.set("objectives", JSON.stringify(objectives));
    return Promise.resolve("displayLearningObjectives created successfully");
  }

  function showPotentialPaths(potentialPaths: string[]): Promise<string> {
    return new Promise((resolve) => {
      setPotentialPaths(potentialPaths);
      setOnPathSelected(() => (path: string, index: number) => {
        if (
          path === "Give me new options" ||
          index === potentialPaths.length - 1
        ) {
          resolve(
            "showPotentialPaths created successfully. But the user wants other options. Rerun showPotentialPaths with new options."
          );
          // Create a message for the buttons to be displayed
          const buttonsMessage: Message = {
            id: "",
            object: "message",
            created_at: Math.floor(Date.now() / 1000),
            thread_id: threadId,
            role: "pathfinder",
            content: potentialPaths.map((p) => ({
              type: "text",
              text: { annotations: [], value: p },
            })),
            assistant_id: null,
            run_id: null,
            file_ids: [],
            metadata: {},
            type: "buttons",
            highlight: index,
          };
          setMessages((prevMessages) => [...prevMessages, buttonsMessage]);
          setPotentialPaths([]);
        } else {
          resolve(
            `showPotentialPaths created successfully. The user chose ${path}. Now run the displayTitleandDescription function.`
          );
          // Create a message for the buttons to be displayed
          const buttonsMessage: Message = {
            id: "",
            object: "message",
            created_at: Math.floor(Date.now() / 1000),
            thread_id: threadId,
            role: "pathfinder",
            content: potentialPaths.map((p) => ({
              type: "text",
              text: { annotations: [], value: p },
            })),
            assistant_id: null,
            run_id: null,
            file_ids: [],
            metadata: {},
            type: "buttons",
            highlight: index,
          };
          setMessages((prevMessages) => [...prevMessages, buttonsMessage]);
          setPotentialPaths([]);
          // Create a temporary message
          const tempMessage: Message = {
            id: "", // This will be updated after the axios request
            object: "message",
            created_at: Math.floor(Date.now() / 1000),
            thread_id: threadId,
            role: "pathfinder",
            content: [
              {
                type: "text",
                text: { annotations: [], value: "Creating page..." },
              },
            ],
            assistant_id: null,
            run_id: null,
            file_ids: [],
            metadata: {},
            type: "page",
          };
          setMessages((prevMessages) => [...prevMessages, tempMessage]);
          setPageLoading(true);
        }
      });
    });
  }

  function requestResourceFeedback(): Promise<string> {
    return new Promise((resolve) => {
      // Create a message asking for feedback
      const buttonsMessage: Message = {
        id: "",
        object: "message",
        created_at: Math.floor(Date.now() / 1000),
        thread_id: threadId,
        role: "pathfinder",
        content: potentialPaths.map((p) => ({
          type: "text",
          text: { annotations: [], value: p },
        })),
        assistant_id: null,
        run_id: null,
        file_ids: [],
        metadata: {},
        type: "buttons",
        // highlight: index,
      };
      setMessages((prevMessages) => [...prevMessages, buttonsMessage]);
  
      // Resolve the promise with a message indicating the feedback request was created
      resolve("Feedback request created successfully.");
    });
  }
  
  // Define the handleFeedback function to process the user's feedback
  function handleFeedback(type: "positive" | "negative"): void {
    // Process the feedback here, e.g., log it or update the state
    console.log(`User gave ${type} feedback.`);
    // Optionally, you can display a thank you message or take additional actions
  }

  interface RiskMessage {
    sender: string;
    message: string;
    timestamp: string;
  }

  const sendRiskEmail = async (
    risk: string,
    risk_explanation: string,
    messages: RiskMessage[]
  ): Promise<string> => {
    // Format messages for email after sorting by timestamp
    const formattedMessages = messages
      .sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      )
      .map((msg) => `${msg.sender} (${msg.timestamp}): ${msg.message}`)
      .join("\n");

    let teacherName = "Unknown Teacher";
    let teacherEmail = "contact@pathfindereducation.ai";

    try {
      const response = await axios.get(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getTeacherByStudent",
        {
          params: {
            studentEmail: user.email,
          },
        }
      );
      if (response) {
        teacherName = response.data.teacherName;
        teacherEmail = response.data.teacherEmail;
      }
    } catch (error) {
      console.error("Error fetching teacher details:", error);
    }

    // Prepare the parameters to be sent
    const emailParams = {
      user_name: user.name,
      teacher_name: teacherName,
      teacher_email: teacherEmail,
      safety_risk: risk,
      risk_explanation: risk_explanation,
      risk_transcript: formattedMessages,
      // Include any other parameters your email template needs
    };

    try {
      await emailjs.send(
        "service_v3xr936", // Your service ID
        "template_eav4h7m", // Your template ID
        emailParams,
        "In1oVi89f23i97sl5" // Your public key
      );
      return "Risk email sent successfully";
    } catch (error) {
      console.error("Error sending email:", error);
      return "Error sending risk email";
    }
  };

  function changeResources(newSelected: {
    title: string;
    url: string;
    description: string;
  }) {
    const updatedExtraResources = [...potentialResources, resource].filter(
      (r) => r !== newSelected
    );
    setPotentialResources(updatedExtraResources);
    setResource(newSelected);
    Cookie.set("resource", JSON.stringify(newSelected));
    Cookie.set("extraResources", JSON.stringify(updatedExtraResources));
  }

  function onExampleProjectSelected(project: string) {
    setSelectedExampleProject(project);
    setUserPrompt(
      `I am interested in ${project.split(" ").slice(1).join(" ")}`
    );
  }

  const handleRegenerateResources = async (
    setLoading: (loading: boolean) => void
  ) => {
    setLoading(true);
    await handleConfirmCancelRun();

    const messageContent = `I want different resources for ${title}`;

    setUserPrompt(messageContent);
    setPotentialResources([]);

    setLoading(false);
  };

  // useEffect hook that triggers when userPrompt changes
  useEffect(() => {
    if (
      userPrompt &&
      (userPrompt.startsWith("I want different resources for") ||
        userPrompt.startsWith("  ") ||
        userPrompt.startsWith("Can you create a resources page for me?") ||
        userPrompt.startsWith("Can you suggest me some options?") ||
        userPrompt.startsWith("Can you find some images for me?") ||
        userPrompt.startsWith("Go deeper into"))
    ) {
      // Call handlePromptSubmission only if the prompt is set to the desired message or starts with double spaces
      handlePromptSubmission(true);
    }
  }, [userPrompt]);

  const fetchSuggestedPrompts = useCallback(async () => {
    let allMessages = "";
    try {
      try {
        const response = await axios.get(
          `https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/fetchMessages`,
          {
            params: {
              threadId: threadId,
            },
          }
        );
        const messages = response.data.newMessages;
        const reversedMessages = [...messages].reverse();
        const combinedMessages = reversedMessages
          .map((msg) => msg.content[0].text.value)
          .join(" ");
        allMessages += combinedMessages + ". ";
        // console.log("all messages: ", allMessages);
      } catch (error) {
        console.error("Error fetching messages for threadId:", threadId, error);
      }
      // console.log("all messages: ", allMessages)
      if (allMessages.trim().length > 0) {
        const response = await axios.get(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getSuggestedPrompts",
          {
            params: {
              chat: allMessages,
            },
          }
        );
        const promptsString = response.data.prompts;
        const promptsArray = promptsString.split(";");
        // console.log("PROMPTS ARRAY: " + promptsArray)
        setSuggestedPrompts(promptsArray);
      }
    } catch (error) {
      console.error("Error fetching suggested prompts:", error);
    }
  }, [threadId]);

  useEffect(() => {
    if (aiResponseReceived) {
      setTimeout(() => {
        fetchSuggestedPrompts();
        setAiResponseReceived(false);
      }, 5000);
    }
  }, [aiResponseReceived, fetchSuggestedPrompts]);

  const handleSuggestedPromptSelection = async (prompt: string) => {
    setUserPrompt("  " + prompt); //called automatically in useEffect
  };

  useEffect(() => {
    if (messages.length === 0 && userChatsId && display) {
      setShowChats(true);
    } else if (
      messages.length > 0 &&
      userChatsId &&
      messages[messages.length - 1].role === "user" &&
      display
    ) {
      setShowChats(false);
    }
  }, [messages, userChatsId, display]);

  async function incrementFindImagesClick() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/incrementFindImagesClick",
          { email: userEmail }
        );
      }
    } catch (error) {
      console.error("Error incrementing FindImagesClick:", error);
    }
  }

  async function incrementSuggestOptionsClick() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/incrementSuggestOptionsClick",
          { email: userEmail }
        );
      }
    } catch (error) {
      console.error("Error incrementing FindImagesClick:", error);
    }
  }


  const handleFindImagesRHSClick = () => {
    const message = "Can you find some images for me?";
    let index = 0;
    setUserPrompt(""); // Clear existing prompt
    incrementFindImagesClick();

    const intervalId = setInterval(() => {
      if (index < message.length) {
        setUserPrompt(message.slice(0, index + 1));
        if (index + 3 < message.length) {
          index += 3;
        } else {
          index += 1;
        }
      } else {
        clearInterval(intervalId);
      }
    }); // Adjust typing speed as needed
  };

  const handleCreatePageRHSClick = () => {
    const message = "Can you create a resources page for me?";
    let index = 0;
    setUserPrompt(""); // Clear existing prompt

    const intervalId = setInterval(() => {
      if (index < message.length) {
        setUserPrompt(message.slice(0, index + 1));
        if (index + 3 < message.length) {
          index += 3;
        } else {
          index += 1;
        }
      } else {
        clearInterval(intervalId);
      }
    }); // Adjust typing speed as needed
  };

  const handleSuggestOptionsClick = () => {
    const message = "Can you suggest me some options?";
    let index = 0;
    setUserPrompt(""); // Clear existing prompt
    incrementSuggestOptionsClick();

    const intervalId = setInterval(() => {
      if (index < message.length) {
        setUserPrompt(message.slice(0, index + 1));
        if (index + 3 < message.length) {
          index += 3;
        } else {
          index += 1;
        }
      } else {
        clearInterval(intervalId);
      }
    }); // Adjust typing speed as needed
  };

  const updateArticlesCount = async (newCount: number) => {
    const email = localStorage.getItem("userEmail");
    try {
      await axios.post(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/updateNumberArticles", 
        {
          userEmail: email,
          newCount: newCount,
        });
    } catch (error) {
      console.error('Failed to update articles count', error);
    }
  };
  
  // useEffect hook to watch for articlesCount changes
  useEffect(() => {
    const timer = setTimeout(() => {
      updateArticlesCount(articlesCount);
    }, 5000); // 5 seconds delay
  
    return () => clearTimeout(timer); 
  }, [articlesCount]);

  return (
    <>
      <div className={`flex flex-row bg-white dark:bg-pf-navy`}>
        {!display ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            {showChats && userChatsId && (
              <>
                <SideMenu
                  showChats={showChats}
                  setShowChats={setShowChats}
                  notionLink={notionLink}
                  userChatsId={userChatsId}
                  setThreadId={setThreadId}
                  setParentId={setParentId}
                  setShowOverview={setShowOverview}
                  calculatedHeight2={calculatedHeight2}
                />
              </>
            )}
            <div className="flex-grow bg-white dark:bg-pf-navy">
              {messages.length === 0 ? (
                <div
                  className={`relative ${calculatedHeight} flex flex-col md:flex-row gap-1 md:gap-4 transition-all duration-1000 ease-in-out w-full
          }`}
                >
                  {!showChats && userChatsId && (
                    <>
                      <div className="hidden h-sm:block">
                        <PathFinderButton
                          outlined={false}
                          onClick={() => {
                            setShowChats(!showChats);
                          }}
                        >
                          <FontAwesomeIcon icon={faBars} />
                        </PathFinderButton>
                      </div>
                      <div className="block h-sm:hidden">
                        <PathFinderButton
                          size="xs"
                          outlined={false}
                          onClick={() => {
                            setShowChats(!showChats);
                          }}
                        >
                          <div className="px-1">
                            <FontAwesomeIcon icon={faBars} size="sm" />
                          </div>
                        </PathFinderButton>
                      </div>
                    </>
                  )}
                  <div className="flex flex-col w-full items-center justify-center">
                    <div className="flex flex-col w-4/5 items-center justify-center">
                      <div className="flex flex-col items-center justify-center h-full w-full">
                        <h3 className="font-semibold w-4/5 text-black dark:text-white text-center text-3xl ml-2 mb-8">
                          {userEmail && (userEmail.includes("actonacademyfl") || testEmails.includes(userEmail)) && actonToggle
                            ? "What did you learn today?"
                            : displayedText}
                        </h3>
                        <PromptInput
                          userPrompt={userPrompt}
                          setUserPrompt={setUserPrompt}
                          onPromptSubmission={handlePromptSubmission}
                          onFileUpload={handleFileSubmission}
                          assistantWriting={assistantWriting}
                          handleCancelRun={handleCancelRunModal}
                          runActive={runActive}
                          numMessages={messages.length}
                          focus={focus}
                          setFocus={setFocus}
                          userEmail={userEmail}
                          handleImagePromptSubmission={
                            handleImagePromptSubmission
                          }
                          handleImagePromptSubmissionHelper={
                            handleImagePromptSubmissionHelper
                          }
                        />
                      </div>
                      <h5 className="mt-[8rem] h-sm:mt-[12rem] text-black dark:text-white text-center">
                        Popular projects this week:
                      </h5>
                      <div className="flex flex-row text-center place-items-center mt-2">
                        {exampleProjects.map((project) => (
                          <OptionButton
                            path={project}
                            selectedPath={selectedExampleProject}
                            onPathSelected={() =>
                              onExampleProjectSelected(project)
                            }
                          />
                        ))}
                      </div>
                      {userEmail && (userEmail.includes("actonacademyfl") || testEmails.includes(userEmail)) && (
                        <div className="absolute top-0 right-0 p-4">
                          <label htmlFor="acton-toggle" className="flex items-center cursor-pointer">
                            <div className="relative">
                              <input
                                id="acton-toggle"
                                type="checkbox"
                                className="sr-only"
                                checked={actonToggle}
                                onChange={handleToggleChange}
                              />
                              <div className={`block w-14 h-8 rounded-full ${actonToggle ? 'bg-green-500' : 'bg-gray-600'}`}></div>
                              <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${actonToggle ? 'translate-x-full' : ''}`}></div>
                            </div>
                            <div className="ml-3 text-gray-700 font-medium">
                              Reflection Mode
                            </div>
                          </label>
                        </div>
                      )}
                      {(
                        <ThemeProvider theme={theme}>
                          <div className="absolute bottom-0 right-0 p-4">
                            <div className="space-y-6">
                              <div>
                                <Typography id="articles-slider" gutterBottom>
                                  Number of Articles
                                </Typography>
                                <Slider
                                  aria-labelledby="articles-slider"
                                  value={articlesCount}
                                  onChange={handleArticlesChange}
                                  valueLabelDisplay="auto"
                                  step={1}
                                  marks
                                  min={0}
                                  max={6}
                                />
                              </div>
                              <div>
                                <Typography id="videos-slider" gutterBottom>
                                  Number of Videos
                                </Typography>
                                <Slider
                                  aria-labelledby="videos-slider"
                                  value={videosCount}
                                  onChange={handleVideosChange}
                                  valueLabelDisplay="auto"
                                  step={1}
                                  marks
                                  min={0}
                                  max={6}
                                />
                              </div>
                            </div>
                          </div>
                        </ThemeProvider>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`flex flex-col ${
                    showChats && userChatsId
                      ? "max-w-[calc(100vw-350px)] w-[75vw]"
                      : "w-full"
                  } md:flex-row gap-1 md:gap-4 max-${calculatedHeight} bg-white dark:bg-pf-navy`}
                >
                  <>
                    <div
                      className={`flex flex-col space-y-5 ${calculatedHeight} transition-all duration-1000 ease-in-out ${
                        focus === "chat"
                          ? "md:w-3/5"
                          : focus === "page"
                          ? "md:w-2/5"
                          : "md:w-1/2"
                      }`}
                    >
                      <div className="flex justify-between bg-white dark:bg-pf-navy">
                        <div className="flex space-x-2">
                          {!showChats && userChatsId && (
                            <>
                              <div className="hidden h-sm:block">
                                <PathFinderButton
                                  outlined={false}
                                  onClick={() => {
                                    setShowChats(!showChats);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faBars} />
                                </PathFinderButton>
                              </div>
                              <div className="block ml-[-2rem] h-sm:hidden">
                                <PathFinderButton
                                  size="xs"
                                  outlined={false}
                                  onClick={() => {
                                    setShowChats(!showChats);
                                  }}
                                >
                                  <div className="px-1">
                                    <FontAwesomeIcon icon={faBars} size="sm" />
                                  </div>
                                </PathFinderButton>
                              </div>
                            </>
                          )}
                          <>
                            <div className=" space-x-2 flex items-center">
                              <PathFinderButton
                                outlined={true}
                                loading={newChatLoading}
                                onClick={async () => {
                                  setNewChatLoading(true);
                                  createThread();
                                  setMessages([]);
                                  setPageLoading(false);
                                  setTitle("");
                                  setDescription("");
                                  setResource(null);
                                  setObjectives([""]);
                                  setPotentialPaths([]);
                                  setPotentialResources([]);
                                  setImages([]);
                                  setImageTitle("");
                                  Cookie.remove("title");
                                  Cookie.remove("description");
                                  Cookie.remove("images");
                                  Cookie.remove("imageTitle");
                                  Cookie.remove("resource");
                                  Cookie.remove("objectives");
                                  Cookie.remove("extraResources");
                                  setFocus("neutral"); // Reset focus when starting a new chat
                                }}
                              >
                                New chat
                              </PathFinderButton>
                              <PathFinderButton
                                outlined={true}
                                onClick={async () => {
                                  setUserPrompt("Can you create a resources page for me?")
                                }}
                              >
                                Find Me Resources
                              </PathFinderButton>
                              {userEmail && (userEmail.includes("actonacademyfl") || testEmails.includes(userEmail)) && (
                                  <label htmlFor="acton-toggle" className="flex items-center cursor-pointer ml-4">
                                    <div className="relative">
                                      <input
                                        id="acton-toggle"
                                        type="checkbox"
                                        className="sr-only"
                                        checked={actonToggle}
                                        onChange={handleToggleChange}
                                      />
                                      <div className={`block w-14 h-8 rounded-full ${actonToggle ? 'bg-green-500' : 'bg-gray-600'}`}></div>
                                      <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${actonToggle ? 'translate-x-full' : ''}`}></div>
                                    </div>
                                    <div className="ml-3 text-gray-700 font-medium">
                                      Reflection Mode
                                    </div>
                                  </label>
                              )}
                            </div>
                          </>
                        </div>
                        {parentId && (
                          <PathFinderButton
                            outlined={false}
                            onClick={() => {
                              setShowOverview(!showOverview);
                              incrementSeeAllPages();
                            }}
                          >
                            {showOverview
                              ? "See current page"
                              : "See all pages"}
                          </PathFinderButton>
                        )}
                      </div>
                      <div className="flex flex-col h-full justify-center flex-grow overflow-y-scroll">
                        <ChatHistory
                          messages={messages}
                          assistantWriting={assistantWriting}
                          potentialPaths={potentialPaths}
                          onPathSelected={onPathSelected}
                          messagesEndRef={messagesEndRef}
                          suggestedPrompts={suggestedPrompts}
                          handleSuggestedPromptSelection={
                            handleSuggestedPromptSelection
                          }
                          setUserPrompt={setUserPrompt}
                        />
                      </div>
                      <div>
                        <PromptInput
                          userPrompt={userPrompt}
                          setUserPrompt={setUserPrompt}
                          onPromptSubmission={handlePromptSubmission}
                          onFileUpload={handleFileSubmission}
                          assistantWriting={assistantWriting}
                          handleCancelRun={handleCancelRunModal}
                          runActive={runActive}
                          numMessages={messages.length}
                          focus={focus}
                          setFocus={setFocus}
                          userEmail={userEmail}
                          handleImagePromptSubmission={
                            handleImagePromptSubmission
                          }
                          handleImagePromptSubmissionHelper={
                            handleImagePromptSubmissionHelper
                          }
                          handleFindImagesClick={handleFindImagesRHSClick}
                          handleCreatePageClick={handleCreatePageRHSClick}
                          handleSuggestOptionsClick={handleSuggestOptionsClick}
                        />
                      </div>
                    </div>
                    <div
                      className={`flex flex-col max-${calculatedHeight2} transition-all duration-1000 ease-in-out ${
                        focus === "page"
                          ? "md:w-3/5"
                          : focus === "chat"
                          ? "md:w-2/5"
                          : "md:w-1/2"
                      }`}
                    >
                      <InteractiveBox
                        loading={pageLoading}
                        title={title}
                        description={description}
                        resource={resource}
                        imageTitle={imageTitle}
                        images={images}
                        objectives={objectives}
                        extraResources={potentialResources}
                        onResourceSelected={onResourceSelected}
                        changeResources={changeResources}
                        notionLink={notionLink}
                        showOverview={showOverview}
                        handleRegenerateResources={handleRegenerateResources}
                        handleCreatePageClick={handleCreatePageClick}
                        threadId={threadId}
                        parentId={parentId}
                        setParentId={setParentId}
                        handleFindImagesRHSClick={handleFindImagesRHSClick}
                        handleCreatePageRHSClick={handleCreatePageRHSClick}
                      />
                    </div>
                  </>
                </div>
              )}
              {showCancelModal && runActive && (
                <DeleteModal
                  showModal={showCancelModal}
                  handleConfirmDelete={handleConfirmCancelRun}
                  setShowModal={setShowCancelModal}
                  loading={isCancelling}
                  text="I'm thinking... Do you want me to help you with something else?"
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AssistantComponent;
