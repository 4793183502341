import React, { useEffect, useState } from "react";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import Authenticator from "../../../services/authenticator";
import AddProjectCard from "../AddProjectCard";
import ProjectCard, { ProjectCardProps } from "../ProjectCard";

const AllUserProjectCards = ({
  portfolioId,
  isPortfolio,
}: {
  portfolioId?: number;
  isPortfolio: boolean;
}) => {
  const [myProjects, setMyProjects] = useState<ProjectCardProps[]>([
    {
      title: "",
      description: "",
      fileStorageLink: "",
      removeProjCardCallback: () => {},
      // showOnPortfolio: false,
      pathId: -1,
      // isPortfolio: false,
    },
  ]);
  const [projectsLoading, setProjectsLoading] = useState(true);

  const getDashboardProjectInfo = async () => {
    const userId = portfolioId
      ? portfolioId
      : await Authenticator.getCurrentUserId();

    const response = await axios.post(
      // "http://127.0.0.1:8081/thepathfinderprojectbackendapi/us-central1/getAllUserProjects",
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getAllUserProjects",
      {
        userId: userId,
      }
    );
    if (response) {
      setMyProjects(response.data);
    }
    setProjectsLoading(false);
  };

  useEffect(() => {
    getDashboardProjectInfo();
  }, []);

  return projectsLoading ? (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mb-4">
      {[...Array(4)].map((_, index) => (
        <div key={index}>
          <Skeleton
            key={index}
            variant="rectangular"
            height={150}
            animation="wave"
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "3rem" }}
            width={"80%"}
            animation="wave"
          />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation="wave" />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"70%"}
            animation="wave"
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"90%"}
            animation="wave"
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"80%"}
            animation="wave"
          />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation="wave" />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"90%"}
            animation="wave"
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"80%"}
            animation="wave"
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"60%"}
            animation="wave"
          />{" "}
        </div>
      ))}
    </div>
  ) : (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mb-4">
      {myProjects.map((project) => {
        if (project.pathId !== undefined) {
          return (
            <ProjectCard
              key={project.title} // Consider using a unique id if available
              title={project.title}
              description={project.description}
              fileStorageLink={project.fileStorageLink}
              removeProjCardCallback={() => {
                setMyProjects(
                  myProjects.filter((p) => p.title !== project.title)
                );
              }}
              // showOnPortfolio={project.showOnPortfolio}
              pathId={project.pathId}
              // isPortfolio={isPortfolio}
            />
          );
        } else {
          return <div></div>;
        }
      })}
      {!isPortfolio && <AddProjectCard setProjects={setMyProjects} />}
    </div>
  );
};

export default AllUserProjectCards;
