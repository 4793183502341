import { useState } from "react";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { Label, TextInput, Button } from "flowbite-react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountsTemplate from "../../components/AccountsTemplate";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { useNavigate } from "react-router-dom";

interface ForgotPasswordFormStage1 {
  email: string;
}

interface ForgotPasswordFormStage2 extends ForgotPasswordFormStage1 {
  code: string;
  password: string;
  confirmPassword: string;
}

// Separate Yup schemas for each stage
const formSchema1 = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});

const formSchema2 = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  code: Yup.string().required("Code is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [stage, setStage] = useState(0);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  // Separate useForm instances for each stage
  const formStage1 = useForm<ForgotPasswordFormStage1>({
    mode: "onBlur",
    resolver: yupResolver(formSchema1),
  });

  const formStage2 = useForm<ForgotPasswordFormStage2>({
    mode: "onBlur",
    resolver: yupResolver(formSchema2),
  });

  const onSubmit = async (data: any) => {
    if (stage === 0) {
      try {
        await Auth.forgotPassword(data.email);
        setStage(1);
      } catch (error) {
        console.error("Error in forgot password", error);
      }
    } else if (stage === 1) {
      if (data.password !== data.confirmPassword) {
        console.error("Passwords do not match");
        return;
      }
      try {
        await Auth.forgotPasswordSubmit(data.email, data.code, data.password);
        navigate("/login");
      } catch (error) {
        console.error("Error in forgot password submit", error);
      }
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  function resetErrorState() {
    setShowError(false);
    setErrorMessage("");
  }

  return (
    <form
      onSubmit={
        stage === 0
          ? formStage1.handleSubmit(onSubmit)
          : formStage2.handleSubmit(onSubmit)
      }
    >
      {showError && (
        <div
          className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
          role="alert"
        >
          <p className="font-bold">Passwords do not match :( </p>
        </div>
      )}
      <div className="mb-2 block">
        <div className="mb-2 block">
          <Label htmlFor="email" value="Your email" />
        </div>
        <TextInput
          id="email1"
          type="email"
          placeholder="Email"
          {...(stage === 0
            ? formStage1.register("email")
            : formStage2.register("email"))}
        />
        {(stage === 0
          ? formStage1.formState.errors.email
          : formStage2.formState.errors.email) && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">
              {stage === 0
                ? formStage1.formState.errors.email.message
                : formStage2.formState.errors.email.message}
            </span>
          </div>
        )}
      </div>
      {stage === 1 && (
        <>
          <div className="mb-2 block">
            <div className="mb-2 block">
              <Label htmlFor="code" value="Verification Code (sent to your email)" />
            </div>
            <TextInput
              id="code"
              type="text"
              placeholder="Verification Code"
              {...formStage2.register("code", { required: true })}
            />
            {formStage2.formState.errors.code && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">Code is required</span>
              </div>
            )}
          </div>
          <div className="mb-2 block">
            <div className="mb-2 block">
              <Label htmlFor="password" value="New Password" />
            </div>
            <div className="flex items-center">
              <TextInput
                id="password"
                type={passwordShown ? "text" : "password"}
                placeholder="Password"
                {...formStage2.register("password")}
                className="w-full"
              />
              <span
                className="cursor-pointer relative right-10"
                onClick={() => setPasswordShown(!passwordShown)}
              >
                {passwordShown ? (
                  <VisibilityOffSharpIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <VisibilitySharpIcon className="h-5 w-5 text-gray-500" />
                )}
              </span>
            </div>
            {formStage2.formState.errors.password && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">Password is required</span>
              </div>
            )}
          </div>
          <div className="mb-2 block">
            <div className="mb-2 block">
              <Label htmlFor="confirmPassword" value="Confirm New Password" />
            </div>
            <div className="flex items-center">
              <TextInput
                id="confirmPassword"
                type={confirmPasswordShown ? "text" : "password"}
                placeholder="Password"
                {...formStage2.register("confirmPassword")}
                className="w-full"
              />
              <span
                className="cursor-pointer relative right-10"
                onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}
              >
                {confirmPasswordShown ? (
                  <VisibilityOffSharpIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <VisibilitySharpIcon className="h-5 w-5 text-gray-500" />
                )}
              </span>
            </div>
            {formStage2.formState.errors.confirmPassword && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">
                  {formStage2.formState.errors.confirmPassword.message}
                </span>
              </div>
            )}
          </div>
        </>
      )}
      <div className="w-full bg-092056 mt-10">
        <Button
          className="w-32 bg-pf-navy text-white font-bold p-2 rounded-md float-right"
          type="submit"
        >
          {loading ? (
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </form>
  );
};

const ForgotPassword = () => {
  return (
    <AccountsTemplate
      bg_img="bg-pf-navy bg-contain bg-cover bg-center bg-no-repeat
                bg-[url('/src/assets/onboardingDiagram.png')]"
      title="Forgot password? No problem."
      subtitle=""
      form_component={<ForgotPasswordForm />}
    ></AccountsTemplate>
  );
};

export default ForgotPassword;
