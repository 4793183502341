import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Label, TextInput } from "flowbite-react";
import PathFinderButton from "../../components/common/PathFinderButton";
import phoneIcon from "../../assets/phone_icon.png";
import emailIcon from "../../assets/email_icon.png";
import videoCallIcon from "../../assets/video_call_icon.png";
import instagramIcon from "../../assets/instagram_icon.png";
import linkedinIcon from "../../assets/linkedin_icon.png";
import bulbImage from "../../assets/yellow-dashed-bulb.png";
import arrowImage from "../../assets/yellow-dashed-arrow.png";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../contexts/UserContext";
import RootNoFooterPage from "../rootNoFooter";

interface FormDetails {
  from_name: string;
  from_email: string;
  message: string;
}
interface SuggestFeatureFormProps {
  preFilledMessage?: string; // Prop to receive the pre-filled content for the description box
}

const SuggestFeatureForm = ({ preFilledMessage }: SuggestFeatureFormProps) => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useRef();
  const { user } = useUser();
  const preFilledMessageValue = preFilledMessage || ""; // If preFilledMessage is undefined, use an empty string

  const formSchema = Yup.object().shape({
    from_name: Yup.string().required("Name is required"),
    from_email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm<FormDetails>({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
    defaultValues: {
      from_name: user?.name, // Use user's name
      from_email: user?.email, // Use user's email
      message: preFilledMessage || "", // Pre-filled message or empty string
    },
  });

  const sendEmail = () => {
    setIsSubmitting(true);
    emailjs
      .sendForm(
        "service_lnffz7b",
        "template_5oifwxb",
        form.current,
        "1mrtCcpYjeR_RckcO"
      )
      .then(
        (result) => {
          resetErrorState();
          setIsSent(true);
          setIsSubmitting(false);
          reset();
        },
        (error) => {
          setShowError(true);
          setErrorMessage(error.text);
        }
      );
  };

  function resetErrorState() {
    setShowError(false);
    setErrorMessage("");
  }

  return (
    <div className="pt-4 md:pt-8 px-8 lg:p-8">
      <h1 className="xs:pb-6 text-3xl md:text-4xl lg:text-4xl 2xl:text-5xl font-bold text-pf-purple text-center lg:my-12">
        Give Feedback / Suggest Feature
      </h1>
      <form
        className="flex flex-col gap-4"
        ref={form}
        onSubmit={handleSubmit(sendEmail)}
      >
        {showError && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}

        <div>
          <div className="mb-3 block">
            <Label htmlFor="from_name" value="Your name" />
          </div>
          <TextInput
            id="name1"
            placeholder="Name"
            {...register("from_name")}
            className="border border-pf-purple rounded-lg"
          />
          {errors.from_name && errors.from_name.message && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">
                {errors.from_name.message}
              </span>
            </div>
          )}
        </div>
        <div>
          <div className="mb-3 block">
            <Label htmlFor="from_email" value="Your email" />
          </div>
          <TextInput
            id="email1"
            placeholder="Email"
            type="email"
            {...register("from_email")}
            className="border border-pf-purple rounded-lg"
          />
          {errors.from_email && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">
                {errors.from_email.message}
              </span>
            </div>
          )}
        </div>
        <div className="block">
          <Label htmlFor="message" value="Your suggestion" />
        </div>
        <textarea
          className="w-full border border-pf-purple rounded-lg focus:outline-none focus:ring-1 focus:ring-purple-500"
          rows={6}
          style={{ boxSizing: "border-box" }}
          placeholder="Your suggestion"
          {...register("message", { value: preFilledMessageValue })} // Set the pre-filled content as the default value
        ></textarea>
        {errors.message && errors.message.message && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{errors.message.message}</span>
          </div>
        )}
        <div className="xs:pt-4 flex justify-center lg:justify-end">
          <PathFinderButton
            type="submit"
            loading={isSubmitting}
            onClick={() => {}}
            disabled={isSubmitting}
          >
            {isSent ? (
              <div>
                <FontAwesomeIcon icon={faCheck} className="text-white pr-2" />
                Sent, thank you!
              </div>
            ) : (
              <div>
                Send
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  className="text-white pl-2"
                />
              </div>
            )}
          </PathFinderButton>
        </div>
      </form>
    </div>
  );
};

const SuggestFeatureInfo = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-pf-navy m-8 xs:px-4 sm:p-4 rounded-xl h-full relative space-y-8 flex flex-col justify-between overflow-hidden">
      <div className="flex justify-between items-center">
        <PathFinderButton
          onClick={() => {
            navigate("/");
          }}
        >
          <h2 className="text-lg">Go back to chat</h2>
        </PathFinderButton>
      </div>
      <img
        src={arrowImage}
        alt="Bulb top right"
        className="absolute top-24 xs:hidden sm:right-8 right-10 lg:top-20 lg:right-8 sm:h-1/3 h-1/2 lg:h-1/3 transform translate-x-1/2 -translate-y-1/2"
      />
      <div className="xs:mx-2 mx-8">
        <h2 className="text-3xl md:text-4xl lg:text-4xl 2xl:text-5xl font-bold text-white mb-6">
          Give your feedback!
        </h2>
        <p className="text-white xs:w-full xs:text-sm sm:text-lg xl:text-xl mb-4 sm:mb-8 mt-4 lg:pr-4 w-5/6 xl:w-3/4">
          We want to make PathFinder the best experience for you. If you have
          any feedback or exciting suggestions on new features, please suggest 
          so we can improve PathFinder for you!
        </p>
      </div>
      <div className="mx-4 sm:mx-8 sm:pl-8 space-y-6 pb-8 sm:pb-32">
        <div className="flex items-center">
          <img
            src={phoneIcon}
            alt="phone icon"
            className="mr-2 w-6 h-6 sm:w-8 sm:h-8"
          />
          <a
            href="tel:+14159157252"
            className="pl-4 xs:text-xs text-white hover:underline hover:text-[#D6E54D]"
          >
            +1 (415) 915-7252
          </a>
        </div>
        <div className="flex items-center">
          <img
            src={emailIcon}
            alt="email icon"
            className="mr-2 w-6 h-6 sm:w-8 sm:h-8"
          />
          <a
            href="mailto:contact@pathfindereducation.ai"
            className="xs:text-xs pl-4 text-white hover:underline hover:text-[#D6E54D]"
          >
            contact@pathfindereducation.ai
          </a>
        </div>
        <div className="flex items-center">
          <img
            src={videoCallIcon}
            alt="video call icon"
            className="mr-2 w-6 h-6 sm:w-8 sm:h-8"
          />
          <a
            href="https://calendly.com/pathfinder-ai/30min"
            className="xs:text-xs pl-4 text-white hover:underline hover:text-[#D6E54D]"
          >
            calendly.com/pathfinder-ai/
          </a>
        </div>
      </div>
      <div className="xs:pb-4 flex justify-end space-x-4">
        {/* <a href="https://instagram.com/pathfinder_education?igshid=OGQ5ZDc2ODk2ZA==">
          <img
            src={instagramIcon}
            alt="instagram icon"
            className="w-6 h-6 sm:w-8 sm:h-8"
          />
        </a> */}
        <a href="https://www.linkedin.com/company/thepathfinderproject/">
          <img
            src={linkedinIcon}
            alt="linkedin icon"
            className="w-6 h-6 sm:w-8 sm:h-8"
          />
        </a>
      </div>
      <img
        src={bulbImage}
        alt="Bulb bottom left"
        className="absolute xs:hidden bottom-16 left-16 transform sm:w-1/5 lg:w-1/3 xl:w-1/4 2xl:w-1/5 -translate-x-1/2 translate-y-1/2"
      />
    </div>
  );
};

const SuggestFeaturePage = () => {
  return (
    <RootNoFooterPage header={""}>
      <div className="lg:flex py-8 lg:flex-row justify-between mb-8 lg:mb-20">
        <div className="lg:w-1/2">
          <SuggestFeatureInfo />
        </div>
        <div className="lg:w-1/2">
          <SuggestFeatureForm />
        </div>
      </div>
    </RootNoFooterPage>
  );
};

export default SuggestFeaturePage;
