import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Label, TextInput } from "flowbite-react";
import PathFinderButton from "../common/PathFinderButton";
import { useNavigate } from "react-router-dom";

interface QuestionProps {
  question: string;
  description: string;
  inputProps: object;
  error: string | undefined;
}

const Question: React.FC<QuestionProps> = ({
  question,
  description,
  inputProps,
  error,
}) => (
  <>
    <div className="block mt-8 lg:mt-12">
      <h3 className="text-xl lg:text-2xl mb-2">{question}</h3>
      <Label value={description} />
    </div>
    <textarea
      className="w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-500 mt-4"
      rows={6}
      placeholder={"Write your answer here"}
      {...inputProps}
    ></textarea>
    {error && (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <span className="block sm:inline">{error}</span>
      </div>
    )}
  </>
);

interface FormDetails {
  from_name: string;
  from_email: string;
  q1_answer: string;
  q2_answer: string;
  q3_answer: string;
  q4_answer: string;
}

export const ApplicationForm: React.FC = () => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [q3Answer, setQ3Answer] = useState("");
  const form = useRef();
  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    from_name: Yup.string().required("Name is required"),
    from_email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    q1_answer: Yup.string().required("Answer is required"),
    q2_answer: Yup.string().required("Answer is required"),
    q3_answer: Yup.string().required("Answer is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset, // here is reset from useForm
  } = useForm<FormDetails>({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const sendEmail = () => {
    // console.log("Through to submit");
    // console.log(form.current);
    setIsSubmitting(true);
    emailjs
      .sendForm(
        "service_v3xr936",
        "template_eav4h7m",
        form.current,
        "In1oVi89f23i97sl5"
      )
      .then(
        (result) => {
          console.log(result.text);
          resetErrorState();
          setIsSent(true);
          setIsSubmitting(false);
          setLoading(true);
          reset(); // resetting form after successful submission
          navigate("/summer-cohort/success");
        },
        (error) => {
          console.log(error.text);
          setShowError(true);
          setErrorMessage(error.text);
        }
      );
  };

  function resetErrorState() {
    setShowError(false);
    setErrorMessage("");
  }

  return (
    <div className="pt-4 md:pt-8 px-8 lg:pt-8">
      <h1 className="xs:pb-6 text-3xl md:text-4xl lg:text-4xl 2xl:text-5xl font-bold text-pf-purple text-center mb-12">
        PathFinder Summer Bootcamp Application Form (5-10 mins)
      </h1>
      <form
        className="flex flex-col gap-4"
        ref={form}
        onSubmit={handleSubmit(sendEmail)}
      >
        <h3 className="text-xl lg:text-2xl">Your contact details</h3>
        {showError && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
        <div className="lg:flex lg:flex-row lg:space-x-8 ">
          <div className="lg:w-1/2">
            <div className="mb-3 block">
              <Label htmlFor="from_name" value="Your name" />
            </div>
            <TextInput
              id="name1"
              placeholder="Name"
              {...register("from_name")}
            />
            {errors.from_name && errors.from_name.message && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">
                  {errors.from_name.message}
                </span>
              </div>
            )}
          </div>
          <div className="lg:w-1/2">
            <div className="mt-4 lg:mt-0 mb-3 block">
              <Label htmlFor="from_email" value="Your email" />
            </div>
            <TextInput
              id="email1"
              placeholder="Email"
              type="email"
              {...register("from_email")}
            />
            {errors.from_email && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">
                  {errors.from_email.message}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="block">
          <Question
            question={
              "What is the most challenging thing you've worked on outside of coursework? Why were you drawn to it? (~100 words)"
            }
            description={
              "Think of a time when you really put your all into your work. We don't just mean you worked really hard on it. We all put a piece ourselves into the work we do, what is your favourite example of this?"
            }
            inputProps={register("q1_answer")}
            error={errors.q1_answer?.message}
          />
          <Question
            question={
              "What are some products of the future you find most exciting? Explain what they are and why you would find them exciting to work on. (~100 words)"
            }
            description={
              "Be completely honest, this question is to help us design the learning path to be as directly applicable as possible!"
            }
            inputProps={register("q2_answer")}
            error={errors.q2_answer?.message}
          />
          <div>
            <div className="block mt-8 lg:mt-12">
              <h3 className="text-xl lg:text-2xl mb-2">
                The in-person events held will be happening in London. Are you
                able going to be able to attend them in person?
              </h3>
              <Label value="You're either based in London or able to commute when needed" />
              <input type="hidden" {...register("q3_answer")} />
              <div className="flex flex-row justify-center space-x-8 mt-2">
                <button
                  type="button"
                  className={`mx-2 py-2 px-4 font-black rounded-lg border border-pf-purple text-pf-purple ${
                    q3Answer === "yes" ? "bg-pf-purple text-white" : "bg-white"
                  }`}
                  onClick={() => {
                    setQ3Answer("yes");
                    setValue("q3_answer", "Yes");
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={`mx-2 py-2 px-4 font-black rounded-lg border border-pf-purple text-pf-purple ${
                    q3Answer === "no" ? "bg-pf-purple text-white" : "bg-white"
                  }`}
                  onClick={() => {
                    setQ3Answer("no");
                    setValue("q3_answer", "No");
                  }}
                >
                  No
                </button>
              </div>
            </div>
            {errors.q3_answer?.message && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-4 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">
                  {errors.q3_answer.message}
                </span>
              </div>
            )}
          </div>
          <Question
            question={
              "To add a bit of skin in the game, we ask all participants for a £50 deposit. If you make it to demo day, you get it all back.\nIf you need a scholarship either for this or for commuting, please explain why below."
            }
            description={
              "For more information on this £50 deposit, see the description of the summer cohort: https://pathfindereducation.ai/summer-cohort.\nIf you don't need a scholarship, feel free to leave it blank."
            }
            inputProps={register("q4_answer")}
            error={errors.q4_answer?.message}
          />
        </div>
        <div className="xs:pt-4 flex justify-center">
          <PathFinderButton
            type="submit"
            loading={loading}
            onClick={() => {}}
            disabled={isSubmitting}
          >
            {isSent ? (
              <div>
                <FontAwesomeIcon icon={faCheck} className="text-white pr-2" />
                Sent
              </div>
            ) : (
              <div>
                Submit
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  className="text-white pl-2"
                />
              </div>
            )}
          </PathFinderButton>
        </div>
      </form>
    </div>
  );
};

export default ApplicationForm;
