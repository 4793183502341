import { useEffect, useState } from "react";
import { ReactComponent as HeaderLogo } from "../../assets/headerLogo.svg";
import { useNavigate } from "react-router-dom";
import Authenticator from "../../services/authenticator";
import axios from "axios";
import ReactGA from "react-ga4";
import PathFinderButton from "../common/PathFinderButton";
import { Link } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "@mui/material/Skeleton";
import amplitudeInstance, {
  logEvent,
  generateUniqueUserId,
} from "../../services/amplitudeService";
import { Tooltip } from "flowbite-react";

interface Props {
  active: string;
  onSignOut?: () => void;
  window?: () => Window;
}

export default function DrawerAppBar(props: Props) {
  const navigate = useNavigate();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);
  const [clickedLogout, setClickedLogout] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const { user, setUser } = useUser();

  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle("dark");

    const uniqueUserId = generateUniqueUserId(user.email);
    amplitudeInstance.setUserId(uniqueUserId.toString());
    logEvent("DARK_MODE", {
      userId: uniqueUserId.toString(),
    });
  };

  useEffect(() => {
    const fetchPendingRequests = async () => {
      // console.log("User role is: ", user.role);
      if (user?.role === "Learner") {
        try {
          const response = await axios.get(
            "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getPendingRequestsForStudent",
            {
              params: {
                studentEmail: user.email,
              },
            }
          );
          setPendingRequests(response.data);
        } catch (error) {
          // console.error("Error fetching pending requests:", error);
        }
      }
    };

    fetchPendingRequests();
  }, [user]);

  const handleResponse = async (decision: string) => {
    if (decision === "APPROVED") {
      setApproveLoading(true);
    } else {
      setDenyLoading(true);
    }
    try {
      const response = await axios.post(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/respondToTeacherRequest",
        {
          teacherEmail: pendingRequests[0].teacher.email,
          studentEmail: user.email,
          decision: decision,
        }
      );
      if (response.status === 200) {
        // Remove the request from the pendingRequests state or refetch the pending requests
        setPendingRequests(pendingRequests.slice(1));
      }
    } catch (error) {
      console.error("Error sending response to teacher request:", error);
    } finally {
      if (decision === "APPROVED") {
        setApproveLoading(false);
      } else {
        setDenyLoading(false);
      }
    }
  };

  const pendingRequestNotification = pendingRequests.length > 0 && (
    <div className="relative bg-pf-purple p-4 flex justify-between items-center">
      <p className="text-white text-xl">
        {pendingRequests[0].teacher.name} wants to add you as their student
      </p>
      <div className="flex gap-2">
        <PathFinderButton
          outlined={true}
          loading={approveLoading}
          disabled={false}
          onClick={() => handleResponse("APPROVED")}
        >
          Approve
        </PathFinderButton>
        <PathFinderButton
          outlined={true}
          loading={denyLoading}
          disabled={false}
          onClick={() => handleResponse("DENIED")}
        >
          Deny
        </PathFinderButton>
      </div>
    </div>
  );

  const checkUserLoggedIn = async () => {
    const resp = await Authenticator.ifSignedIn();
    setUserLoggedIn(resp);
    if (resp) {
      const storedName = localStorage.getItem("userName");
      const storedEmail = localStorage.getItem("userEmail");
      const storedRole = localStorage.getItem("userRole");
      if (storedName && storedEmail) {
        setUser({
          ...user,
          name: storedName,
          email: storedEmail,
          role: storedRole,
        });
      }
    }
  };

  useEffect(() => {
    checkUserLoggedIn();
    if (clickedLogout) {
      setClickedLogout(false);
    }
  }, [clickedLogout]);

  async function signOut() {
    try {
      setLoading(true);
      await Authenticator.signOut();
      localStorage.removeItem("userName");
      localStorage.setItem("userEmail", "");
      localStorage.setItem("userRole", "");
      setUser({});
      setUserLoggedIn(false);
      setLoading(false);
      navigate("/");
    } catch (error) {
      console.error("Cant sign out: ", error);
    }
  }

  function accountButtonRender() {
    if (user.email) {
      return (
        <div className="flex items-center space-x-4 lg:order-2">
          <Tooltip
            content={darkMode ? "Switch to light mode" : "Switch to dark mode"}
          >
            <button
              onClick={toggleDarkMode}
              className="p-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              <FontAwesomeIcon
                icon={darkMode ? faSun : faMoon}
                className={darkMode ? "text-white" : "text-black"}
              />
            </button>
          </Tooltip>
          {user.name ? (
            <h5 className="text-black text-[1.1rem] h-sm:text-[1.25rem] dark:text-white">
              Welcome back, {user.name}
            </h5>
          ) : (
            <div className="flex text-black gap-3 dark:text-white">
              <h5 className="text-black dark:text-white">Welcome back,</h5>
              <Skeleton
                variant="rectangular"
                height="1.75rem"
                width="200px"
                style={{ borderRadius: "4px" }}
              />
            </div>
          )}
          <div className="block h-sm:hidden">
            <PathFinderButton
              size="xs"
              onClick={() => navigate("/suggest-feature")}
            >
              <span className="text-[0.9rem]">Give Feedback</span>
            </PathFinderButton>
          </div>
          <div className="hidden h-sm:block">
            <PathFinderButton onClick={() => navigate("/suggest-feature")}>
              Give Feedback
            </PathFinderButton>
          </div>
          {(user.role === "Teacher" || user.role === "Parent") && (
            <>
              <div className="hidden h-sm:block">
                <PathFinderButton
                  onClick={() => navigate("/teacher-dashboard")}
                >
                  Teacher Dashboard
                </PathFinderButton>
              </div>
              <div className="block h-sm:hidden">
                <PathFinderButton
                  size="xs"
                  onClick={() => navigate("/suggest-feature")}
                >
                  <span className="text-[0.9rem]">Teacher Dashboard</span>
                </PathFinderButton>
              </div>
            </>
          )}
          <>
            <div className="hidden h-sm:block">
              <PathFinderButton
                outlined={true}
                onClick={async () => {
                  await signOut();
                  setClickedLogout(true);
                  props.onSignOut?.();
                }}
                loading={loading}
                disabled={false}
              >
                Log out
              </PathFinderButton>
            </div>
            <div className="block h-sm:hidden">
              <PathFinderButton
                size="xs"
                outlined={true}
                onClick={async () => {
                  await signOut();
                  setClickedLogout(true);
                  props.onSignOut?.();
                }}
                loading={loading}
                disabled={false}
              >
                <span className="text-[0.9rem]">Log out</span>
              </PathFinderButton>
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div className="flex items-center gap-4 lg:order-2">
          <Link
            to="/contact"
            className="text-pf-dark-grey dark:text-pf-grey hidden md:block underline mr-2 hover:text-gray-800"
          >
            Teach with PathFinder / Give Feedback
          </Link>
          <Tooltip
            content={darkMode ? "Switch to light mode" : "Switch to dark mode"}
          >
            <button
              onClick={toggleDarkMode}
              className="p-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              <FontAwesomeIcon
                icon={darkMode ? faSun : faMoon}
                className={darkMode ? "text-white" : "text-black"}
              />
            </button>
          </Tooltip>
          <PathFinderButton
            onClick={() => navigate("/login")}
            loading={false}
            disabled={false}
            outlined={true}
          >
            Log in
          </PathFinderButton>
          <PathFinderButton
            onClick={() => {
              ReactGA.event({
                action: "create_account",
                category: "header_links",
              });
              navigate("/create-account");
            }}
            loading={false}
            disabled={false}
          >
            Create an account
          </PathFinderButton>
        </div>
      );
    }
  }

  return (
    <div className="w-full">
      <header className="w-full">
        <nav className="bg-white dark:bg-pf-navy w-full mx-auto h-sm:pt-5 pt-3 px-4 2xl:px-0">
          <div className="flex flex-wrap justify-between items-center max-w-screen">
            <a href="/" className="flex items-center h-full">
              <HeaderLogo className="h-[1.75rem] h-sm:h-[2rem] w-[10rem] h-sm:w-[16rem]" />
            </a>
            <div className="flex items-center lg:order-2">
              <div className="block xs:hidden sm:block">
                {accountButtonRender()}
              </div>
              {/* Kebab Menu */}
              <div className="md:hidden">
                <button
                  type="button"
                  className="block text-gray-600 hover:text-gray-800 focus:text-gray-800 focus:outline-none"
                  onClick={() => {
                    const menu = document.getElementById("mobile-menu-2");
                    menu.classList.toggle("hidden");
                  }}
                >
                  <svg
                    className="h-10 w-10 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 6C11.45 6 11 6.45 11 7C11 7.55 11.45 8 12 8C12.55 8 13 7.55 13 7C13 6.45 12.55 6 12 6ZM12 10C11.45 10 11 10.45 11 11C11 11.55 11.45 12 12 12C12.55 12 13 11.55 13 11C13 10.45 12.55 10 12 10ZM12 14C11.45 14 11 14.45 11 15C11 15.55 11.45 16 12 16C12.55 16 13 15.55 13 15C13 14.45 12.55 14 12 14Z" />
                  </svg>
                </button>
              </div>
            </div>
            <div
              className={
                "hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
              }
              id="mobile-menu-2"
            >
              <ul className="md:hidden flex flex-col mt-4 font-bold lg:flex-row lg:space-x-4 xl:space-x-8 lg:mt-0">
                <Link
                  to="#"
                  className="text-[#6B7280] underline mr-2 hover:text-gray-800"
                >
                  Teach with PathFinder
                </Link>
                <li>
                  <button
                    onClick={() => {
                      ReactGA.event({
                        action: "about_us",
                        category: "header_links",
                      });
                      navigate("/about-us");
                    }}
                    className="block py-2 pr-4 pl-3 text-pf-purple hover:text-gray-800"
                  >
                    About us
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      ReactGA.event({
                        action: "explore",
                        category: "header_links",
                      });
                      navigate("/explore");
                    }}
                    className="block py-2 pr-4 pl-3 text-pf-purple hover:text-gray-800"
                  >
                    Explore paths
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      ReactGA.event({
                        action: "summer_cohort",
                        category: "header_links",
                      });
                      navigate("/summer-cohort");
                    }}
                    className="block py-2 pr-4 pl-3 text-pf-purple hover:text-gray-800"
                  >
                    Summer bootcamp
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      ReactGA.event({
                        action: "contact",
                        category: "header_links",
                      });
                      navigate("/contact");
                    }}
                    className="block py-2 pr-4 pl-3 text-pf-purple hover:text-gray-800"
                  >
                    Contact
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      ReactGA.event({
                        action: "contact",
                        category: "header_links",
                      });
                      navigate("/contact");
                    }}
                    className="block py-2 pr-4 pl-3 text-pf-purple hover:text-gray-800"
                  >
                    Teach with PathFinder
                  </button>
                </li>
                {userLoggedIn && (
                  <li>
                    <a
                      href="#"
                      className="block py-2 pr-4 pl-3 text-pf-purple md:hidden"
                      onClick={async () => {
                        await signOut();
                        setClickedLogout(true);
                        props.onSignOut?.();
                      }}
                    >
                      Log out
                    </a>
                  </li>
                )}
              </ul>
              <ul className="sm:hidden flex flex-col font-bold lg:flex-row lg:space-x-4 xl:space-x-8 lg:mt-0">
                {!userLoggedIn ? (
                  <>
                    <li>
                      <button
                        onClick={() => {
                          navigate("/login");
                        }}
                        className="block py-2 pr-4 pl-3 text-pf-purple hover:text-gray-800"
                      >
                        Log In
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          navigate("/get-started");
                        }}
                        className="block py-2 pr-4 pl-3 text-pf-purple hover:text-gray-800"
                      >
                        Create an account
                      </button>
                    </li>
                  </>
                ) : (
                  <li>
                    <button
                      onClick={async () => {
                        await signOut();
                        setClickedLogout(true);
                        props.onSignOut?.();
                      }}
                      className="block py-2 pr-4 pl-3 text-pf-purple hover:text-gray-800"
                    >
                      Log Out
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <hr className="bg-pf-purple border h-sm:mt-5 mt-3 border-pf-purple dark:bg-pf-green dark:border-pf-green" />
        </nav>
      </header>
      <div className="mt-4 ">{pendingRequestNotification}</div>
    </div>
  );
}
