import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import Authenticator from "../../../services/authenticator";

export const PathList = () => {
  const navigate = useNavigate();

  return (
    <div className="md:grid md:grid-rows-3 md:grid-cols-3 md:gap-8">
      <div className="px-3 md:px-0 row-span-4 col-span-1">
        <button
          className="mt-8 text-white bg-white font-black py-3 px-8 w-full h-full rounded-3xl bg-center bg-cover bg-[url('/src/assets/AIHealthcareRoadmapBG.png')]"
          onClick={async () => {
            ReactGA.event({
              action: "healthcare",
              category: "search_cards",
            });
            navigate(
              (await Authenticator.ifSignedIn())
                ? "/ai-for-healthcare/c1230bec0dd64526969a3f92e132f6f9"
                : "/ai-for-healthcare-preview"
            );
          }}
        >
          <p className="text-white text-2xl md:text-4xl md:mt-52 2xl:mt-60">
            AI for healthcare
          </p>
          <hr className="w-40 h-1 mx-auto my-4 bg-white border-0 rounded md:my-6"></hr>
          <p className="text-white font-black text-sm md:text-lg">3-4 months</p>
          <p className="text-white font-black text-sm md:text-lg">
            10+ resources
          </p>
        </button>

        <button
          className="invisible md:visible flex items-baseline md:mt-4 bg-pf-red md:py-3 px-8 w-full h-1/4 rounded-3xl bg-center bg-cover bg-[url('/src/assets/quantBG.png')]"
          onClick={() => {
            ReactGA.event({
              action: "quantum_computing",
              category: "search_cards",
            });
            //   scrollTo();
          }}
        ></button>
      </div>
      <div className="px-3 md:px-0 row-span-4 col-span-1">
        <button
          className="invisible md:visible flex items-center md:mt-8 bg-pf-green md:py-3 px-8 w-full h-1/4 rounded-3xl bg-center bg-cover bg-[url('/src/assets/financeBG.png')]"
          onClick={() => {
            ReactGA.event({
              action: "finance",
              category: "search_cards",
            });
            // scrollTo("waitlist");
          }}
        ></button>

        <button
          className="invisible md:visible flex items-center md:mt-4 bg-pf-navy md:py-3 px-8 w-full h-1/4 rounded-3xl bg-center bg-cover bg-[url('/src/assets/UIUXRoadmapBG.png')]"
          onClick={() => {
            ReactGA.event({
              action: "ui_ux",
              category: "search_cards",
            });
            // scrollTo("waitlist");
          }}
        ></button>
      </div>
      <div className="px-3 md:px-0 row-span-4 col-span-1">
        <button
          className="mt-4 md:mt-8 text-white bg-white font-black py-3 px-8 w-full h-full rounded-3xl bg-center bg-cover bg-[url('/src/assets/UIUXRoadmapBG.png')]"
          onClick={async () => {
            ReactGA.event({
              action: "ui_ux",
              category: "search_cards",
            });
            navigate(
              (await Authenticator.ifSignedIn())
                ? "/ui-ux-design/1f5bff438ca04dbcbae6e8adbdb0c012"
                : "/ui-ux-design-preview"
            );
          }}
        >
          <p className="text-white text-2xl md:text-4xl md:mt-52 2xl:mt-40">
            UI/UX Design
          </p>
          <hr className="w-40 h-1 mx-auto my-4 bg-white border-0 rounded md:my-6"></hr>
          <p className="text-white font-black text-sm md:text-lg">3-6 months</p>
          <p className="text-white font-black text-sm md:text-lg">
            10+ resources
          </p>
        </button>

        <button
          className="invisible md:visible flex items-center md:mt-4 bg-pf-navy md:py-3 px-8 w-full h-1/4 rounded-3xl bg-center bg-cover bg-[url('/src/assets/uiuxBG.png')]"
          onClick={() => {
            ReactGA.event({
              action: "ui_ux",
              category: "search_cards",
            });
            // scrollTo("waitlist");
          }}
        ></button>
      </div>
      <div className="px-3 md:px-0 row-span-4 col-span-1">
        <button
          className="mt-4 md:mt-8 text-white bg-white font-black py-3 px-8 w-full h-full rounded-3xl bg-center bg-cover bg-[url('/src/assets/CodingPythonRoadmapBG.png')]"
          onClick={async () => {
            ReactGA.event({
              action: "coding",
              category: "search_cards",
            });
            navigate(
              (await Authenticator.ifSignedIn())
                ? "/data-science-with-python/2c5872b1052b4f75ae839c0ed2f9678f"
                : "/data-science-with-python-preview"
            );
          }}
        >
          <p className="text-white text-2xl md:text-4xl md:mt-52 2xl:mt-40">
            Data Science with Python
          </p>
          <hr className="w-40 h-1 mx-auto my-4 bg-white border-0 rounded md:my-6"></hr>
          <p className="text-white font-black text-sm md:text-lg">3-4 months</p>
          <p className="text-white font-black text-sm md:text-lg">
            10+ resources
          </p>
        </button>

        <button
          className="mt-4 text-white bg-white font-black py-3 px-8 w-full h-full rounded-3xl bg-center bg-cover bg-[url('/src/assets/consultingRoadmapBG.png')]"
          onClick={async () => {
            ReactGA.event({
              action: "consulting",
              category: "search_cards",
            });
            navigate(
              (await Authenticator.ifSignedIn())
                ? "/management-consulting/e6c7a639df3740a3a6b048c79493cc43"
                : "/management-consulting-preview"
            );
          }}
        >
          <p className="text-white text-2xl md:text-4xl md:mt-52 2xl:mt-80">
            Management Consulting
          </p>
          <hr className="w-40 h-1 mx-auto my-4 bg-white border-0 rounded md:my-6"></hr>
          <p className="text-white font-black text-sm md:text-lg">1-2 months</p>
          <p className="text-white font-black text-sm md:text-lg">
            10+ resources
          </p>
        </button>
      </div>
      <div className="px-3 md:px-0 row-span-4 col-span-1">
        <button
          className="mt-4 md:mt-8 text-white bg-white font-black py-3 px-8 w-full h-full rounded-3xl bg-center bg-cover bg-[url('/src/assets/SEORoadmapBG.png')]"
          onClick={async () => {
            ReactGA.event({
              action: "seo",
              category: "search_cards",
            });
            navigate(
              (await Authenticator.ifSignedIn())
                ? "/search-engine-optimisation/7e0017f0cb5b40c996b5aa91b1f9384f"
                : "/search-engine-optimisation-preview"
            );
          }}
        >
          <p className="text-white text-2xl md:text-4xl md:mt-52 2xl:mt-40">
            Search Engine Optimisation
          </p>
          <hr className="w-40 h-1 mx-auto my-4 bg-white border-0 rounded md:my-6"></hr>
          <p className="text-white font-black text-sm md:text-lg">3-4 months</p>
          <p className="text-white font-black text-sm md:text-lg">
            10+ resources
          </p>
        </button>

        <button
          className="invisible md:visible flex items-center md:mt-4 bg-pf-navy md:py-3 px-8 w-full h-1/4 rounded-3xl bg-center bg-cover bg-[url('/src/assets/uiuxBG.png')]"
          onClick={() => {
            ReactGA.event({
              action: "ui_ux",
              category: "search_cards",
            });
            // scrollTo("waitlist");
          }}
        ></button>
      </div>
    </div>
  );
};
