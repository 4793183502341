import RootPage from "../root";
import { SearchCard } from "../Home/cards/searchCard";

const Explore = () => {
  return (
    <RootPage header="home">
      <div className="mb-20">
        <SearchCard />
      </div>
    </RootPage>
  );
};

export default Explore;
