import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";

interface RootPageProps {
  header: string;
  children: JSX.Element;
}

const RootPage = (props: RootPageProps) => {
  return (
    <div className="flex dark:bg-pf-navy flex-col justify-between min-h-screen">
      <div className="max-w-[1400px] w-full mx-auto">
        <Header active={props.header} />
        <div className="w-full">{props.children}</div>
      </div>
      <div className="bg-pf-purple w-full">
        <div className="max-w-[1400px] w-full mx-auto">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default RootPage;
