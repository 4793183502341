import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Authenticator from "../../../services/authenticator";
import { useUser } from "../../../contexts/UserContext";
import RootNoFooterPage from "../../rootNoFooter";
import PathFinderButton from "../../../components/common/PathFinderButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import DisplayStudents from "./components/DisplayStudents";
import DisplayStudentOverview from "./components/DisplayStudentOverview";
import StudentSummary from "./components/StudentSummary";
import CircularProgress from "@mui/material/CircularProgress";
import StudentLearningPages from "./components/StudentLearningPages";
import { Student } from "./components/types";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "@mui/material/Skeleton";

// Remove the StudentOverviewProps type definition

const StudentOverviewPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();

  // Initialize state outside of any conditional logic
  const [currSelectedStudent, setCurrSelectedStudent] =
    useState<Student | null>(null);
  const [students, setStudents] = useState<Array<Student>>([]);
  const [threadIds, setThreadIds] = useState<string[]>([]);
  const [threadId, setThreadId] = useState<string>("");
  const [parentId, setParentId] = useState<string>("");
  const [currTitleMap, setCurrTitleMap] = useState<
    Map<string, { title: string; bracketThread: string }>
  >(new Map());
  const [chatSummary, setChatSummary] = useState<string>("");
  const [overallChatSummary, setOverallChatSummary] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState<string>("");
  const [showAllStudents, setShowAllStudents] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchingStudents, setFetchingStudents] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const state = location.state as {
        studentName: string;
        studentEmail: string;
        studentsParent: Array<Student>;
        threadIds?: string[];
        tempTitleMap?: Map<string, { title: string; bracketThread: string }>;
        overallChatSummary?: string;
      };

      if (!state) {
        navigate(-1); // Go back if state is not available
        return;
      }

      const {
        studentName,
        studentEmail,
        studentsParent,
        threadIds,
        tempTitleMap,
        overallChatSummary,
      } = state;

      if (tempTitleMap && threadIds && overallChatSummary) {
        // New condition where additional state properties are provided
        setLoading(true);
        setCurrSelectedStudent({
          name: studentName,
          email: studentEmail,
          isApproved: true,
        });
        setStudents(studentsParent);
        setThreadIds(threadIds);
        setCurrTitleMap(tempTitleMap);
        setOverallChatSummary(overallChatSummary);
        setLoading(false);
      } else {
        // Existing logic for when only studentName, studentEmail, and studentsParent are provided
        setLoading(true);
        setCurrSelectedStudent({
          name: studentName,
          email: studentEmail,
          isApproved: true,
        });
        setStudents(studentsParent);
        try {
          const pageId = await Authenticator.getUserPageIdByEmail(studentEmail);
          if (pageId === null) {
            setCurrSelectedStudent({
              name: studentName,
              email: studentEmail,
              pageId: null,
              isApproved: true,
            });
            setLoading(false);
            return;
          }
          const response = await axios.get(
            `https://pathfinder-notion-web-content.vercel.app/api/${pageId}`
          );
          const threadIds = response.data.recordMap.block[pageId].value.content;
          const tempTitleMap = new Map();
          let bracketThreads = [];

          for (const threadId of threadIds) {
            const threadResponse = await axios.get(
              `https://pathfinder-notion-web-content.vercel.app/api/${threadId}`
            );
            let fullTitle =
              threadResponse.data.recordMap.block[threadId].value.properties
                .title[0][0];
            let title = fullTitle;
            let bracketThread = "";

            if (fullTitle.includes("(")) {
              const splitTitle = fullTitle.split("(");
              title = splitTitle[0].trim();
              bracketThread = splitTitle[1].split(")")[0].trim();
              bracketThreads.push(bracketThread);
            }
            tempTitleMap.set(threadId, { title, bracketThread });
          }

          setCurrSelectedStudent({
            name: studentName,
            email: studentEmail,
            pageId,
            isApproved: true,
          });
          setThreadIds(threadIds);
          setCurrTitleMap(tempTitleMap);
          setLoading(false);
        } catch (error) {
          console.error("Error loading student overview:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [location.state, navigate]);

  const fetchStudents = async () => {
    try {
      setFetchingStudents(true);
      const response = await axios.get(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getStudentsByTeacherEmail",
        {
          params: {
            email: user.email,
          },
        }
      );
      setStudents(response.data);
      setFetchingStudents(false);
    } catch (error) {
      console.error("Error fetching students for teacher:", error);
    }
  };

  const handleThreadSelection = async (
    threadId: string,
    bracketThread: string
  ) => {
    setLoading(true);
    setChatSummary("");
    setSelectedPage(threadId);
    setCurrSelectedStudent({
      ...currSelectedStudent,
      pageId: threadId,
    });
    setLoading(false);
    await fetchMessages(bracketThread);
  };

  const handleViewPages = async (studentSelected: Student) => {
    const pageId = await Authenticator.getUserPageIdByEmail(
      studentSelected.email
    );
    // console.log(`Viewing pages for student with page ID: ${pageId}`);
    // Find the student in the students state to get the isApproved status
    setCurrSelectedStudent(studentSelected);
    setLoading(true);
    setCurrTitleMap(new Map());
    if (!pageId) {
      setLoading(false);
      return;
    }

    let response: any = null;
    try {
      response = await axios.get(
        `https://pathfinder-notion-web-content.vercel.app/api/${pageId}`
      );
      const threadIds = response.data.recordMap.block[pageId].value.content;
      // console.log("THREAD IDs: " + threadIds);
      setThreadIds(threadIds);

      const tempTitleMap = new Map();
      let bracketThreads = [];

      // Fetch and store titles
      for (const threadId of threadIds) {
        const response = await axios.get(
          `https://pathfinder-notion-web-content.vercel.app/api/${threadId}`
        );
        let fullTitle =
          response.data.recordMap.block[threadId].value.properties.title[0][0];
        let title = fullTitle;
        let bracketThread = "";

        // Check if title includes brackets and extract the content
        if (fullTitle.includes("(")) {
          const splitTitle = fullTitle.split("(");
          title = splitTitle[0].trim();
          bracketThread = splitTitle[1].split(")")[0].trim();
          bracketThreads.push(bracketThread);
        }
        tempTitleMap.set(threadId, { title, bracketThread });
      }
      // console.log("TITLE MAP: ", Array.from(tempTitleMap));
      setCurrTitleMap(tempTitleMap);
      setLoading(true);

      // if (bracketThreads.length > 0) {
      //   await fetchAndSummarizeAllMessages(bracketThreads);
      // }

      navigate(`/teacher-dashboard/${studentSelected.name}`, {
        state: {
          studentName: studentSelected.name,
          studentEmail: studentSelected.email,
          studentsParent: students,
          threadIds: threadIds,
          tempTitleMap: tempTitleMap,
          overallChatSummary: overallChatSummary,
        },
      });
    } catch (error) {
      // console.log("ERORR: ", error);
      return;
    }
    setLoading(true);
  };

  // const fetchAndSummarizeAllMessages = async (threadIds: string[]) => {
  //   let allMessages = "";
  //   for (const threadId of threadIds) {
  //     try {
  //       const response = await axios.post(
  //         "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/fetchMessages",
  //         {
  //           threadId: threadId,
  //         }
  //       );
  //       const messages = response.data.newMessages;
  //       const combinedMessages = messages
  //         .map((msg) => msg.content[0].text.value)
  //         .join(" ");
  //       allMessages += combinedMessages + " ";
  //     } catch (error) {
  //       console.error("Error fetching messages for threadId:", threadId, error);
  //     }
  //   }

  //   if (allMessages) {
  //     try {
  //       const summaryResponse = await axios.post(
  //         "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getChatSummary",
  //         {
  //           chat: allMessages,
  //           name: currSelectedStudent.name,
  //         }
  //       );
  //       const overallSummary = summaryResponse.data.summary;
  //       // console.log('Overall Chat Summary: ', overallSummary);
  //       setOverallChatSummary(overallSummary);
  //     } catch (error) {
  //       console.error("Error generating overall chat summary:", error);
  //     }
  //   }
  // };

  const fetchMessages = async (threadId: string) => {
    try {
      const response = await axios.get(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/fetchMessages",
        {
          params: {
            threadId: threadId,
          },
        }
      );
      const messages = response.data.newMessages;
      // Extract the 'value' from each message and combine into a string
      const combinedMessages = messages
        .map((msg) => msg.content[0].text.value)
        .join(" ");
      // console.log("Combined Messages:", combinedMessages);
      try {
        const response = await axios.get(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getChatSummary",
          {
            params: {
              chat: combinedMessages,
              name: currSelectedStudent.name,
            },
          }
        );
        const summary = response.data.summary;
        // console.log("Chat Summary: ", summary);
        setChatSummary(summary);
      } catch (error) {
        console.error("Error generating chat summary:", error);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  return (
    <RootNoFooterPage header="Student Details">
      <div className="flex flex-col space-y-8 h-full w-full bg-white dark:bg-pf-navy">
        {currSelectedStudent ? (
          <div>
            <div className="bg-pf-purple dark:bg-pf-green mt-[-18px] py-10 px-6 flex justify-between items-center">
              <div className="flex gap-6">
                {/* <h4 className="text-white">YOUR SCHOOL NAME</h4> */}
                <PathFinderButton
                  outlined={true}
                  onClick={() => {
                    navigate("/teacher-dashboard", {
                      state: { students: students },
                    });
                  }}
                >
                  <div className="flex items-center justify-center gap-3">
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </div>
                </PathFinderButton>
                {currSelectedStudent.name ? (
                  <h2 className="text-white dark:text-pf-navy">
                    {currSelectedStudent.name}'s builds
                  </h2>
                ) : (
                  <Skeleton variant="text" width="100%" height={30} />
                )}
              </div>
            </div>
            <div className="flex  w-full flex-row min-h-screen">
              {students && showAllStudents && (
                <div
                  className={`bg-pf-grey dark:bg-pf-white dark:bg-opacity-10 min-w-[350px] w-[25vw] max-w-[400px] pt-[1.5rem] pl-[2rem] mr-6 mb-4 border-r-2 border-pf-purple dark:border-pf-green relative`}
                >
                  <div className="absolute top-[1rem] right-[1rem] m-2">
                    <PathFinderButton
                      outlined={true}
                      onClick={() => setShowAllStudents(!showAllStudents)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </PathFinderButton>
                  </div>
                  <div className="absolute top-[1rem] pt-[2px] left-[2rem] m-2">
                    <h3 className="font-bold text-black dark:text-white mb-4">
                      Your students:
                    </h3>
                    <DisplayStudents
                      teacherEmail=""
                      students={students}
                      loading={fetchingStudents}
                      fetchStudents={fetchStudents}
                      handleViewPages={handleViewPages}
                      vertical={true}
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-col w-full md:flex-row gap-1 md:gap-4 h-screen">
                <>
                  <div className={`flex flex-col h-screen md:w-1/2 py-1 px-4`}>
                    <div className="flex flex-col pt-4 flex-grow overflow-y-scroll">
                      <div className="flex space-x-2 mb-2">
                        {students && !showAllStudents && (
                          <PathFinderButton
                            outlined={false}
                            onClick={() => {
                              setShowAllStudents(!showAllStudents);
                            }}
                          >
                            <FontAwesomeIcon icon={faBars} />
                          </PathFinderButton>
                        )}
                      </div>
                      {/* <StudentSummary
                        title={`What ${currSelectedStudent.name} has been up to`}
                        summary={overallChatSummary || "Fetching summary..."}
                      /> */}
                      <h2 className="font-bold mb-4 text-black dark:text-white">
                        Builds
                      </h2>
                      {currTitleMap.size > 0 ? (
                        <div className="flex w-full justify-start">
                          <DisplayStudentOverview
                            titleMap={currTitleMap}
                            selectedPage={selectedPage}
                            handleThreadSelection={handleThreadSelection}
                          />
                        </div>
                      ) : loading ? (
                        <div className="flex w-full justify-start">
                          <div className="flex flex-col space-y-2 w-full">
                            <Skeleton
                              variant="rectangular"
                              width="40%"
                              height={40}
                              style={{ borderRadius: "4px" }}
                            />
                            <Skeleton
                              variant="rectangular"
                              width="70%"
                              height={40}
                              style={{ borderRadius: "4px" }}
                            />
                            <Skeleton
                              variant="rectangular"
                              width="90%"
                              height={40}
                              style={{ borderRadius: "4px" }}
                            />
                            <Skeleton
                              variant="rectangular"
                              width="60%"
                              height={40}
                              style={{ borderRadius: "4px" }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="flex w-full justify-center">
                          <div className="w-full bg-pf-red bg-opacity-10 dark:bg-opacity-80 p-4 rounded-md flex items-center justify-center">
                            <p className="text-pf-dark-grey dark:text-white text-lg">
                              {currSelectedStudent.name} has not made any
                              projects yet.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={`flex flex-col h-screen mr-4 md:w-1/2`}>
                    {loading || currTitleMap.size > 0 ? (
                      <StudentLearningPages
                        loadingPages={loading}
                        currSelectedStudent={currSelectedStudent}
                        selectedPage={selectedPage}
                        chatSummary={chatSummary}
                        setThreadId={setThreadId}
                        setParentId={setParentId}
                      />
                    ) : (
                      <div className="bg-pf-grey dark:bg-pf-dark-grey border border-pf-dark-grey dark:border-pf-grey w-full h-full overflow-y-scroll rounded-md my-4"></div>
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="h-full w-full"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
      </div>
    </RootNoFooterPage>
  );
};

export default StudentOverviewPage;
