import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export interface DashboardHeaderProps {
  pathName: string;
  progress: number;
}

const DashboardHeader = ({ pathName, progress }: DashboardHeaderProps) => {
  return (
    <header className="bg-pf-purple text-white w-full bottom-0 left-0 py-1 px-6 flex items-center justify-between">
      <div className="text-sm md:text-lg font-bold">
        Your current path: {pathName}
      </div>
      <div className="flex items-center">
        <div className="hidden md:block">Amazing work, keep going! </div>
        <div className="ml-5 w-14">
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            strokeWidth={10}
            styles={buildStyles({
              strokeLinecap: "round",
              pathColor: "#fff",
              textSize: "26px",
              trailColor: "rgba(255, 255, 255, 0.3)",
              textColor: "#fff",
            })}
          />
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
