import Authenticator from "../../services/authenticator";

import { Label, TextInput } from "flowbite-react";
import AccountsTemplate from "../../components/AccountsTemplate";
import { useForm, SubmitHandler } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextLink from "../../components/common/TextLink";
import PathFinderButton from "../../components/common/PathFinderButton";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import Header from "../../components/Header/header";
import { useUser } from "../../contexts/UserContext";
import amplitudeInstance, {
  logEvent,
  generateUniqueUserId,
} from "../../services/amplitudeService";
interface LogInUserDetails {
  email: string;
  password: string;
}

const LoginForm = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setUser } = useUser();
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password length should be at least 6 characters"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LogInUserDetails>({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<LogInUserDetails> = (
    data: LogInUserDetails
  ) => {
    resetErrorState();
    setLoading(true);
    loginToAmplify(data);
  };

  async function loginToAmplify(data: LogInUserDetails) {
    const response: any = await Authenticator.signIn(data.email, data.password);

    if (typeof response == "string") {
      setErrorMessage(response);
      setShowError(true);
    } else {
      localStorage.setItem("userEmail", data.email);
      localStorage.setItem("userRole", "Learner");
      localStorage.setItem("userName", "");
      navigate("/", { replace: true }); 
      fetchUserDetails(data.email); // Fetch user details in the background
    }
    setLoading(false);
  }

  async function fetchUserDetails(email: string) {
    const name = await Authenticator.getUserNameByEmail(email);
    const role = await Authenticator.getRoleByEmail(email);
    if (name && role) {
      setUser({ email: email, name: name, role: role });
      localStorage.setItem("userName", name);
      localStorage.setItem("userEmail", email);
      localStorage.setItem("userRole", role);
      const uniqueUserId = generateUniqueUserId(email);
      amplitudeInstance.setUserId(uniqueUserId.toString());
      logEvent("USER_LOGIN", { userId: uniqueUserId.toString() });
    } else {
    }
  }

  function resetErrorState() {
    setShowError(false);
    setErrorMessage("");
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="w-full px-16">
        <h2 className="text-4xl font-bold text-center">Welcome back 🚀</h2>

        <div className="flex mt-8 mb-6 justify-between items-center">
          <hr className="border-t flex-grow border-pf-dark-grey" />
          <hr className="border-t flex-grow border-pf-dark-grey" />
        </div>

        <div>
          <h4 className="text-center mb-8">Log in with your Email below</h4>
        </div>

        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
          <div>
            {showError && (
              <div
                className="bg-[#F4EBF4] border-pf-purple text-black px-4 py-3 rounded-lg"
                role="alert"
              >
                <p className="font-bold">Oh no, something went wrong :( </p>
                <p>{errorMessage}</p>
                <p>
                  You can create an account{" "}
                  <a href="/create-account" className="underline">
                    HERE
                  </a>
                  .
                </p>
              </div>
            )}

            <div className="mb-2 block">
              <Label htmlFor="email" value="Your email" />
            </div>
            <TextInput
              id="email"
              type="email"
              placeholder="Email"
              {...register("email")}
              className="border border-pf-navy rounded-lg"
            />
            {errors.email && (
              <div
                className="bg-[#F4EBF4] border-pf-purple text-black px-4 py-3 rounded-lg"
                role="alert"
              >
                <span className="block sm:inline">{errors.email.message}</span>
              </div>
            )}
          </div>
          <div className="mb-2 block">
            <Label htmlFor="password" value="Your password" />
            <div className="flex items-center">
              <TextInput
                id="password"
                type={passwordShown ? "text" : "password"}
                placeholder="Password"
                {...register("password")}
                className="w-full border border-pf-navy rounded-lg flex-shrink-0"
              />
              <span
                className="cursor-pointer relative right-10"
                onClick={() => setPasswordShown(!passwordShown)}
              >
                {passwordShown ? (
                  <VisibilityOffSharpIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <VisibilitySharpIcon className="h-5 w-5 text-gray-500" />
                )}
              </span>
            </div>
            {errors.password && (
              <div
                className="bg-[#F4EBF4] border-pf-purple text-black px-4 py-3 rounded-lg"
                role="alert"
              >
                <span className="block sm:inline">
                  {errors.password.message}
                </span>
              </div>
            )}
          </div>
          <div className="flex w-full items-end justify-end">
            <PathFinderButton
              color="pf-navy"
              type="submit"
              loading={loading}
              disabled={false}
            >
              Log In
            </PathFinderButton>
          </div>
          <div className="flex items-center gap-4">
            <TextLink text="Create an account" link="/create-account" />
            <TextLink text="Forgot password" link="/login/forgot-password" />
          </div>
        </form>
      </div>
    </div>
  );
};

const LoginPage: React.FC = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="w-full h-full mb-4 mx-auto">
        <div className="w-full px-6 mx-auto">
          <Header active="home" />
        </div>
        <div className="w-full h-full my-[-1rem] bg-pf-purple">
          <AccountsTemplate
            bg_img="bg-pf-navy bg-contain bg-cover bg-center bg-no-repeat
                      bg-[url('/src/assets/onboardingDiagram.png')]"
            title=""
            subtitle=""
            form_component={<LoginForm />}
          ></AccountsTemplate>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
