import React from "react";
import ReactMarkdown from "react-markdown";

interface MarkdownViewerProps {
  content: string;
  textClassName?: string;
}

const MarkdownViewer: React.FC<MarkdownViewerProps> = ({
  content,
  textClassName = "",
}) => {
  return (
    <div className="flex flex-col">
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => (
            <h1 className={`${textClassName} text-5xl font-bold`} {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 className={`${textClassName} text-4xl font-bold`} {...props} />
          ),
          h3: ({ node, ...props }) => (
            <h3 className={`${textClassName} text-3xl font-bold`} {...props} />
          ),
          h4: ({ node, ...props }) => (
            <h4 className={`${textClassName} text-3xl font-bold`} {...props} />
          ),
          p: ({ node, ...props }) => (
            <h3 className={`${textClassName} text-[1.35rem]`} {...props} />
          ),
          a: ({ node, ...props }) => (
            <a
              className={`${textClassName} text-lg text-blue-500`}
              {...props}
            />
          ),
          img: ({ node, ...props }) => (
            <img
              className={`${textClassName} text-xl rounded-full`}
              {...props}
            />
          ),
          ul: ({ node, ...props }) => (
            <ul
              className={`${textClassName} text-lg font-normal list-disc`}
              {...props}
            />
          ),
          ol: ({ node, ...props }) => (
            <ol
              className={`${textClassName} text-lg font-normal list-decimal`}
              {...props}
            />
          ),
          li: ({ node, ...props }) => (
            <li
              className={`${textClassName} text-lg font-normal ml-5`}
              {...props}
            />
          ),
          blockquote: ({ node, ...props }) => (
            <blockquote
              className={`${textClassName} text-xl pl-4 italic border-l-4`}
              {...props}
            />
          ),
          pre: ({ node, ...props }) => (
            <pre
              className={`${textClassName} text-xl p-2 rounded bg-gray-800 text-white`}
              {...props}
            />
          ),
          code: ({ node, ...props }) => (
            <code className={`${textClassName} text-xl font-mono`} {...props} />
          ),
          em: ({ node, ...props }) => (
            <em className={`${textClassName} text-xl italic`} {...props} />
          ),
          strong: ({ node, ...props }) => (
            <strong
              className={`${textClassName} text-xl font-bold`}
              {...props}
            />
          ),
          del: ({ node, ...props }) => (
            <del
              className={`${textClassName} text-xl line-through`}
              {...props}
            />
          ),
          table: ({ node, ...props }) => (
            <table
              className={`${textClassName} text-xl table-auto`}
              {...props}
            />
          ),
          thead: ({ node, ...props }) => (
            <thead
              className={`${textClassName} text-xl bg-gray-200`}
              {...props}
            />
          ),
          tbody: ({ node, ...props }) => (
            <tbody className={textClassName} {...props} />
          ),
          tr: ({ node, ...props }) => (
            <tr className={textClassName} {...props} />
          ),
          td: ({ node, ...props }) => (
            <td
              className={`${textClassName} text-xl border px-4 py-2`}
              {...props}
            />
          ),
          th: ({ node, ...props }) => (
            <th
              className={`${textClassName} text-xl border px-4 py-2`}
              {...props}
            />
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownViewer;
