import React, { useEffect, useState } from "react";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import ExplorePathsCard from "../ExplorePathsCard";
import Authenticator from "../../../services/authenticator";
import CurrentPathCard from "../CurrentPathCard";
import { pathUrls } from "../../../routes";
import { allPathCardsData } from "../../common/Carousel/carousel"; // Replace with the correct path
import path from "path";

interface DashboardPathInfo {
  currentPageId: string;
  progress: number;
  Path: {
    id: string;
    title: string;
    numOfPages: number;
    numOfProjects: number;
  };
}
const AllUserPathCards = ({
  portfolioId,
  isPortfolio,
}: {
  portfolioId?: number;
  isPortfolio: boolean;
}) => {
  const [myPaths, setMyPaths] = useState<DashboardPathInfo[]>([]);
  const [pathsLoading, setPathsLoading] = useState<boolean>(true);

  const getDashboardPathInfo = async () => {
    const userId = portfolioId
      ? portfolioId
      : await Authenticator.getCurrentUserId();
    const responsePath = await axios.post(
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getUserPaths",
      {
        userId: userId,
      }
    );
    if (responsePath) {
      setMyPaths(responsePath.data);
    }
    setPathsLoading(false);
  };

  const getBackgroundImageForPath = (pathTitle: string) => {
    const pathData = allPathCardsData.find(
      (path) =>
        path.title.trim().toLowerCase() === pathTitle.trim().toLowerCase()
    );

    return pathData?.bgImage || ""; // If the path is not found in the data, return an empty string
  };

  useEffect(() => {
    getDashboardPathInfo();
  }, []);

  return pathsLoading ? (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
      {[...Array(4)].map((_, index) => (
        <Skeleton key={index} variant="rounded" height={150} animation="wave" />
      ))}
    </div>
  ) : (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
      {myPaths.map((path: DashboardPathInfo) => (
        <CurrentPathCard
          key={path.currentPageId} // Use a unique id if available
          bgImage={getBackgroundImageForPath(path.Path.title)}
          url={`${pathUrls[path.Path.title]}/${path.currentPageId}`}
          title={path.Path.title}
          progress={path.progress}
          removeCardCallback={() => {
            setMyPaths(
              myPaths.filter((p) => p.currentPageId !== path.currentPageId)
            );
          }}
          isPortfolio={isPortfolio}
        />
      ))}
      {isPortfolio && <ExplorePathsCard />}
    </div>
  );
};

export default AllUserPathCards;
