import React from "react";
import PathFinderButton from "../../../../components/common/PathFinderButton";
import { Tooltip } from "flowbite-react";

interface DisplayStudentOverviewProps {
  titleMap: Map<string, { title: string; bracketThread: string }>;
  selectedPage: string;
  handleThreadSelection: (threadId: string, bracketThread: string) => void;
}

const DisplayStudentOverview: React.FC<DisplayStudentOverviewProps> = ({
  titleMap,
  selectedPage,
  handleThreadSelection,
}) => {
  return (
    <div className="flex flex-col space-y-2 w-full">
      {Array.from(titleMap).map(([threadId, { title, bracketThread }]) => (
        <div className="flex w-full">
          {" "}
          {/* Ensure this div takes up full width */}
          <Tooltip content="Click to select">
            <div className="flex w-[100%]">
              <PathFinderButton
                key={threadId}
                outlined={threadId !== selectedPage}
                onClick={() => handleThreadSelection(threadId, bracketThread)}
              >
                {title}
              </PathFinderButton>
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default DisplayStudentOverview;
