import { forwardRef, useState, useEffect, RefObject } from "react";
import WeekCard from "../../../components/common/WeekCard";
import axios from "axios";
import jsPDF from "jspdf";
import PathFinderButton from "../../../components/common/PathFinderButton";
import SkeletonWeekCard from "../../../components/common/SkeletonWeekCard";
import { Topics, Links } from "../index";

interface EditPathCardProps {
  topics: Topics;
  linksMap: Links;
  numOfWeeks: number;
}

const EditPathCard = forwardRef<HTMLDivElement, EditPathCardProps>(
  ({ topics, linksMap, numOfWeeks }, ref) => {
    const [weekTopics, setWeekTopics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentLinksMap, setCurrentLinksMap] = useState(null);

    useEffect(() => {
      if (linksMap && Object.keys(linksMap).length > 0) {
        setCurrentLinksMap(linksMap);
        setLoading(false);
      }
    }, [linksMap]);

    useEffect(() => {
      // Organize topics into weeks
      const weeks = Object.keys(topics).reduce((acc, topic, index) => {
        const weekIndex = Math.floor(index / 4); // Assuming 4 topics per week
        if (!acc[weekIndex]) {
          acc[weekIndex] = [];
        }
        acc[weekIndex].push({ topic, subtopics: topics[topic] });
        return acc;
      }, []);

      setWeekTopics(weeks);
    }, [topics]);

    const handleSavePath = () => {
      const doc = new jsPDF();

      doc.setFontSize(13);
      doc.setFont("helvetica", "bold");
      doc.text("Your PathFinder Curriculum", 10, 15); // Position the title
      let yPos = 30; // Initialize y position for text after the title

      for (const week of weekTopics) {
        let weekContent = `Week ${weekTopics.indexOf(week) + 1}:\n`;
        let j = 1;
        for (const topicObj of week) {
          let content =
            weekContent + `Topic ${j}: ${topicObj.topic}\nSubtopics:\n`;
          j += 1;
          weekContent = ""; // Reset weekContent after using it once
          let i = 1;
          for (const subtopic of topicObj.subtopics) {
            content += `  ${i}) ${subtopic}: \n Link: ${currentLinksMap[subtopic]}\n`;
            i += 1;
          }

          // Check if adding text exceeds page length
          if (
            yPos + content.split("\n").length * 10 >
            doc.internal.pageSize.height
          ) {
            doc.addPage();
            yPos = 20; // Reset y position for new page
          }

          doc.setFont("helvetica", "bold");
          doc.text(content.split("\n")[0], 10, yPos); // Print the week and topic in bold
          doc.setFont("helvetica", "normal");
          doc.text(content.split("\n").slice(1).join("\n"), 10, yPos + 7); // Print the rest in normal style

          yPos += content.split("\n").length * 6; // Increment y position for next text
        }
      }

      doc.save("pathfinder_curriculum.pdf");
    };

    const handleRegenerate = async (title: string) => {
      console.log("handleRegenerate called with title:", title);
      try {
        const response = await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getRegeneratedLink",
          { topic: title }
        );
        const newLink = response.data;
        console.log("New link:", newLink);

        const isYouTubeLink = newLink.includes("youtube.com");

        setCurrentLinksMap((prevLinksMap) => ({
          ...prevLinksMap,
          [title]: isYouTubeLink ? newLink : prevLinksMap[title],
        }));
      } catch (error) {
        console.error("Error in handleRegenerate:", error);
      }
    };

    const handleTopicChange = (
      weekIndex: number,
      newTopics: Array<{ topic: string; subtopics: string[] }>
    ) => {
      setWeekTopics((prevWeekTopics) => {
        const newWeekTopics = [...prevWeekTopics];
        newWeekTopics[weekIndex] = newTopics;
        return newWeekTopics;
      });
    };

    return (
      <div ref={ref as RefObject<HTMLDivElement>}>
        <div className="grid grid-cols-3 mt-8">
          <div className="mt-4 col-span-1">
            <h1 className="text-4xl font-bold">Edit your path</h1>
          </div>
          <div className="flex flex-col col-span-2 ml-12">
            {loading ? (
              <>
                {Array.from({ length: numOfWeeks }).map((_, index) => (
                  <SkeletonWeekCard key={index} />
                ))}
              </>
            ) : (
              <>
                {currentLinksMap &&
                  weekTopics.map((week, index) => {
                    return (
                      <WeekCard
                        key={index}
                        line={index !== weekTopics.length - 1}
                        number={String(index + 1)}
                        topics={week}
                        handleRegenerate={handleRegenerate}
                        linksMap={currentLinksMap}
                        handleTopicChange={(newTopics) =>
                          handleTopicChange(index, newTopics)
                        }
                        setCurrentLinksMap={setCurrentLinksMap}
                      />
                    );
                  })}
                <div className="mt-4 w-full flex justify-end">
                  <PathFinderButton onClick={handleSavePath}>
                    Save Path
                  </PathFinderButton>
                </div>
              </>
            )}
          </div>
        </div>
        <div style={{ borderTop: "1px solid #D1D5DB" }} className="mt-8"></div>
      </div>
    );
  }
);

export default EditPathCard;
