import { styled } from "@mui/material/styles";

export const TryItOutNowContainer = styled("div")`
  width: 100%;
  padding-left: 85%;
  margin-top: -1.25rem;

  @media only screen and (max-width: 1200px) {
    padding-left: 85%;
  }

  @media only screen and (max-width: 1000px) {
    padding-left: 80%;
  }

  @media only screen and (max-width: 800px) {
    padding-left: 70%;
  }

  @media only screen and (max-width: 500px) {
    padding-left: 60%;
  }

  @media only screen and (max-width: 400px) {
    padding-left: 50%;
  }
`;

export const LogoContainer = styled("div")`
  width: 100%;
  margin-top: -2rem;
  margin-left: -1rem;
`;

export const TitleContainer = styled("div")`
  width: 100%;
  margin-top: -0.75rem;
  margin-left: 0rem;

  @media only screen and (max-width: 650px) {
    margin-top: -1.5rem;
  }
`;

export const StarsContainer = styled("div")`
  width: 100%;
  margin-top: -0.75rem;
  text-align: right;
`;
