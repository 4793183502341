import { Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import Middlewares from "./middlewares/auth";
import ErrorPage from "./pages/error";
import AppRoutes from "./routes";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import ReactGA from "react-ga4";
import { Amplify } from "aws-amplify";
import { UserProvider } from './contexts/UserContext';
import awsconfig from "./aws-exports";
import amplitude from "amplitude-js";
Amplify.configure(awsconfig);

const TRACKING_ID = "G-1VCJW9KZX5";
const amplitudeInstance = amplitude.getInstance();
amplitudeInstance.init("221fb43727cc773e832384e9d4b952c2");

function App() {
  const handleAcceptCookie = () => {
    ReactGA.initialize(TRACKING_ID);
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <UserProvider>
        <Middlewares>
          <BrowserRouter>
            <Suspense fallback="loading ...">
              <AppRoutes />
              {/* <CookieConsent
                buttonText="Accept"
                style={{
                  background: "#092056",
                }}
                buttonStyle={{
                  fontWeight: "bold",
                  backgroundColor: "#77f0a1",
                  color: "#000",
                  borderRadius: 1000,
                }}
                // declineButtonStyle={{
                //   fontWeight: "bold",
                //   backgroundColor: "#e3675d",
                //   color: "#000",
                //   borderRadius: 1000,
                // }}
                // declineButtonText="Decline"
                // enableDeclineButton
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
              >
                PathFinder uses cookies to enhance your user experience. Please
                accept to help us on our mission!
              </CookieConsent> */}
            </Suspense>
          </BrowserRouter>
        </Middlewares>
      </UserProvider>
    </ErrorBoundary>
  );
}

export default App;