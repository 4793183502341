import React from "react";
import { useNavigate } from "react-router-dom";
import Add from "../../../assets/add.png";

const ExplorePathsButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the paths exploration page
    navigate("/explore");
  };

  return (
    <div className="relative h-60">
      <div className="relative max-w-sm">
        <button
          className="w-full h-full flex items-center justify-center font-black rounded-3xl bg-white shadow-lg hover:shadow-xl"
          onClick={handleClick}
          style={{
            borderColor: "#6B7280",
            borderWidth: "4px",
            borderStyle: "dashed",
          }}
        >
          <div className="flex flex-col items-center my-10">
            <img src={Add} alt="Add" style={{ marginBottom: "8px" }} />
            <h5 className="text-2xl mb-6" style={{ color: "#6B7280" }}>
              Explore paths
            </h5>
          </div>
        </button>
      </div>
    </div>
  );
};

export default ExplorePathsButton;
