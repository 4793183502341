import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Authenticator from "../../../services/authenticator";
import closeWindow from "../../../assets/close.png";
import { Progress } from "flowbite-react";
import axios from "axios";
import { PathNameToIds } from "../Carousel/carouselCard";
import DeleteModal from "../DeleteModal";
interface CurrentPathCardProps {
  bgImage: string;
  url: string;
  title: string;
  progress: number;
  removeCardCallback: any;
  isPortfolio: boolean;
}

export const CurrentPathCard = ({
  bgImage,
  url,
  title,
  progress,
  removeCardCallback,
  isPortfolio,
}: CurrentPathCardProps) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleClick = async () => {
    navigate(url);
  };

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setDeleting(true);
    const userId = await Authenticator.getCurrentUserId();
    const pathName = url.split("/")[1];
    const pathId = PathNameToIds[pathName];
    const response = await axios.post(
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/removeUserFromPath",
      {
        userId: userId,
        pathId: pathId,
      }
    );
    removeCardCallback();

    setDeleting(false);
    setShowModal(false);
  };

  return (
    <div className="relative">
      <div className="relative h-52">
        <button
          className={`w-full h-full text-white font-black rounded-3xl bg-center bg-cover ${bgImage} shadow-lg hover:shadow-xl`}
          onClick={handleClick}
        >
          <div className="p-4">
            <p className="text-white text-3xl pt-5 leading-tight font-bold">
              {title}
            </p>
          </div>
          <div className="mt-1 mb-4 px-6">
            <Progress progress={progress} size="sm" color="dark" />
          </div>
          {!isPortfolio && (
            <button
              className="absolute top-4 right-4 rounded-full p-1"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
            >
              <img src={closeWindow} alt="Close" />
            </button>
          )}
        </button>
        {!isPortfolio && (
          <DeleteModal
            showModal={showModal}
            handleConfirmDelete={handleConfirmDelete}
            setShowModal={setShowModal}
            loading={deleting}
            text="Are you sure you want to delete this project?"
          ></DeleteModal>
        )}
      </div>
    </div>
  );
};

export default CurrentPathCard;
