import RootPage from "../root";
import { useRef, useState } from "react";
import axios from "axios";
import StepsCard from "./cards/stepsCard";
import AboutActivityCard from "./cards/aboutActivityCard";
import EditActivitiesCard from "./cards/editActivitiesCard";

export interface Activity {
  title: string;
  description: string;
  implementation: string[];
}

const GenerateActivitiesPage = () => {
  const aboutPathRef = useRef(null);
  const editPathRef = useRef(null);
  const accessShareRef = useRef(null);

  const [activities, setActivities] = useState<Activity[]>(null);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);

  const [threadId, setThreadId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [description, setDescription] = useState("");
  const [students, setStudents] = useState("");
  const [classroomUpdate, setClassroomUpdate] = useState("");
  const [extraInfo, setExtraInfo] = useState("");
  const [oldMessageCount, setOldMessageCount] = useState(0);

  const assistantId = "asst_Z4MxvKizSCEieIazbpH8Tlb9";
  const apiKey = "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";

  const createThread = async () => {
    console.log("Processing createThread...");

    const response = await axios.post(
      `https://api.openai.com/v1/threads`,
      {},
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1",
        },
      }
    );

    // console.log(response.data.id);
    setThreadId(response.data.id);
    return response.data.id; // return the threadId
  };

  const createMessage = async (
    threadId: string, // Add threadId as a parameter
    role: string,
    content: string,
    file_ids: string[] = [],
    metadata: any = {}
  ) => {
    // console.log("Processing createMessage...", threadId);

    const response = await axios.post(
      `https://api.openai.com/v1/threads/${threadId}/messages`,
      {
        role,
        content,
        file_ids,
        metadata,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1",
        },
      }
    );

    setIsSubmitting(true);

    return response.data;
  };

  const awaitAssistantActions = async (threadId: string) => {
    // console.log("Processing getAssistantResponse...");

    // Run the thread
    const runResponse = await axios.post(
      `https://api.openai.com/v1/threads/${threadId}/runs`,
      {
        assistant_id: assistantId,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1",
        },
      }
    );

    console.log("Run connected...");

    let runStatus = runResponse.data.status;
    let runStepStatus = null;
    let runId = runResponse.data.id;

    // Constantly check the status of the run and its steps until it has the status "complete"
    while (runStatus !== "completed") {
      const runStatusResponse = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v1",
          },
        }
      );

      runStatus = runStatusResponse.data.status;
      console.log("Checking run status...");
      // If run status is not complete, check the run step status
      if (runStatus !== "completed") {
        while (runStepStatus !== "completed") {
          console.log("Checking runStepStatus", runStepStatus);

          const runStepStatusResponse = await axios.get(
            `https://api.openai.com/v1/threads/${threadId}/runs/${runId}/steps`,
            {
              headers: {
                Authorization: `Bearer ${apiKey}`,
                "Content-Type": "application/json",
                "OpenAI-Beta": "assistants=v1",
              },
            }
          );

          if (
            runStepStatusResponse.data.data[
              runStepStatusResponse.data.data.length - 1
            ]
          ) {
            runStepStatus = runStepStatusResponse.data.data[0].status;
          }

          // Wait for a second before checking the status again to avoid hitting rate limits
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      }
      console.log("Run status:", runStatus);
      // Wait for a second before checking the status again to avoid hitting rate limits
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    setIsSubmitting(false);
  };

  const getMessages = async (threadId: string) => {
    // console.log("Processing getMessages...");
    const messagesResponse = await axios.get(
      `https://api.openai.com/v1/threads/${threadId}/messages`,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1",
        },
      }
    );

    return messagesResponse.data;
  };

  const handleWrongNumberOfActivities = async (
    num: number,
    currThread: string
  ) => {
    console.log("Only got", num, "activities... regenerating");
    const message = `You only gave me ${num} activities. I want 5! Retry my previous request: I want to design activity with the following description: ${description}. It will be for my classroom which looks like the following: ${students}. The class has been doing the following: ${classroomUpdate}. Any extra information I might have for you is here: ${extraInfo}. Provide me with 5 activities I could do as options.`;
    console.log("Creating the message:", message);
    await createMessage(currThread, "user", message);
    console.log("Awaiting assistant...");
    await awaitAssistantActions(currThread);
    const data = await getMessages(currThread);

    return data;
  };

  const handleGenerateActivities = async (
    description,
    students,
    classroomUpdate,
    extraInfo
  ) => {
    setStep(2);
    setDescription(description);
    setStudents(students);
    setClassroomUpdate(classroomUpdate);
    setExtraInfo(extraInfo);

    let currentThreadId = threadId;
    if (!currentThreadId) {
      currentThreadId = await createThread();
      setThreadId(currentThreadId);
    }

    const message = `I want to design activity with the following description: ${description}. It will be for my classroom which looks like the following: ${students}. The class has been doing the following: ${classroomUpdate}. Any extra information I might have for you is here: ${extraInfo}. Provide me with 5 activities I could do as options.`;
    console.log("Creating the message:", message);
    await createMessage(currentThreadId, "user", message);
    console.log("Awaiting assistant...");
    await awaitAssistantActions(currentThreadId);
    const data = await getMessages(currentThreadId);
    const activities = await formattingActivities(
      data.data,
      5,
      currentThreadId
    );

    setOldMessageCount(data.data.length);

    console.log("Activities are: ", activities);
    if (activities.length > 5) {
      setActivities(activities.slice(0, 5));
    } else {
      setActivities(activities);
    }
  };

  const handleIterateAllActivities = async (changesRequested) => {
    const message = `I don't like the activities you've generated. Here is more information on why and what I want: ${changesRequested}. Provide me with ONLY 5 new activities I could do as options.`;
    console.log("Creating the message:", message);
    await createMessage(threadId, "user", message);
    console.log("Awaiting assistant...");
    await awaitAssistantActions(threadId);
    const data = await getMessages(threadId);
    setOldMessageCount(data.data.length);

    console.log("New activities generating...");
    const newData = data.data.slice(0, data.data.length - oldMessageCount);

    const newActivities = await formattingActivities(newData, 5);
    setActivities(newActivities);
    console.log("Updated activities:", newActivities);
  };

  const handleIterateSingleActivity = async (
    iterateActivityIndex,
    changesRequested
  ) => {
    const iterateActivity = activities[iterateActivityIndex];
    const message = `I want to iterate on the activity titled "${iterateActivity.title}" with the following description: ${iterateActivity.description}. It will be for my classroom which looks like the following: ${students}. The class has been doing the following: ${classroomUpdate}. Any extra information I might have for you is here: ${extraInfo}. Provide me with a new variation of this activity but with the following changes: ${changesRequested}. I ONLY want 1 activity from you that iterates on the previous one I've just provided but with the changes I've requested.`;
    console.log("Creating the message:", message);
    await createMessage(threadId, "user", message);
    console.log("Awaiting assistant...");
    await awaitAssistantActions(threadId);
    const data = await getMessages(threadId);
    setOldMessageCount(data.data.length);

    console.log("Iterating on single activit...");
    const newData = data.data.slice(0, data.data.length - oldMessageCount);

    const newActivity = await formattingActivities(newData, 1);
    // Replace the previous activity with the new activity but in the same order on the activities object
    const updatedActivities = [...activities];
    updatedActivities[iterateActivityIndex] = newActivity[0];
    setActivities(updatedActivities);
    console.log("Updated activities:", updatedActivities);
    return newActivity;
  };

  const formattingActivities = async (
    data: any,
    expected: number,
    currThreadId?: string
  ) => {
    console.log("Formatting activities:", data);
    let activities: Activity[] = []; // Initialize an empty array
    const currThread = currThreadId ? currThreadId : threadId;

    // Filter the messages to only keep ones where the role is "assistant"
    let assistantMessages = [];
    if (Array.isArray(data)) {
      assistantMessages = data.filter(
        (message) => message.role === "assistant"
      );
    } else {
      console.log("Data is not an Array:", data);
      assistantMessages = data.data.filter(
        (message) => message.role === "assistant"
      );
    }
    console.log("Assistant messages:", assistantMessages);

    // Regular expression to match JSON objects
    const regex = /{[^}]*}/g;

    for (let n = 0; n < assistantMessages.length; n++) {
      // Extract JSON objects from the text
      const matches = assistantMessages[n].content[0].text.value.match(regex);

      if (matches) {
        matches.forEach((match: string) => {
          // Parse each JSON object and add it to the activities array
          const activityData = JSON.parse(match);
          activities.push({
            title: activityData.title,
            description: activityData.description,
            implementation: activityData.implementation,
          });
        });
      }
    }

    console.log("Activities formatted:", activities);

    if (activities.length < expected) {
      const data2 = await handleWrongNumberOfActivities(
        activities.length,
        currThread
      );
      return await formattingActivities(data2, expected, currThread);
    }

    return activities;
  };

  return (
    <RootPage header="home">
      <div className="container mx-auto my-10">
        <StepsCard
          aboutPathRef={aboutPathRef}
          editPathRef={editPathRef}
          accessShareRef={accessShareRef}
          step={step}
        />
        <AboutActivityCard
          ref={aboutPathRef}
          onGenerateActivities={handleGenerateActivities}
        />
        {step > 1 ? (
          <EditActivitiesCard
            activities={activities}
            handleIterateAllActivities={handleIterateAllActivities}
            handleIterateSingleActivity={handleIterateSingleActivity}
          />
        ) : (
          <p></p>
        )}
      </div>
    </RootPage>
  );
};

export default GenerateActivitiesPage;
