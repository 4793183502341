import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import StudentSummary from "./StudentSummary";
import OverviewPage from "../../components/OverviewPage";
import { Student } from "./types";
import Skeleton from "@mui/material/Skeleton";

type StudentChatSummaryProps = {
  loadingPages: boolean;
  chatSummary: string | null;
  currSelectedStudent: Student | null;
  selectedPage: string;
  setThreadId: (threadId: string) => void;
  setParentId: React.Dispatch<React.SetStateAction<string>>;
};

const StudentChatSummary: React.FC<StudentChatSummaryProps> = ({
  loadingPages,
  chatSummary,
  currSelectedStudent,
  selectedPage,
  setThreadId,
  setParentId,
}) => {
  return (
    <div className="bg-pf-grey dark:bg-pf-dark-grey border border-pf-dark-grey dark:border-pf-grey w-full h-full overflow-y-scroll rounded-md my-4">
      {loadingPages ? (
        <div className="flex h-full w-full justify-center items-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          {selectedPage ? (
            <div className="flex flex-col px-6 py-2">
              {chatSummary ? (
                <>
                  <StudentSummary title="Overview" summary={chatSummary} />
                  <hr className="border-t border-pf-dark-grey dark:border-pf-grey mt-2 mb-4"></hr>
                </>
              ) : (
                <div className="flex flex-col h-full mb-4">
                  <h2 className="font-bold my-2">
                    Generating project summary...
                  </h2>
                  <div className="flex flex-col space-y-2">
                    <Skeleton
                      variant="rectangular"
                      width="90%"
                      height={20}
                      style={{ borderRadius: "4px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={20}
                      style={{ borderRadius: "4px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="95%"
                      height={20}
                      style={{ borderRadius: "4px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="40%"
                      height={20}
                      style={{ borderRadius: "4px" }}
                    />
                  </div>
                  <hr className="border-t border-pf-dark-grey dark:border-pf-grey mt-8 mb-2"></hr>
                </div>
              )}
              <OverviewPage
                teacherOverride={true}
                key={currSelectedStudent.pageId}
                parentPageId={selectedPage}
                setThreadId={setThreadId}
                setParentId={setParentId}
              />
            </div>
          ) : (
            <div className="flex w-full h-full px-[10%] text-center font-bold justify-center items-center">
              <h4>
                Select a build on the right to view {currSelectedStudent.name}'s
                learning pages
              </h4>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StudentChatSummary;
