import React from "react";
import { Draggable } from "react-beautiful-dnd";
import DraggableCard from "../../../components/common/DraggableCard";
import { Links } from "../index";

interface TopicCardProps {
  topic: string;
  subtopics: string[];
  handleRegenerate: (title: string) => Promise<void>;
  linksMap: Links;
  index: number;
  setCurrentLinksMap: React.Dispatch<React.SetStateAction<Links>>;
  currentLinksMap: Links;
}

const TopicCard: React.FC<TopicCardProps> = ({
  topic,
  subtopics,
  handleRegenerate,
  linksMap,
  index,
  setCurrentLinksMap,
  currentLinksMap
}) => {
  return (
    <Draggable draggableId={topic} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <DraggableCard
            id={topic}
            title={topic}
            link="#" // Placeholder link for the topic
            handleRegenerate={handleRegenerate}
            index={0}
            isYouTubeLink={false}
            isSubtopic={false}
            setCurrentLinksMap={setCurrentLinksMap}
            currentLinksMap={linksMap}
          >
            {subtopics.map((subtopic, index) => {
              return (
                <DraggableCard
                  key={subtopic}
                  id={subtopic}
                  title={subtopic}
                  link={linksMap[subtopic]}
                  handleRegenerate={handleRegenerate}
                  index={index + 1}
                  isYouTubeLink={
                    linksMap[subtopic]
                      ? linksMap[subtopic].includes("youtube.com")
                      : false
                  } // Check if the subtopic link is a YouTube link
                  isSubtopic={true}
                  setCurrentLinksMap={setCurrentLinksMap}
                  className="bg-pf-purple bg-opacity-10"
                  currentLinksMap={linksMap}
                />
              );
            })}
          </DraggableCard>
        </div>
      )}
    </Draggable>
  );
};

export default TopicCard;
