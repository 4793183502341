import React, { useEffect, useState } from "react";
import axios from "axios";
import { ExtendedRecordMap } from "notion-types";
import { getPageTitle } from "notion-utils";
import NotionPage from "./notionPage";
import { CircularProgress } from "@mui/material";

interface OverviewPageProps {
  teacherOverride?: boolean;
  url?: string;
  parentPageId?: string;
  setThreadId?: (threadId: string) => void;
  setParentId?: React.Dispatch<React.SetStateAction<string>>;
  setShowOverview?: React.Dispatch<React.SetStateAction<boolean>>;
}

const OverviewPage = ({
  teacherOverride,
  url,
  parentPageId,
  setThreadId,
  setParentId,
  setShowOverview,
}: OverviewPageProps) => {
  const [recordMap, setRecordMap] = useState<ExtendedRecordMap | null>(null);
  const pageId = parentPageId;

  const fetchNotionPage = async () => {
    // console.log("URL: " + url);
    // console.log("pageID: " + pageId);
    let response: any = null;
    try {
      // console.log("IM SEARCHING FOR A NOTION PAGE IN OVERVIEW:", pageId);
      response = await axios.get(
        `https://pathfinder-notion-web-content.vercel.app/api/${pageId}`
      );
    } catch (error) {
      //   navigate("/error");
      return;
    }

    if (response.data.error) {
      console.error("Error:", response.data.error.message);
      //   navigate("/error");
    } else {
      // Filter out duplicate pages based on their titles
      const uniquePages = {};
      const blocks = response.data.recordMap.block;
      Object.keys(blocks).forEach((key) => {
        const block = blocks[key];
        if (block.value.type === "page") {
          const title = getPageTitle({
            block: { [key]: block },
            collection: {},
            collection_view: {},
            notion_user: {},
            collection_query: {},
            signed_urls: {},
          });
          if (!uniquePages[title]) {
            uniquePages[title] = block;
          }
        }
      });

      // Create a new recordMap with unique pages only
      const filteredRecordMap: ExtendedRecordMap = {
        ...response.data.recordMap,
        block: Object.values(uniquePages).reduce((result, block) => {
          result[(block as any).value.id] = block;
          return result;
        }, {}),
      };

      setRecordMap(filteredRecordMap);
    }
  };

  useEffect(() => {
    fetchNotionPage();
  }, [pageId]);

  if (!recordMap) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <CircularProgress color="secondary" className="w-full h-full" />
      </div>
    );
  }

  return (
    <div
      className={`mr-1 h-full ${teacherOverride ? "" : "overflow-y-scroll"}`}
    >
      <NotionPage
        teacherOverride={teacherOverride}
        recordMap={recordMap}
        pageId={pageId}
        setThreadId={setThreadId}
        setParentId={setParentId}
        pageTitle={getPageTitle(recordMap)}
        setShowOverview={setShowOverview}
      />
    </div>
  );
};

export default OverviewPage;
