import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import AssistantComponent from "./AssistantComponent";
import Header from "../../components/Header/header";
import ModalRoot from "../../components/ModalRoot";
import Cookie from "js-cookie";
import { CircularProgress } from "@mui/material";

const PBLCopilot: React.FC = () => {
  const [threadId, setThreadId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false); // New state variable for loading status

  const startNewChat = useCallback(() => {
    Cookie.remove("threadId");
    setThreadId(null);
    Cookie.remove("title");
    Cookie.remove("description");
    Cookie.remove("resource");
    Cookie.remove("objectives");
    Cookie.remove("extraResources");
    // Add any other logic that is needed to start a new chat
  }, []);

  const handleThreadIdChange = (threadId: string | null) => {
    if (threadId === null) {
      // console.log("Removing threadId from cookie", threadId);
      Cookie.remove("threadId");
    } else {
      // console.log("Setting threadId in cookie", threadId);
      Cookie.set("threadId", threadId);
    }
    setThreadId(threadId);
  };

  useEffect(() => {
    if (!threadId) {
      setIsLoading(true);
      const cachedThreadId = Cookie.get("threadId");
      // console.log("Got thread from cookies:", cachedThreadId);
      setIsLoading(false);
      if (cachedThreadId) {
        setThreadId(cachedThreadId);
      } else {
        createThread();
      }
    }
  }, [threadId]);

  const createThread = useCallback(async () => {
    setIsLoading(true);
    // console.log("createThread function is running...");
    const response = await axios.post(
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/createThread",
      {}
    );
    const threadId = response.data.threadId;
    handleThreadIdChange(threadId);
    setIsLoading(false);
    return threadId;
  }, []);

  if (!threadId) return null;

  return (
    <div className="flex bg-white dark:bg-pf-navy flex-col h-screen">
      <div className="w-full h-full px-6 mb-4 mx-auto bg-white dark:bg-pf-navy">
        <Header active="home" onSignOut={startNewChat} />
        <div className={`w-full px-8 2xl:px-4 bg-white dark:bg-pf-navy`}>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <AssistantComponent
              threadId={threadId}
              createThread={createThread}
              setThreadId={setThreadId}
            />
          )}
        </div>
      </div>
      <ModalRoot />
    </div>
  );
};

export default PBLCopilot;
