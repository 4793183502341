import { Skeleton } from "@mui/material";

const SkeletonActivityCard: React.FC = () => {
  return (
    <div className="flex mt-2 w-full">
      <div className="flex flex-col w-full rounded-xl border p-4">
        <Skeleton variant="text" width="100%" height={50} />
      </div>
    </div>
  );
};

export default SkeletonActivityCard;
