import React from "react";
import { Skeleton } from "@mui/material";
import { ReactComponent as PFIcon } from "../../../assets/pf-icon.svg";
import { ReactComponent as ImThinking } from "../../../assets/im-thinking.svg";

function AssistantWritingSkeleton({
  numMessages,
  lines,
}: {
  numMessages: number;
  lines?: number;
}) {
  return (
    <div className="w-full pb-4 relative">
      <div className="flex items-start w-full">
        <div className="h-7 w-7 mt-2 mr-2 items-start justify-start">
          <PFIcon
            className="text-pf-dark-grey dark:text-pf-grey"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <div className="w-full ml-2 mr-8">
          <Skeleton variant="text" width="100%" height={30} />
          <Skeleton variant="text" width="40%" height={30} />
        </div>
      </div>
      {numMessages < 7 && (
        <div
          className="absolute top-[-6rem] left-12 w-[25rem]"
          style={{ pointerEvents: "none" }}
        >
          <ImThinking
            className="text-transparent"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default AssistantWritingSkeleton;
