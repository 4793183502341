import React from "react";

interface StudentSummaryProps {
  title: string;
  summary: string;
}

const StudentSummary: React.FC<StudentSummaryProps> = ({ title, summary }) => {
  return (
    <div>
      <h2 className="font-bold my-2 text-black dark:text-white">{title}</h2>
      <p className="mb-4 text-black dark:text-white">{summary}</p>
    </div>
  );
};

export default StudentSummary;
