import React, { useEffect, useState } from "react";
import { Message } from "../objects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faFile,
  faFilePen,
  faBookOpen,
  faGear,
  faList,
  faPlay,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as PFIcon } from "../../../assets/pf-icon.svg";
import OptionButton from "./OptionButton";
import { Tooltip } from "flowbite-react";
import ChatMarkdown from "./ChatMarkdown";

type ChatMessageProps = {
  message: Message;
  lastMessage: boolean;
  numMessages: number;
  onStreamingComplete?: () => void;
};

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  lastMessage,
  numMessages,
  onStreamingComplete,
}) => {
  let iconColor = "pf-dark-grey dark:pf-grey";
  let textColor = "text-pf-dark-grey dark:text-pf-grey";
  if (message.role === "assistant") {
    iconColor = "pf-purple dark:pf-green";
    textColor = "text-pf-purple dark:text-pf-green";
  } else if (message.role === "user") {
    iconColor = "black dark:white";
    textColor = "text-black dark:text-white";
  } else if (lastMessage) {
    iconColor = "pf-purple dark:pf-green";
    textColor = "text-pf-purple dark:text-pf-green";
  }
  const textWeight = lastMessage ? "font-semibold" : "";

  const [speechSynthesis, setSpeechSynthesis] =
    useState<SpeechSynthesisUtterance | null>(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [streamedText, setStreamedText] = useState<string>("");
  const [streamingComplete, setStreamingComplete] = useState(false);
  const streamText = (text: string) => {
    setStreamedText(""); // Clear previous text
    let displayedText = "";
    const characters = text.split("");
    let index = 0;

    const intervalId = setInterval(() => {
      if (index < characters.length) {
        displayedText += characters[index];
        setStreamedText(displayedText);
        index++;
      } else {
        clearInterval(intervalId);
        setStreamingComplete(true);
        onStreamingComplete?.();
      }
    }, 28);

    // Clear the interval on cleanup
    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    // Only stream if the message is from the assistant and it's the last message
    if (message.role === "assistant" && lastMessage) {
      const intervalCleanup = streamText(message.content[0]?.text?.value || "");
      return () => {
        intervalCleanup();
      };
    }
    // If it's not the last message, just set the full text without streaming
    else if (message.role === "assistant") {
      setStreamedText(message.content[0]?.text?.value || "");
    }
  }, [message, lastMessage]);

  useEffect(() => {
    if ("speechSynthesis" in window) {
      setSpeechSynthesis(new SpeechSynthesisUtterance());
    }
  }, []);

  const handlePlayClick = (text: string) => {
    if (speechSynthesis) {
      const voices = window.speechSynthesis.getVoices();
      const selectedVoice = voices.find((voice) => voice.name === "Samantha");

      if (selectedVoice) {
        speechSynthesis.voice = selectedVoice;
      }

      speechSynthesis.text = text;
      speechSynthesis.rate = 0.9;
      window.speechSynthesis.speak(speechSynthesis);
      setIsSpeaking(true); // set isSpeaking to true when starting to speak

      speechSynthesis.onend = () => {
        setIsSpeaking(false); // set isSpeaking to false when done speaking
      };
    }
  };

  const handleStopClick = () => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Define the SVG fill classes based on the message role
  let svgFillClass = "text-black"; // default black color class
  if (message.role === "assistant") {
    svgFillClass = "text-pf-purple dark:text-pf-green"; // classes for light and dark mode
  } else if (message.role === "user") {
    svgFillClass = "text-pf-dark-grey dark:text-pf-grey"; // classes for light and dark mode
  }

  return (
    <div
      className={`flex flex-col justify-start items-start ${
        lastMessage && message.role === "assistant" ? "mb-4" : ""
      } mr-4`}
    >
      {message.type === "buttons" ? (
        <div className="flex flex-wrap items-center justify-center text-center place-items-center ml-2 mb-2">
          {message.content.map((path, index) => (
            <OptionButton
              key={index}
              path={path.text.value}
              selectedPath={message.content[message.highlight].text.value}
              onPathSelected={() => {}}
            />
          ))}
        </div>
      ) : (
        <div className="flex items-start justify-start w-full">
          {message.content.map((content, i) => {
            if (content.type === "text" && content.text.value) {
              return (
                <div key={i} className="flex items-start justify-start h-full">
                  {(message.role === "assistant" ||
                    message.role === "pathfinder") && (
                    <div className="flex items-start justify-start h-full">
                      {message.type === "page" && (
                        <FontAwesomeIcon
                          icon={faFile}
                          className={`${textColor} h-5 w-5 mt-3 mr-2 items-start justify-start ${textWeight}`}
                        />
                      )}
                      {message.type === "writing" && (
                        <FontAwesomeIcon
                          icon={faFilePen}
                          className={`${textColor} h-5 w-5 mt-3 mr-2 items-start justify-start ${textWeight}`}
                        />
                      )}
                      {message.type === "list" && (
                        <FontAwesomeIcon
                          icon={faList}
                          className={`${textColor} h-5 w-5 mt-3 mr-2 items-start justify-start ${textWeight}`}
                        />
                      )}
                      {message.type === "search" && (
                        <FontAwesomeIcon
                          icon={faSearch}
                          className={`${textColor} h-5 w-5 mt-3 mr-2 items-start justify-start ${textWeight}`}
                        />
                      )}
                      {message.type === "read-file" && (
                        <FontAwesomeIcon
                          icon={faBookOpen}
                          className={`${textColor} h-5 w-5 mt-3 mr-2 items-start justify-start ${textWeight}`}
                        />
                      )}
                      {message.type === "generate" && (
                        <FontAwesomeIcon
                          icon={faGear}
                          className={`${textColor} h-5 w-5 mt-3 mr-2 items-start justify-start ${textWeight}`}
                        />
                      )}
                      {message.type === "dialog" && (
                        <div className="h-[25px] w-[25px] mt-[6px] mr-1 items-start justify-start">
                          <PFIcon
                            className={`${textWeight} ${svgFillClass}`}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {message.role === "assistant" && lastMessage ? (
                    // Streamed text for the assistant's message
                    <div className="bg-pf-purple bg-opacity-10 p-2 rounded-lg mb-6 ml-2 shadow-2xl">
                      <ChatMarkdown
                        content={streamedText}
                        textClassName={`${textColor} ml-2 py-2 ${textWeight}`}
                      />
                    </div>
                  ) : message.imageUrl ? (
                    <div className="my-2">
                      <img
                        src={message.imageUrl}
                        alt="Uploaded"
                        className="max-w-xs max-h-60 h-auto mb-4"
                      />
                      <h3
                        className={`${textColor} text-2xl ml-2 py-2 ${textWeight}`}
                      >
                        {content.text.value}
                      </h3>
                    </div>
                  ) : (
                    // Regular text for user and no img
                    // <h3 className={`${textColor} text-2xl ml-2 mb-2 py-2 ${textWeight}`} >
                    //   {content.text.value}
                    // </h3>
                    <ChatMarkdown
                      content={content.text.value}
                      textClassName={`${textColor} ml-2 mb-2 py-2 ${textWeight}`}
                    />
                  )}
                  <Tooltip content="Click to hear aloud">
                    <button
                      onClick={
                        isSpeaking
                          ? handleStopClick
                          : () => handlePlayClick(content.text.value)
                      }
                      className={`ml-4 mt-[0.7rem] border ${
                        lastMessage && "border-2"
                      } ${
                        lastMessage
                          ? "border-pf-purple dark:border-pf-green"
                          : "border-pf-dark-grey dark:border-pf-grey"
                      } h-5 w-5 rounded-full flex-shrink-0 flex items-center justify-center`}
                    >
                      <FontAwesomeIcon
                        icon={isSpeaking ? faSquare : faPlay}
                        className={`${
                          lastMessage
                            ? "text-pf-purple dark:text-pf-green"
                            : "text-pf-dark-grey dark:text-pf-grey"
                        } ml-[2px]`}
                        size="sm"
                      />
                    </button>
                  </Tooltip>
                </div>
              );
            } else if (message.type === "file") {
              return (
                <div key={i}>
                  <FontAwesomeIcon
                    icon={faFile}
                    className={`${textColor} h-8 w-8 ${textWeight}`}
                  />
                  <a href={null}>TITLE</a>
                </div>
              );
            } else {
              return <div></div>;
            }
          })}
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
