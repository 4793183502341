/* eslint-disable jsx-a11y/img-redundant-alt */
import ReactPlayer from "react-player";
import LinkPreview from "../../../components/LinkPreview";
import { Checkbox } from "flowbite-react";
import { Skeleton } from "@mui/material";
import OptionResourceButton from "./OptionResourceButton";
import OverviewPage from "./OverviewPage";
import PathFinderButton from "../../../components/common/PathFinderButton";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faImage, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "flowbite-react";

type InteractiveBoxProps = {
  loading: boolean;
  title: string;
  description: string;
  resource: { title: string; url: string; description: string };
  imageTitle: string;
  images: string[];
  objectives: string[];
  extraResources: Array<{ title: string; url: string; description: string }>;
  changeResources: (newSelected: {
    title: string;
    url: string;
    description: string;
  }) => void;
  onResourceSelected:
    | ((resource: { title: string; url: string; description: string }) => void)
    | null;
  notionLink: string;
  showOverview: boolean;
  handleRegenerateResources: (setLoading: (loading: boolean) => void) => void;
  handleCreatePageClick: (
    titl: string,
    desc: string,
    r: {
      title: string;
      url: string;
      description: string;
    },
    obs: string[],
    extraRs: Array<{ title: string; url: string; description: string }>
  ) => void;
  threadId: string;
  parentId: string;
  focus?: string;
  setFocus?: React.Dispatch<
    React.SetStateAction<"neutral" | "chat" | "page" | "full">
  >;
  setParentId?: React.Dispatch<React.SetStateAction<string>>;
  handleFindImagesRHSClick?: () => void;
  handleCreatePageRHSClick?: () => void;
};

const InteractiveBox: React.FC<InteractiveBoxProps> = ({
  loading,
  title,
  description,
  resource,
  imageTitle,
  images,
  objectives,
  extraResources,
  changeResources,
  onResourceSelected,
  notionLink,
  showOverview,
  handleRegenerateResources,
  handleCreatePageClick,
  threadId,
  parentId,
  focus,
  setFocus,
  setParentId,
  handleCreatePageRHSClick, 
  handleFindImagesRHSClick,
}) => {
  const [handleRegenerateLoading, setHandleRegenerateLoading] =
    useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [currTitle, setCurrTitle] = useState<string>(title);
  const [currDescription, setCurrDescription] = useState<string>(description);
  const [currResource, setCurrResource] = useState<{
    title: string;
    url: string;
    description: string;
  }>(resource);
  const [currObjectives, setCurrObjectives] = useState<string[]>(objectives);
  const [currExtraResources, setCurrExtraResources] = useState<
    Array<{ title: string; url: string; description: string }>
  >([...extraResources]);
  const [direction, setDirection] = useState<"up" | "down">("down");
  const [copiedIndices, setCopiedIndices] = useState<{
    [key: number]: boolean;
  }>({});

  useEffect(() => {
    setCurrTitle(title);
    setCurrDescription(description);
    setCurrResource(resource);
    setCurrObjectives(objectives);
    setCurrExtraResources(extraResources);
  }, [title, description, resource, objectives, extraResources]);

  useEffect(() => {
    // console.log("NOTION PAGE PARAM OBJECTIVES: ", currObjectives);
    if (
      currTitle &&
      currDescription &&
      currResource &&
      currObjectives.length > 1 &&
      currExtraResources.length > 0
    ) {
      handleCreatePageClick(
        currTitle,
        currDescription,
        currResource,
        currObjectives,
        currExtraResources
      );
    }
  }, [
    currTitle,
    currDescription,
    currResource,
    currObjectives,
    currExtraResources,
  ]);

  const scrollToTop = () => {
    try {
      document
        .getElementById("interaction-box")
        .scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      console.error("Failed to scroll:", error);
    }
  };

  if (showOverview) {
    return (
      <div className="bg-pf-grey dark:bg-white dark:bg-opacity-10 border border-pf-dark-grey dark:border-pf-grey w-full h-full max-h-[calc(100vh-132px)] rounded-md">
        <OverviewPage
          url={notionLink}
          parentPageId={parentId}
          setParentId={setParentId}
        />
      </div>
    );
  }

  const handleCopyClick = async (im: string, index: number) => {
    try {
      await navigator.clipboard.writeText(im);
      // Set the copied state to true for the clicked button
      setCopiedIndices((prev) => ({ ...prev, [index]: true }));
      // Reset the copied state for the clicked button after 2 seconds
      setTimeout(
        () => setCopiedIndices((prev) => ({ ...prev, [index]: false })),
        2000
      );
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <div
      className="bg-pf-grey dark:bg-white dark:bg-opacity-10 border border-pf-dark-grey dark:border-pf-grey w-full h-full max-h-[calc(100vh-132px)] rounded-md relative"
      onClick={() => {
        if (setFocus && focus !== "page") {
          setFocus("page");
        }
      }}
    >
      <div className="mr-1 h-full overflow-y-scroll" id="interaction-box">
        {loading ? (
          <div className="flex flex-col p-6 space-y-6 w-full">
            {(imageTitle || images) && currTitle === "" ? (
              <>
                <h2 className="font-semibold text-black dark:text-white">
                  {imageTitle}
                </h2>
                <div>
                  {images && (
                    <div className="w-full grid grid-cols-2 gap-2 p-2">
                      {images.map((im, index) => (
                        <div
                          key={index}
                          className="relative h-full w-full overflow-hidden bg-pf-purple bg-opacity-20 rounded-lg flex items-center justify-center"
                        >
                          <img
                            src={im}
                            alt={`image-${index}`}
                            className="w-full h-auto p-2 rounded-lg"
                          />
                          <Tooltip content="Click to copy image URL">
                            <button
                              className="absolute top-2 right-2 p-2 text-white bg-pf-purple dark:bg-pf-dark-grey rounded"
                              onClick={() => handleCopyClick(im, index)}
                            >
                              <FontAwesomeIcon
                                icon={copiedIndices[index] ? faCheck : faCopy}
                                size="lg"
                              />
                            </button>
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                {" "}
                {currTitle !== "" ? (
                  <>
                    <h2 className="font-semibold text-black dark:text-white">
                      {currTitle}
                    </h2>
                  </>
                ) : (
                  <>
                    <h2 className="text-center font-semibold text-black dark:text-white">
                      We're going to make a page for you to learn from here:
                    </h2>
                    <Skeleton variant="rounded" width="100%" height={60} />
                  </>
                )}
                {currDescription !== "" ? (
                  <div>
                    <p className="font-bold text-black dark:text-white">
                      How this helps with your goal:{" "}
                      <span className="font-normal">{currDescription}</span>
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col space-y-2">
                    <Skeleton variant="rounded" width="100%" height={25} />
                    <Skeleton variant="rounded" width="100%" height={25} />
                    <Skeleton variant="rounded" width="100%" height={25} />
                    <Skeleton variant="rounded" width="60%" height={25} />
                  </div>
                )}
                {currResource ? (
                  <div className="flex flex-col space-y-4">
                    <h4 className="mb-2 font-semibold text-black dark:text-white">
                      {currResource.title}
                    </h4>
                    <div className="flex items-center text-center justify-center">
                      {ReactPlayer.canPlay(currResource.url) ? (
                        <ReactPlayer
                          url={currResource.url}
                          controls={true}
                          config={{
                            youtube: {
                              playerVars: {
                                controls: 1,
                              },
                            },
                          }}
                        />
                      ) : (
                        <LinkPreview url={currResource.url} size="small" />
                      )}
                    </div>
                    <p className="pt-2 text-black dark:text-white">
                      <strong>Summary of this resource: </strong>
                      {currResource.description}
                    </p>
                  </div>
                ) : currExtraResources.length === 6 ? (
                  <div>
                    <div className="w-full text-center mb-4">
                      <h3 className="font-bold text-pf-purple dark:text-pf-green">
                        Click on your favourite resource from the 6 below:
                      </h3>
                    </div>
                    <div className="w-full grid grid-cols-2 gap-2">
                      {currExtraResources.map((r, index) => (
                        <OptionResourceButton
                          key={index}
                          resource={r}
                          selectedResource={currResource}
                          onResourceSelected={onResourceSelected}
                        />
                      ))}
                      {/* <div className="flex self-end pb-4 pr-4 justify-end"> */}
                      <div className="fixed bottom-6 right-0 p-4 z-10 mr-12">
                        <PathFinderButton
                          onClick={() => {
                            handleRegenerateResources(
                              setHandleRegenerateLoading
                            );
                          }}
                          loading={handleRegenerateLoading}
                        >
                          I want new resources
                        </PathFinderButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="mb-4">
                      <Skeleton variant="rounded" width="100%" height={40} />
                    </div>
                    <Skeleton variant="rounded" width="100%" height="300px" />
                  </div>
                )}
                {objectives[0] !== "" ? (
                  <div>
                    <h4 className="mb-2 font-semibold text-black dark:text-white">
                      What you should be comfortable with understanding
                    </h4>
                    {objectives.map((objective, index) => (
                      <div
                        key={index}
                        className="flex mb-2 items-center space-x-3"
                      >
                        <div className="border h-7 w-7 text-center items-center border-pf-purple dark:border-pf-green rounded-lg">
                          <Checkbox
                            id={`objective${index}`}
                            name={`objective${index}`}
                            className="form-checkbox h-5 w-5 bg-[#F4EBF4] dark:bg-pf-grey text-pf-complete border-2 "
                          />
                        </div>
                        <label
                          htmlFor={`objective${index}`}
                          className="ml-2 text-pf-dark-grey dark:text-pf-grey"
                        >
                          {objective}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : currExtraResources.length !== 6 ? (
                  <div className="flex flex-col space-y-2">
                    <div className="mb-2">
                      <Skeleton variant="rounded" width="100%" height={40} />
                    </div>
                    <div className="flex space-x-2">
                      <Skeleton variant="rounded" width="25px" height={25} />
                      <Skeleton variant="rounded" width="80%" height={25} />
                    </div>
                    <div className="flex space-x-2">
                      <Skeleton variant="rounded" width="25px" height={25} />
                      <Skeleton variant="rounded" width="60%" height={25} />
                    </div>
                    <div className="flex space-x-2">
                      <Skeleton variant="rounded" width="25px" height={25} />
                      <Skeleton variant="rounded" width="90%" height={25} />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {currExtraResources.length &&
                currExtraResources.length !== 6 ? (
                  <div>
                    <h4 className="mb-2 font-semibold text-black dark:text-white">
                      Extra resources
                    </h4>
                    <div className="w-full grid grid-cols-2 gap-2">
                      {currExtraResources.map((r, index) => (
                        <OptionResourceButton
                          key={index}
                          resource={r}
                          selectedResource={currResource}
                          onResourceSelected={(resource) => {
                            changeResources(resource);
                            scrollToTop();
                          }}
                        />
                      ))}
                    </div>
                  </div>
                ) : currExtraResources.length !== 6 ? (
                  <div className="flex flex-col space-y-2">
                    <div className="mb-2">
                      <Skeleton variant="rounded" width="100%" height={40} />
                    </div>
                    <div className="w-full grid grid-cols-2 gap-2">
                      <Skeleton variant="rounded" width="100%" height={250} />
                      <Skeleton variant="rounded" width="100%" height={250} />
                      <Skeleton variant="rounded" width="100%" height={250} />
                      <Skeleton variant="rounded" width="100%" height={250} />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        ) : (
          <div className="flex flex-col h-full p-6 space-y-6 w-full justify-center">
            <div className="flex flex-col items-center justify-center space-y-4">
              <h4 className="text-center text-black dark:text-white font-semibold pb-8">
                We're going to make a page here for you to learn from the best resources or see the coolest pictures
              </h4>
              <div className="flex justify-center items-center gap-8">
                <PathFinderButton
                  size="lg"
                  // outlined={true}
                  onClick={handleCreatePageRHSClick}
                  // color="pf-dark-grey dark:pf-grey"
                >
                  <div className="flex gap-2 items-center">
                    <FontAwesomeIcon icon={faPlus} className="text-lg" />
                    <h5>
                      Find resources
                    </h5>
                  </div>
                </PathFinderButton>
                <PathFinderButton
                  size="lg"
                  // outlined={true}
                  onClick={handleFindImagesRHSClick}
                  // color="pf-dark-grey dark:pf-grey"
                >
                  <div className="flex gap-2 items-center">
                    <FontAwesomeIcon icon={faImage} className="text-lg" />
                    <h5>
                      Find images
                    </h5>
                  </div>
                </PathFinderButton>
              </div>
              {/* <h2 className="text-center text-black dark:text-white font-semibold pb-8">
                We're going to make a page for you to learn with here:
              </h2> */}
              {/* <h4 className="text-lg text-black dark:text-white text-center">
                You'll find the coolest and most fun resources for you to learn
                from to create your project. What comes up here is based on your
                chat on the left!
              </h4> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InteractiveBox;
