import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";
import ModalRoot from "../components/ModalRoot";
import ProgressFooter from "../components/ProgressFooter";

interface RootNoFooterPageProps {
  header: string;
  pathName?: string;
  children: JSX.Element;
}

const RootNoFooterPage = (props: RootNoFooterPageProps) => {
  return (
    <div className="min-h-screen bg-white dark:bg-pf-navy z-50 flex flex-col">
      <div className="max-w-[1400px] w-full mx-auto">
        <Header active={props.header} />
        <div className="flex-grow">{props.children}</div>
      </div>
      <ModalRoot />
    </div>
  );
};

export default RootNoFooterPage;
