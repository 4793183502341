import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import "tailwindcss/tailwind.css";
import PathFinderButton from "../PathFinderButton";
import { TextInput } from "flowbite-react";
import ChatMessage from "../ChatMessages";

const ChatInterface = ({ contextOnPath }: { contextOnPath: string }) => {
  const [threadId, setThreadId] = useState(null);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState<
    Array<{ role: string; content: string }>
  >([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  const assistantId = "asst_rX1MCafFiZQd16VUHNVVjOch";
  const apiKey = "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";

  const runThread = async (threadId: string) => {
    // console.log("Processing runThread...");

    const response = await axios.post(
      `https://api.openai.com/v1/threads/${threadId}/runs`,
      {
        assistant_id: assistantId,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1",
        },
      }
    );

    return response.data;
  };

  const createThread = async () => {
    // console.log("Processing createThread...");

    const response = await axios.post(
      `https://api.openai.com/v1/threads`,
      {},
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1",
        },
      }
    );

    // console.log(response.data.id);
    setThreadId(response.data.id);
    return response.data.id; // return the threadId
  };

  const createMessage = async (
    threadId: string, // Add threadId as a parameter
    role: string,
    content: string,
    file_ids: string[] = [],
    metadata: any = {}
  ) => {
    // console.log("Processing createMessage...", threadId);

    const response = await axios.post(
      `https://api.openai.com/v1/threads/${threadId}/messages`,
      {
        role,
        content,
        file_ids,
        metadata,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1",
        },
      }
    );

    setIsSubmitting(true);

    return response.data;
  };

  const awaitAssistantActions = async (threadId: string) => {
    // console.log("Processing getAssistantResponse...");

    // Run the thread
    const runResponse = await runThread(threadId);

    let runStatus = runResponse.status;
    let runStepStatus = null;
    let runId = runResponse.id;

    // Constantly check the status of the run and its steps until it has the status "complete"
    while (runStatus !== "completed") {
      //console.log("Checking runStatus");

      const runStatusResponse = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v1",
          },
        }
      );

      runStatus = runStatusResponse.data.status;
      //console.log(runStatus);
      // If run status is not complete, check the run step status
      if (runStatus !== "completed") {
        while (runStepStatus !== "completed") {
          //console.log("Checking runStepStatus");

          const runStepStatusResponse = await axios.get(
            `https://api.openai.com/v1/threads/${threadId}/runs/${runId}/steps`,
            {
              headers: {
                Authorization: `Bearer ${apiKey}`,
                "Content-Type": "application/json",
                "OpenAI-Beta": "assistants=v1",
              },
            }
          );

          if (
            runStepStatusResponse.data.data[
              runStepStatusResponse.data.data.length - 1
            ]
          ) {
            runStepStatus = runStepStatusResponse.data.data[0].status;
          }

          // Wait for a second before checking the status again to avoid hitting rate limits
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      }

      // Wait for a second before checking the status again to avoid hitting rate limits
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    setIsSubmitting(false);
  };

  const getMessages = async (threadId: string) => {
    console.log("Processing getMessages...");

    const messagesResponse = await axios.get(
      `https://api.openai.com/v1/threads/${threadId}/messages`,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1",
        },
      }
    );

    if (Array.isArray(messagesResponse.data.data)) {
      const messages = messagesResponse.data.data.map((message) => {
        if (message.role === "user" || message.role === "assistant") {
          return {
            role: message.role,
            content: message.content[0]?.text?.value,
          };
        }
      });

      // Filter out any undefined values before updating chatHistory
      const validMessages = messages.filter((message) => message !== undefined);
      setChatHistory(validMessages);
    } else {
      console.error("Expected an array but got:", messagesResponse.data.data);
    }
  };

  const handleSendMessage = async (msg?: string) => {
    if (!msg) {
      console.log("Processing handleSendMessage...", message);
      setChatHistory((prevChatHistory) => [
        { role: "user", content: message },
        ...prevChatHistory,
      ]);

      let currentThreadId = threadId;
      if (!currentThreadId) {
        setChatHistory((prevChatHistory) => [
          { role: "user", content: message },
          ...prevChatHistory,
        ]);
        currentThreadId = await createThread();
        await createMessage(currentThreadId, "user", contextOnPath);
      }
      await createMessage(currentThreadId, "user", message);
      setMessage("");

      await awaitAssistantActions(currentThreadId);
      getMessages(currentThreadId);
    } else {
      setChatHistory((prevChatHistory) => [
        { role: "user", content: msg },
        ...prevChatHistory,
      ]);

      let currentThreadId = threadId;
      if (!currentThreadId) {
        setChatHistory((prevChatHistory) => [
          { role: "user", content: msg },
          ...prevChatHistory,
        ]);
        currentThreadId = await createThread();
        await createMessage(currentThreadId, "user", contextOnPath);
      }
      await createMessage(currentThreadId, "user", msg);
      setMessage("");

      await awaitAssistantActions(currentThreadId);
      getMessages(currentThreadId);
    }
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  return (
    <div
      className="fixed bottom-16 right-0 flex flex-col h-1/2 bg-pf-purple bg-opacity-10 rounded-xl border border-pf-purple m-4 p-4 pr-2 resize-y overflow-auto"
      style={{ aspectRatio: "0.75" }}
    >
      <>
        <div className="max-w-full flex flex-col gap-4 flex-grow overflow-auto pr-2">
          {chatHistory.length === 0 ? (
            <div className="flex flex-col gap-2">
              <ChatMessage
                role="assistant"
                content="Hi I'm PathFinder's AI assistant! Here to help whenever you need. Below are some options on what I can help with."
              />
              <div className="flex flex-col gap-4 w-full items-center">
                <div className="flex justify-between w-full">
                  <button
                    className="bg-pf-navy bg-opacity-10 border border-pf-navy rounded-3xl py-2 px-4"
                    onClick={() => {
                      handleSendMessage(
                        "Summarise the primary resource on the page"
                      );
                    }}
                  >
                    <text className="text-sm text-pf-navy">
                      Summarise the resource
                    </text>
                  </button>
                  <button
                    className="bg-pf-navy bg-opacity-10 border border-pf-navy rounded-3xl py-2 px-4"
                    onClick={() => {
                      handleSendMessage(
                        "Suggest a project based on the content of the resource"
                      );
                    }}
                  >
                    <text className="text-sm text-pf-navy">
                      Suggest a project
                    </text>
                  </button>
                </div>
                <div>
                  <button
                    className="bg-pf-navy bg-opacity-10 border border-pf-navy rounded-3xl py-2 px-4"
                    onClick={() => {
                      handleSendMessage(
                        "I'm stuck. Give me alternative resources I could use to learn this topic."
                      );
                    }}
                  >
                    <text className="text-sm text-pf-navy">
                      Provide alternative resources
                    </text>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {[...chatHistory]
                .reverse()
                .slice(1)
                .map((message, index) => {
                  //console.log("Message", message);
                  if (message.content) {
                    return (
                      <div className="flex">
                        <ChatMessage
                          key={index}
                          role={message.role}
                          content={message.content}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          )}
          {isSubmitting && <ChatMessage role="assistant" content="" />}
          <div ref={chatEndRef} />
        </div>
        <div className="flex mt-4 gap-4 pr-4">
          <div className="flex-grow">
            <TextInput
              type="text"
              placeholder="Send a message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (
                  (e.metaKey || e.ctrlKey) &&
                  e.key === "Enter" &&
                  message !== ""
                ) {
                  handleSendMessage();
                }
              }}
              className="w-full rounded-sm"
            />
          </div>
          <div className="flex-shrink-0">
            <PathFinderButton
              type="submit"
              loading={isSubmitting}
              onClick={() => {
                handleSendMessage();
              }}
              disabled={message === "" || isSubmitting}
            >
              {isSubmitting ? (
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="text-white py-1"
                  />
                </div>
              ) : (
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    className="text-white py-1"
                  />
                </div>
              )}
            </PathFinderButton>
          </div>
        </div>
      </>
    </div>
  );
};

export default ChatInterface;
