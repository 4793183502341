import React from "react";
import { Card } from "flowbite-react";
import ReactPlayer from "react-player";
import LinkPreview from "../../../components/LinkPreview";

type OptionResourceButtonProps = {
  resource: { title: string; url: string; description: string };
  selectedResource: { title: string; url: string; description: string } | null;
  onResourceSelected: (resource: {
    title: string;
    url: string;
    description: string;
  }) => void;
};

const OptionResourceButton: React.FC<OptionResourceButtonProps> = ({
  resource,
  selectedResource,
  onResourceSelected,
}) => {
  const { title, url, description } = resource;

  return (
    <button
      onClick={() => {
        onResourceSelected(resource);
      }}
      className={`border-pf-purple z-10 border rounded-lg m-2 flex flex-col justify-between ${
        resource === selectedResource
          ? "bg-pf-purple dark:bg-pf-green"
          : "bg-[#F4EBF4] dark:bg-[#E6EF94]"
      }`}
    >
      <div className="p-2 flex flex-col space-y-2 justify-between w-full">
        <div className="flex-grow">
          {ReactPlayer.canPlay(url) ? (
            <div
              className="relative"
              onClick={() => onResourceSelected(resource)}
            >
              <ReactPlayer url={url} width="100%" height="100%" />
              <div className="absolute inset-0" />
            </div>
          ) : (
            <div className="mx-4">
              <LinkPreview url={url} size="small" />
            </div>
          )}
        </div>
        <p
          className={`text-lg font-bold ${
            resource === selectedResource
              ? "text-white dark:text-black"
              : "text-black dark:text-black"
          }`}
        >
          {title}
        </p>
        <p
          className={`font-normal text-ellipsis pt-2 overflow-hidden ${
            resource === selectedResource ? "text-pf-grey" : "text-pf-dark-grey"
          }`}
        >
          {description}
        </p>
      </div>
    </button>
  );
};

export default OptionResourceButton;
