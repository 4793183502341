import lazyRetry from "@tdotcode/react-lazy-retry";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "../pages/Login/forgot-password";
import LoginPage from "../pages/Login/index";
import IframePage from "../pages/Iframe";
import Explore from "../pages/Explore";
import CreateAccount from "../pages/Onboarding/create-account";
import ComingSoonPage from "../pages/ComingSoon";
import Dashboard from "../pages/Dashboard";
import HomePage from "../pages/Home/index";
import SummerCohort from "../pages/SummerCohort";
import ContactPage from "../pages/Contact";
import ApplicationSuccessPage from "../pages/SummerCohort/applicationSuccess";
import GeneratePathPage from "../pages/GeneratePaths";
import PathPage from "../pages/PathPage";
import GenerateActivitiesPage from "../pages/ActivitiesBuilder";
import PBLCopilot from "../pages/PBLCopilot";
import TeacherDashboard from "../pages/PBLCopilot/Teacher/TeacherDashboardPage";
import StudentOverviewPage from "../pages/PBLCopilot/Teacher/StudentOverviewPage";
import SuggestFeaturePage from "../pages/SuggestFeature";

const ErrorPage = lazyRetry(() => import("../pages/error"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PBLCopilot />} />
      <Route path="/generate-activities" element={<GenerateActivitiesPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/teacher-dashboard" element={<TeacherDashboard />} />
      <Route path="/suggest-feature" element={<SuggestFeaturePage />} />
      <Route
        path="/teacher-dashboard/:studentName"
        element={<StudentOverviewPage />}
      />
      <Route
        path="/about-us"
        element={<IframePage pageURL={"c038db994ae74a038232af92aeb8370d"} />}
      />
      <Route
        path="/terms-and-conditions"
        element={<IframePage pageURL={"f7ec797b20564a2c88f6ffce0f61409e"} />}
      />
      <Route
        path="/privacy-policy"
        element={<IframePage pageURL={"72dd48768286472b9ce8264aa97d7d26"} />}
      />
      <Route
        path="/reimbursement"
        element={<IframePage pageURL={"c09ffd7069ff4387a19e261925f8f06b"} />}
      />
      <Route
        path="/cookies-policy"
        element={<IframePage pageURL={"27c4a08c23f9432f934f9fa3a9e6d26b"} />}
      />
      <Route
        path="/data-science-with-python-preview"
        element={<IframePage pageURL={"ff51548a1e5146d3b38d8a88af21dcc7"} />}
      />

      <Route
        path="/management-consulting-preview"
        element={<IframePage pageURL={"edbb5494092e4500bc7f8bdc051e9e4d"} />}
      />
      <Route
        path="/ai-for-healthcare-preview"
        element={<IframePage pageURL={"ce902a05ab9f4173a97fb2ccab8f3e39"} />}
      />
      <Route
        path="/search-engine-optimisation-preview"
        element={<IframePage pageURL={"ab624a888d6944a7a5d3a0585b08639e"} />}
      />

      <Route
        path="/ui-ux-design-preview"
        element={<IframePage pageURL={"cdb53bfe76e74b5f8506b5a5e6a75b07"} />}
      />

      <Route
        path="/coding-with-python-preview"
        element={<IframePage pageURL={"a0784d4206dc4b8d8b777d606754881c"} />}
      />

      <Route path="/login/forgot-password" element={<ForgotPassword />} />
      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/coming-soon" element={<ComingSoonPage />} />
      <Route path="/explore" element={<Explore />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/generate-path" element={<GeneratePathPage />} />

      <Route
        path="/management-consulting/:pageId"
        element={<IframePage pathName="management-consulting" />}
      />
      <Route
        path="/ui-ux-design/:pageId"
        element={<PathPage pathName="ui-ux-design" />}
      />
      <Route
        path="/data-science-with-python/:pageId"
        element={<IframePage pathName="data-science-with-python" />}
      />
      <Route
        path="/search-engine-optimisation/:pageId"
        element={<IframePage pathName="search-engine-optimisation" />}
      />
      <Route
        path="/ai-for-healthcare/:pageId"
        element={<IframePage pathName="ai-for-healthcare" />}
      />

      <Route path="/path/:pageId" element={<IframePage />} />
      <Route path="/summer-cohort" element={<SummerCohort />} />
      <Route
        path="/summer-cohort/success"
        element={<ApplicationSuccessPage />}
      />
    </Routes>
  );
};

export const pathHomepageUrls = {
  "UI / UX Design": "/ui-ux-design/1f5bff438ca04dbcbae6e8adbdb0c012",
  "Data Science with Python":
    "/data-science-with-python/2c5872b1052b4f75ae839c0ed2f9678f",
  "Management Consulting":
    "/management-consulting/e6c7a639df3740a3a6b048c79493cc43",
  "Artificial Intelligence for Healthcare":
    "/ai-for-healthcare/c1230bec0dd64526969a3f92e132f6f9",
  "Search Engine Optimisation (SEO)":
    "/search-engine-optimisation/7e0017f0cb5b40c996b5aa91b1f9384f",
};
export const pathUrls = {
  "UI / UX Design": "/ui-ux-design",
  "Data Science with Python": "/data-science-with-python",
  "Management Consulting": "/management-consulting",
  "Artificial Intelligence for Healthcare": "/ai-for-healthcare",
  "Search Engine Optimisation (SEO)": "/search-engine-optimisation",
};

export default AppRoutes;
