import React from "react";
import { Tooltip } from "flowbite-react";

type OptionButtonProps = {
  path: string;
  selectedPath: string | null;
  onPathSelected: (path: string) => void;
};

const OptionButton: React.FC<OptionButtonProps> = ({
  path,
  selectedPath,
  onPathSelected,
}) => {
  // console.log("On Path Selected: ", onPathSelected);
  return (
    <div>
      {onPathSelected.toString() !== "() => {}" ? (
        <Tooltip content="Click me to select">
          <button
            onClick={() => {
              onPathSelected(path);
            }}
            className={`border-pf-purple dark:border-pf-green border rounded-full m-2 px-4 py-2 ${
              path === selectedPath
                ? "bg-pf-purple dark:bg-pf-green"
                : "bg-[#F4EBF4] dark:bg-pf-dark-grey"
            }`}
          >
            <p
              className={`text-sm ${
                path === selectedPath
                  ? "text-white dark:text-black font-bold"
                  : "text-black dark:text-white"
              }`}
            >
              {path}
            </p>
          </button>
        </Tooltip>
      ) : (
        <button
          onClick={() => {
            onPathSelected(path);
          }}
          className={`border-pf-purple border rounded-full m-2 px-4 py-2 ${
            path === selectedPath
              ? "bg-pf-purple dark:bg-pf-green"
              : "bg-pf-grey dark:bg-pf-dark-grey"
          }`}
        >
          <p
            className={`text-sm ${
              path === selectedPath
                ? "text-white font-bold dark:text-black"
                : "text-black dark:text-white"
            }`}
          >
            {path}
          </p>
        </button>
      )}
    </div>
  );
};

export default OptionButton;
