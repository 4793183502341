import RootPage from "../root";
import { useRef, useState } from "react";
import StepsCard from "./cards/stepsCard";
import AboutPathCard from "./cards/aboutPathCard";
import EditPathCard from "./cards/editPathCard";
import AccessShareCard from "./cards/accessShareCard";
import axios from "axios";

export interface Topics {
  [key: string]: string[];
}
export interface Links {
  [key: string]: string;
}

const GeneratePathPage = () => {
  const aboutPathRef = useRef(null);
  const editPathRef = useRef(null);
  const accessShareRef = useRef(null);

  const [topics, setTopics] = useState<Topics>({});
  const [linksMap, setLinksMap] = useState<Links>({});
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [numOfWeeks, setNumOfWeeks] = useState(-1);

  const handleGeneratePath = async (title, selectedWeeks, learner, goal) => {
    setStep(2);
    setLoading(true);
    setNumOfWeeks(selectedWeeks);
    // Fetch topics and links from backend
    const response = await axios.post(
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getContent",
      {
        topic: title,
        selectedWeeks: selectedWeeks,
        learner: learner,
        goal: goal,
      }
    );
    const data = response.data;
    console.log("data", data.links);
    setTopics(data.topics);
    setLinksMap(data.links);
    setLoading(false);
  };

  return (
    <RootPage header="home">
      <div className="container mx-auto my-10">
        <StepsCard
          aboutPathRef={aboutPathRef}
          editPathRef={editPathRef}
          accessShareRef={accessShareRef}
          step={step}
        />
        <AboutPathCard ref={aboutPathRef} onGeneratePath={handleGeneratePath} />
        {step > 1 ? (
          <EditPathCard
            ref={editPathRef}
            topics={topics}
            linksMap={linksMap}
            numOfWeeks={numOfWeeks}
          />
        ) : (
          <p></p>
        )}
      </div>
    </RootPage>
  );
};

export default GeneratePathPage;