import React, { FC, ChangeEvent, useRef, useEffect } from "react";
interface UserInputProps {
  placeholder: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit?: () => void;
  setHeight?: (number) => void;
  className?: string;
}

const UserInput: FC<UserInputProps> = ({
  placeholder,
  value,
  onChange,
  onSubmit,
  setHeight,
  className,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      if (setHeight) {
        setHeight(textareaRef.current.scrollHeight);
      }
    }
  }, [value]);

  useEffect(() => {
    // Focus the textarea element when the component mounts
    const textarea = textareaRef.current;
    if (textarea) {
      const cursorPosition = value ? value.length : 0; // Set cursor at the end of the value
      textarea.focus();
      textarea.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && (!e.shiftKey || e.metaKey)) {
      e.preventDefault();
      onSubmit?.();
    }
  };

  return (
    <div className="flex flex-col w-full border-none p-0 m-0 items-center">
      <textarea
        ref={textareaRef}
        placeholder={placeholder}
        className={`${className} bg-transparent text-black dark:text-white flex items-center mt-2 w-full min-h-[2.5rem] border-none p-0 m-0 resize-none font-semibold border-gray-300 rounded-md outline-none focus:outline-none active:outline-none hover:border-none focus:border-none active:border-none focus:ring-0`}
        style={{
          boxSizing: "border-box",
          outline: "none",
          overflow: "hidden",
        }}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        rows={1} // Set rows to 1
      />
    </div>
  );
};

export default UserInput;