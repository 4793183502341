// Create an Authenticator class with AWS ampligy signUp, signIn, signOut, and confirmSignUp methods
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";
import axios from "axios";

class Authenticator {
  // Sign up a new user
  public static async signUp(username: string, password: string): Promise<any> {
    try {
      const response = await Auth.signUp({
        username,
        password,
        autoSignIn: {
          enabled: true,
        },
      });
      // console.log("Successfully signed up, response: ", response);
      return response;
    } catch (error) {
      console.error("Didnt sign up in auth: ", error);
    }
  }

  // Sign in an existing user
  public static async signIn(username: string, password: string): Promise<any> {
    try {
      const response = await Auth.signIn(username, password);
      //console.log("Successfully signed in");
      return response;
    } catch (error) {
      console.error(error);
      return error.message;
    }
  }

  // Confirm the sign up of a new user
  public static async confirmSignUp(
    username: string,
    code: string
  ): Promise<any> {
    try {
      const response = await Auth.confirmSignUp(username, code);
      //console.log("Successfully confirmed sign up");
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  // Sign out the current user
  public static async signOut(): Promise<any> {
    try {
      // Cookies.remove("userId");
      const response = await Auth.signOut();
      //console.log("Successfully signed out");
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  public static async ifSignedIn() {
    //console.log("testing nowknfwj");
    try {
      const userInfo = await Auth.currentAuthenticatedUser();
      //console.log("User info: ", userInfo);
      //console.log("testing now");
      return true;
    } catch {
      return false;
    }
  }

  public static async getCurrentUserId() {
    // Check if cookie "userId" is set
    let userId = Cookies.get("userId");
    if (userId) {
      return userId;
    }
    const userInfo = await Auth.currentUserInfo();
    if (!userInfo) {
      return null;
    }
    const awsId = userInfo.username;

    const ourUserId = await axios.post(
      // "http://127.0.0.1:8081/thepathfinderprojectbackendapi/us-central1/getUserIdFromAWSId",
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getUserIdFromAWSId",
      { awsId: awsId }
    );
    userId = ourUserId.data;
    const oneHour = 1 / 24; // 1 hour expressed as a fraction of a day
    const expirationDate = new Date(
      new Date().getTime() + oneHour * 60 * 60 * 1000
    );
    Cookies.set("userId", userId, { expires: expirationDate });
    return userId;
  }

  public static async getUserNameByEmail(
    email: string
  ): Promise<string | null> {
    try {
      // console.log("Sending email: " + email);
      const response = await axios.get(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getPblUserNameByEmail",
        { params: { email } }
      );
      return response.data.name;
    } catch (error) {
      console.error("Error retrieving user name by email: ", error);
      return null;
    }
  }

  public static async getUserPageIdByEmail(
    email: string
  ): Promise<string | null> {
    try {
      // console.log("Sending email: " + email);
      const response = await axios.get(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getPblUserPageIdByEmail",
        { params: { email } }
      );
      return response.data.userPageId;
    } catch (error) {
      console.error("Error retrieving userPageId by email: ", error);
      return null;
    }
  }

  public static async getRoleByEmail(email: string): Promise<string | null> {
    try {
      // console.log("Sending email: " + email);
      const response = await axios.get(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getPblRoleByEmail",
        { params: { email } }
      );
      return response.data.role;
    } catch (error) {
      console.error("Error retrieving role by email:", error);
      return null;
    }
  }
}

export default Authenticator;
