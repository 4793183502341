import { useState } from "react";
import SearchBar from "../../../components/common/SearchBar";
import { useNavigate } from "react-router-dom";
import Authenticator from "../../../services/authenticator";
import { Carousel } from "../../../components/common/Carousel/carousel";
import { PathList } from "../../../components/common/Carousel/pathList";

export const SearchCard = () => {
  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
  };
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<any[]>([]);
  const navigate = useNavigate();

  var allResponses = [];
  for (let i = results.length - 1; i >= 0; i--) {
    // add to all respones the button compoement below
    allResponses.push(
      <button
        key={i}
        className={results[i]["metric_scores"]}
        onClick={async () => {
          navigate(
            (await Authenticator.ifSignedIn())
              ? results[i]["link"]
              : results[i]["metric_names"]
          );
        }}
      >
        <p className="text-white text-2xl md:text-6xl 2xl:mt-60 text-center">
          {results[i]["title"]}
        </p>
      </button>
    );
  }
  return (
    <section className="bg-white">
      <div
        className="container static mx-auto place-self-center xs:py-4 py-8
2xl:max-w-7xl xl:max-w-5xl lg:gap-8 xl:gap-0 lg:grid-cols-12 xs:px-3"
      >
        <h1 className="xl:max-w-8xl my-10 xs:my-5 font-black tracking-tight leading-none text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl text-pf-purple">
          Search for the role you’re interested in
        </h1>
        <SearchBar setQuery={setQuery} setResults={setResults} />
        {results.length > 0 ? (
          <div className="text-center text-pf-purple font-black tracking-tight leading-none py-2 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl">
            <p>Here are the results we found:</p>
            <div className="overflow-hidden">
              <div className="px-3 md:px-0">
                {/* edit formatting on notion */}
                {allResponses}
              </div>
            </div>
          </div>
        ) : query !== "" ? (
          <div className="text-center text-pf-purple font-black tracking-tight leading-none py-2 text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl">
            <p>Unfortunately a path for "{query}" does not currenty exist.</p>
            <p>
              We are aware of the demand and are working hard to deliver it as
              soon as we can
            </p>
            <hr className="my-4 h-0.5 mx-4 border-t-0 bg-pf-purple opacity-100 dark:opacity-50" />
            <p>
              Feel free to look through our currently available paths below:
            </p>
          </div>
        ) : (
          <></>
        )}
        {results.length <= 0 ? (
          <div>
            <div className="hidden md:block">
              <Carousel />
            </div>
            <div className="md:hidden">
              <PathList />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};
