import { Button, TextField, Autocomplete, Grid } from "@mui/material";
import { useState } from "react";
import { SearchBarContainer } from "./styles";
import { logSearchToDB, searchForRoadmap } from "../SearchExtWidget";

interface SearchBarProps {
  setQuery: (query: string) => void;
  setResults: (result: any[]) => void;
}

const SearchBar = ({ setQuery, setResults }: SearchBarProps) => {
  const [localQuery, setLocalQuery] = useState<string>("");

  const handleSubmission = async (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    // console.log("Query1: ", localQuery);
    await fetchSearchedRoadmaps(localQuery);
    setQuery(localQuery);
    // <SearchQueryCard query={localQuery} />
  };

  const fetchSearchedRoadmaps = async (query: string) => {
    if (query === "") {
      //console.log("empty query");
    } else {
      const roadmaps = await searchForRoadmap(query);
      // console.log("Roadmaps: ", roadmaps);
      logSearchToDB(query);
      setResults(roadmaps);
    }
  };

  return (
    <form className="flex items-center" onSubmit={handleSubmission}>
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="pl-3 md:pl-0 relative w-full">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-4 h-4 text-gray-500 dark:text-gray-400 ml-2 md:ml-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          id="search"
          className="bg-gray-50 border border-pf-purple text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 md:pl-10 p-2.5"
          placeholder="What are you looking for..."
          value={localQuery}
          onChange={(event) => setLocalQuery(event.target.value)}
          required
        />
      </div>
      <div className="pr-3 md:pr-0">
        <button
          type="submit"
          className="inline-flex font-black items-center py-2.5 px-3 md:px-7 ml-2 text-sm text-white bg-pf-purple rounded-lg border border-blue-700 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          // onChange={(event, newValue) => {
          //     if (typeof newValue === "string") {
          //       setLocalQuery(newValue);
          //     }
          // onClick={(event, newValue) => {
          //     if (typeof newValue === "string") {
          //       setLocalQuery(newValue);
          //     }
          //   }}
        >
          <svg
            aria-hidden="true"
            className="w-4 h-4 mr-2 -ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
          Search
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
