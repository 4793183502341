import { useNavigate } from "react-router-dom";
import Authenticator from "../../../services/authenticator";
import ReactGA from "react-ga4";
import axios from "axios";

export interface PathCardProps {
  bgImage: string;
  GAaction: string;
  url: string;
  previewUrl: string;
  title: string;
  timeTaken: string;
  resources: string;
  id: string;
}

export const PathNameToIds = {
  "management-consulting": 1,
  "data-science-with-python": 2,
  "ai-for-healthcare": 3,
  "search-engine-optimisation": 4,
  "ui-ux-design": 5,
};

export const addUserToPath = async (url: string) => {
  const pathName = url.split("/")[1];
  const pathId = PathNameToIds[pathName];
  const userId = await Authenticator.getCurrentUserId();
  const response = axios.post(
    "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/addUserToPath",
    {
      userId: userId,
      pathId: pathId,
    }
  );
};

export const PathCard = ({
  bgImage,
  GAaction,
  url,
  previewUrl,
  title,
  timeTaken,
  resources,
  id,
}: PathCardProps) => {
  const navigate = useNavigate();

  const handleClick = async () => {
    const isUserSignedIn = await Authenticator.ifSignedIn();
    if (isUserSignedIn) {
      addUserToPath(url);
    }

    ReactGA.event({
      action: GAaction,
      category: "search_cards",
    });

    navigate(isUserSignedIn ? url : previewUrl);
  };

  return (
    <div className="mr-[32%]">
      <button
        className={`mt-8 text-white font-black w-full h-full rounded-3xl bg-center bg-cover ${bgImage}`}
        style={{
          position: "absolute",
          height: "90%",
          width: "30%",
          transition: "transform 0.2s ease-in-out",
        }}
        onClick={handleClick}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = "scale(1.05)";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = "scale(1)";
        }}
      >
        <p className="text-white text-2xl md:text-4xl md:mt-52 2xl:mt-60">
          {title}
        </p>
        <hr className="w-40 h-1 mx-auto my-4 bg-white border-0 rounded md:my-6" />
        <p className="text-white font-black text-sm md:text-lg">{timeTaken}</p>
        <p className="text-white font-black text-sm md:text-lg">{resources}</p>
      </button>
    </div>
  );
};
