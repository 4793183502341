import React, { useEffect, useState } from "react";
import IframePage from "../../pages/Iframe";
import RootPage from "../../pages/root";
import Modal from "react-modal";
import { Widget } from "@typeform/embed-react";
import ApplicationForm from "../../components/ApplicationModal/applicationForm";

Modal.setAppElement("#root"); // replace with your root element id
const isSmallDevice = window.innerWidth <= 768;

const SummerCohort = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleApplyNow = () => {
    setIsModalOpen((prev) => !prev);
  };

  const customStyles = {
    content: {
      width: isSmallDevice ? "100%" : "60%",
      height: isSmallDevice ? "100%" : "90%",
      minHeight: isSmallDevice ? "45%" : "58%", // Set a minimum height to make sure the button is always visible
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <div>
      <div>
        <div>
          <IframePage pageURL="e72790c4a95c4b6b89f60615f6f99c79" />
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={customStyles} // customize styles as needed
          contentLabel="Application Modal"
        >
          {<ApplicationForm />}
        </Modal>
      </div>
      <div>
        <footer className="xs:static bg-pf-purple text-white w-full bottom-0 left-0 py-4 px-4 flex items-center justify-center">
          <button
            className="px-4 py-2 bg-white text-pf-purple font-semibold rounded-md shadow-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-pf-purple focus:ring-opacity-50"
            onClick={handleApplyNow}
          >
            Apply now!
          </button>
        </footer>
      </div>
    </div>
  );
};

export default SummerCohort;
