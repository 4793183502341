import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import TopicCard from "../../../pages/GeneratePaths/cards/topicCard";
import { Links } from "../../../pages/GeneratePaths/index";

interface WeekCardProps {
  line?: boolean;
  topics: Array<{ topic: string; subtopics: string[] }>;
  number: string;
  handleRegenerate: (title: string) => Promise<void>;
  linksMap: Links;
  handleTopicChange: (
    newTopics: Array<{ topic: string; subtopics: string[] }>
  ) => void;
  setCurrentLinksMap: React.Dispatch<React.SetStateAction<Links>>;
}

const WeekCard: React.FC<WeekCardProps> = ({
  line = true,
  topics,
  number,
  handleRegenerate,
  linksMap,
  handleTopicChange,
  setCurrentLinksMap,
}) => {
  const [collapse, setCollapse] = useState(false);
  const [currentTopics, setCurrentTopics] = useState(topics);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(currentTopics);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCurrentTopics(items);
    handleTopicChange(items); // Propagate the changes to the parent component
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className="flex mt-2 w-full">
        <div className="h-full flex flex-col items-center justify-center w-10">
          <div
            className={`bg-pf-purple text-white mt-2 rounded-full p-2 mb-4 h-10 w-10 flex items-center justify-center`}
          >
            {number}
          </div>
          {line ? (
            <div className=" bg-pf-purple h-full w-[2px] self-center"></div>
          ) : (
            <div className=" bg-white h-full w-[2px] self-center"></div>
          )}
        </div>
        <div className="flex flex-col ml-6 w-full rounded-xl border border-pf-purple p-4">
          <button
            onClick={() => {
              setCollapse(!collapse);
            }}
            className="flex justify-between items-center"
          >
            <span className="font-bold text-black text-xl">Week #{number}</span>
            <FontAwesomeIcon icon={collapse ? faChevronUp : faChevronDown} />
          </button>
          {collapse && (
            <Droppable droppableId="cards">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {currentTopics.map((topicObj, index) => (
                    <TopicCard
                      key={topicObj.topic}
                      topic={topicObj.topic}
                      subtopics={topicObj.subtopics}
                      handleRegenerate={handleRegenerate}
                      linksMap={linksMap}
                      index={index}
                      setCurrentLinksMap={setCurrentLinksMap}
                      currentLinksMap={linksMap}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </div>
      </div>
    </DragDropContext>
  );
};

export default WeekCard;
