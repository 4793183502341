import { useEffect, useRef, useState } from "react";
import { PathCard } from "./carouselCard";
import AIHealthCardBG from "../../../assets/shareAIHealthcareProjectBG.png";
import CodingPythonCardBG from "../../../assets/shareDataScienceProjectBG.png";
import ConsultingCardBG from "../../../assets/shareConsultingProjectBG.png";
import SEORoadmapBG from "../../../assets/shareSEOProjectBG.png";
import UIUXRoadmapBG from "../../../assets/shareUIUXProjectBG.png";

export const allPathCardsData = [
  {
    bgImage: "bg-[url('/src/assets/AIHealthcareRoadmapBG.png')]",
    GAaction: "healthcare",
    url: "/ai-for-healthcare/c1230bec-0dd6-4526-969a-3f92e132f6f9",
    previewUrl: "/ai-for-healthcare-preview",
    title: "AI for Healthcare",
    timeTaken: "3-4 months",
    resources: "10+ resources",
    id: "3",
  },
  {
    bgImage: "bg-[url('/src/assets/UIUXRoadmapBG.png')]",
    GAaction: "ui_ux",
    url: "/ui-ux-design/1f5bff43-8ca0-4dbc-bae6-e8adbdb0c012",
    previewUrl: "/ui-ux-design-preview",
    title: "UI / UX Design",
    timeTaken: "3-6 months",
    resources: "10+ resources",
    id: "5",
  },
  {
    bgImage: "bg-[url('/src/assets/CodingPythonRoadmapBG.png')]",
    GAaction: "coding",
    url: "/data-science-with-python/2c5872b1-052b-4f75-ae83-9c0ed2f9678f",
    previewUrl: "/data-science-with-python-preview",
    title: "Data Science with Python",
    timeTaken: "3-4 months",
    resources: "10+ resources",
    id: "2",
  },
  {
    bgImage: "bg-[url('/src/assets/SEORoadmapBG.png')]",
    GAaction: "seo",
    url: "/search-engine-optimisation/7e0017f0-cb5b-40c9-96b5-aa91b1f9384f",
    previewUrl: "/search-engine-optimisation-preview",
    title: "Search Engine Optimisation",
    timeTaken: "3-4 months",
    resources: "10+ resources",
    id: "4",
  },
  {
    bgImage: "bg-[url('/src/assets/consultingRoadmapBG.png')]",
    GAaction: "consulting",
    url: "/management-consulting/e6c7a639-df37-40a3-a6b0-48c79493cc43",
    previewUrl: "/management-consulting-preview",
    title: "Management Consulting",
    timeTaken: "1-2 months",
    resources: "10+ resources",
    id: "1",
  },
];

export const allPathCardsShareData = [
  {
    bgImage: AIHealthCardBG,
    GAaction: "healthcare",
    url: "/ai-for-healthcare/c1230bec-0dd6-4526-969a-3f92e132f6f9",
    previewUrl: "/ai-for-healthcare-preview",
    title: "AI for Healthcare",
    timeTaken: "3-4 months",
    resources: "10+ resources",
    id: "3",
  },
  {
    bgImage: UIUXRoadmapBG,
    GAaction: "ui_ux",
    url: "/ui-ux-design/1f5bff43-8ca0-4dbc-bae6-e8adbdb0c012",
    previewUrl: "/ui-ux-design-preview",
    title: "UI / UX Design",
    timeTaken: "3-6 months",
    resources: "10+ resources",
    id: "5",
  },
  {
    bgImage: CodingPythonCardBG,
    GAaction: "coding",
    url: "/data-science-with-python/2c5872b1-052b-4f75-ae83-9c0ed2f9678f",
    previewUrl: "/data-science-with-python-preview",
    title: "Data Science with Python",
    timeTaken: "3-4 months",
    resources: "10+ resources",
    id: "2",
  },
  {
    bgImage: SEORoadmapBG,
    GAaction: "seo",
    url: "/search-engine-optimisation/7e0017f0-cb5b-40c9-96b5-aa91b1f9384f",
    previewUrl: "/search-engine-optimisation-preview",
    title: "Search Engine Optimisation",
    timeTaken: "3-4 months",
    resources: "10+ resources",
    id: "4",
  },
  {
    bgImage: ConsultingCardBG,
    GAaction: "consulting",
    url: "/management-consulting/e6c7a639-df37-40a3-a6b0-48c79493cc43",
    previewUrl: "/management-consulting-preview",
    title: "Management Consulting",
    timeTaken: "1-2 months",
    resources: "10+ resources",
    id: "1",
  },
];

const generateAllPathCards = allPathCardsData.map((cardData) => (
  <PathCard
    bgImage={cardData.bgImage}
    GAaction={cardData.GAaction}
    url={cardData.url}
    previewUrl={cardData.previewUrl}
    title={cardData.title}
    timeTaken={cardData.timeTaken}
    resources={cardData.resources}
    id={cardData.id}
  />
));

export const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoScroll, setAutoScroll] = useState(true);
  const timeoutRef = useRef(null);
  const numOfRepeats = 10;
  const allPathCards = Array.from(
    { length: numOfRepeats },
    () => generateAllPathCards
  ).flatMap((x) => x);

  const handlePrevClick = () => {
    setAutoScroll(false);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 21 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setAutoScroll(false);
    setCurrentIndex((prevIndex) => (prevIndex === 21 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const handleAutoScroll = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 21 ? 0 : prevIndex + 1));
    };

    if (autoScroll) {
      timeoutRef.current = setTimeout(handleAutoScroll, 2500);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [autoScroll, currentIndex]);

  return (
    <div className="relative w-full h-[630px] md:h-[490px] lg:h-[640px]">
      <div className="flex items-center justify-center w-full h-full absolute top-0 left-0 overflow-hidden">
        <div
          className="flex w-full h-full transform duration-500 ease-in-out"
          style={{ transform: `translateX(${currentIndex * -33.33}%)` }}
        >
          {allPathCards}
        </div>
      </div>
      <div className="flex items-center justify-center absolute bottom-0 left-0 w-full mb-10">
        <button
          className="p-2 m-4 avenir-medium rounded-full bg-white shadow-lg hover:shadow-xl"
          onClick={handlePrevClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-[#913B93]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          className="p-2 m-4 rounded-full bg-white shadow-lg hover:shadow-xl"
          onClick={handleNextClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-[#913B93]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
