import React, { useState } from "react";
import PathFinderButton from "../../../components/common/PathFinderButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import TextArea from "../../../components/common/TextArea";
import { Skeleton } from "@mui/material";
import { Activity } from "..";

interface ActivityOptionCardProps {
  title: string;
  description: string;
  implementation: string[];
  handleIterateSingleActivity: (
    iterateActivityIndex: number,
    changesRequested: string
  ) => Promise<Activity>;
  activitiesIndex: number;
}

const ActivityOptionCard: React.FC<ActivityOptionCardProps> = ({
  title,
  description,
  implementation,
  handleIterateSingleActivity,
  activitiesIndex,
}) => {
  const [command, setCommand] = useState("");
  const [collapse, setCollapse] = useState(false); // New state variable for collapse
  const [loading, setLoading] = useState(false); // New state variable for collapse
  const [currTitle, setCurrTitle] = useState(title);
  const [currDescription, setCurrDescription] = useState(description);
  const [currImplementation, setCurrImplementation] = useState(implementation);

  return (
    <div className="flex flex-col space-y-4 bg-pf-purple bg-opacity-10 rounded-xl border border-pf-purple p-5 justify-start items-start">
      {loading ? (
        <>
          <Skeleton variant="text" width="100%" height={50} />
          <div className="w-full">
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="100%" height={20} />
          </div>
          <Skeleton variant="text" width="50%" height={30} />
          <div className="w-full">
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="100%" height={20} />
          </div>
        </>
      ) : (
        <>
          <div className="flex w-full flex-col justify-center items-center">
            <button
              onClick={() => setCollapse(!collapse)}
              className="focus:outline-none w-full text-left"
            >
              <div className="flex w-full justify-between items-center">
                <h4>{title}</h4>
                <FontAwesomeIcon
                  icon={collapse ? faChevronUp : faChevronDown}
                />
              </div>
            </button>
          </div>

          {collapse && (
            <>
              <div className="flex justify-center items-center text-gray-900 text-base font-normal font-['Avenir'] leading-normal">
                {description}
              </div>
              <div className="flex flex-col justify-start items-start space-y-2">
                <h6>How to implement it</h6>
                <div className="flex flex-col justify-center items-start">
                  <ul className="list-disc list-inside">
                    {implementation.map((item, key) => (
                      <li key={key}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="flex w-full gap-4 items-center">
                <TextArea
                  placeholder="Iterate on the activity above"
                  h="2.25rem"
                  value={command}
                  onChange={(e) => setCommand(e.target.value)}
                />
                <PathFinderButton
                  onClick={async () => {
                    setCommand("");
                    setLoading(true);
                    const newActivity: Activity =
                      await handleIterateSingleActivity(
                        activitiesIndex,
                        command
                      );
                    setCurrTitle(newActivity.title);
                    setCurrDescription(newActivity.description);
                    setCurrImplementation(newActivity.implementation);
                    setLoading(false);
                  }}
                  loading={loading}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </PathFinderButton>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ActivityOptionCard;
