import { useState, useEffect } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useNavigate } from "react-router-dom";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";

import { Label, TextInput, Button, Select } from "flowbite-react";
import TextLink from "../../components/common/TextLink";
import AccountsTemplate from "../../components/AccountsTemplate";

import { useForm, SubmitHandler, FieldError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PathFinderButton from "../../components/common/PathFinderButton";
import axios from "axios";
import Authenticator from "../../services/authenticator";
import TextArea from "../../components/common/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import Header from "../../components/Header/header";
import { useUser } from "../../contexts/UserContext";
import amplitudeInstance, {
  logEvent,
  generateUniqueUserId,
} from "../../services/amplitudeService";

interface SignUpUserDetails {
  name: string;
  email: string;
  password: string;
  role: string;
  awsId: string;
}

const CreateAccount = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedButton, setSelectedButton] = useState<string | null>(
    "Learner"
  );
  const [roleError, setRoleError] = useState(false);
  const { setUser } = useUser();

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password length should be at least 6 characters"),
    // .min(8, "Password length should be at least 8 characters"),
    // .test(
    //   "containsSpecialCharacter",
    //   "Password needs to contain a special character",
    //   (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value)
    // )
    // .test("containsNumber", "Password needs to contain a number", (value) =>
    //   /[0-9]/.test(value)
    // )
    // .test(
    //   "containsUppercase",
    //   "Password needs to contain an uppercase letter",
    //   (value) => /[A-Z]/.test(value)
    // )
    // .test(
    //   "containsLowercase",
    //   "Password needs to contain a lowercase letter",
    //   (value) => /[a-z]/.test(value)
    // ),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<SignUpUserDetails>({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<SignUpUserDetails> = async (data) => {
    if (!selectedButton) {
      setRoleError(true);
      console.error("Please select a role before submitting.");
      return;
    }
    setRoleError(false);
    try {
      setLoading(true);
      try {
        const awsResponse = await Authenticator.signUp(
          data.email,
          data.password
        );
        data.awsId = awsResponse.userSub;
        await Authenticator.ifSignedIn();
      } catch (error) {
        setLoading(false);
        console.error("Error logging into AWS: ", error);
      }

      // no await needed so it can run in the background
      await axios.post(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/addPblUserToDB",
        {
          role: selectedButton,
          name: data.name,
          email: data.email,
          awsId: data.awsId,
        }
      );

      localStorage.setItem("userName", data.name);
      localStorage.setItem("userEmail", data.email);
      localStorage.setItem("userRole", selectedButton);
      setUser({ email: data.email, name: data.name, role: selectedButton });

      // alert("Sign up successful! Click 'Ok' to go to the home page.");
      navigate("/");

      setLoading(false);

      const uniqueUserId = generateUniqueUserId(data.email);
      amplitudeInstance.setUserId(uniqueUserId.toString());
      logEvent("New User", {
        userId: uniqueUserId.toString(),
      });
    } catch (error) {
      setLoading(false);
      console.error("Error sending to pbl prisma: ", error);
    }
  };

  // Encapsulates the text field component
  function formTextField(
    label: any,
    labelString: string,
    fieldPlaceholder: string,
    errorHit: FieldError,
    errorMessage: string,
    passwordField: boolean = false
  ) {
    return (
      <div>
        <div className="my-2 block">
          <Label htmlFor={label} value={labelString} />
        </div>
        {passwordField ? (
          <>
            <div className="flex items-center">
              <TextInput
                key={label}
                defaultValue=""
                placeholder={fieldPlaceholder}
                type={showPassword ? "text" : "password"}
                {...register(label)}
                className="w-full border border-pf-navy rounded-lg flex-shrink-0"
              />
              <span
                className="cursor-pointer relative right-10"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityOffSharpIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <VisibilitySharpIcon className="h-5 w-5 text-gray-500" />
                )}
              </span>
            </div>
          </>
        ) : (
          <TextInput
            key={label}
            defaultValue=""
            placeholder={fieldPlaceholder}
            type="text"
            {...register(label)}
            className="border border-pf-navy rounded-lg"
          />
        )}
        {errorHit && (
          <div
            className="bg-[#F4EBF4] border-pf-purple text-black px-4 py-3 rounded-lg"
            role="alert"
          >
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
      </div>
    );
  }

  function basicInfoPage() {
    return (
      <div className="flex flex-col justify-start items-center h-screen">
        <div className="w-full px-16">
          <h2 className="text-4xl font-bold text-center mb-12">
            Join PathFinder as:
          </h2>
          <div className="flex flex-col">
            <div className="flex px-[4rem] mb-12">
              {["Learner", "Teacher", "Parent"].map((option, index, array) => (
                <button
                  type="button"
                  key={option}
                  className={`flex-grow px-4 py-2 border-2 border-pf-navy ${
                    selectedButton === option
                      ? "bg-pf-navy text-white"
                      : "bg-white text-pf-navy"
                  } ${index === 0 ? "rounded-l-md" : ""} ${
                    index === array.length - 1 ? "rounded-r-md" : ""
                  } ${
                    index !== 0 && index !== array.length - 1
                      ? "border-l-0 border-r-0"
                      : ""
                  }`}
                  onClick={() => {
                    setSelectedButton(option);
                    setRoleError(false);
                  }}
                >
                  <h5
                    className={`${
                      selectedButton === option ? "font-bold" : ""
                    }`}
                  >
                    {option}
                  </h5>
                </button>
              ))}
            </div>
            {roleError && (
              <div
                className="bg-[#F4EBF4] border-pf-purple text-black px-4 py-3 rounded-lg"
                role="alert"
              >
                <span className="block sm:inline">Please select a role</span>
              </div>
            )}
          </div>
          {/* <div className="flex flex-col w-full space-y-8"> */}
          {/* <PathFinderButton
              color="pf-navy"
              outlined={true}
              onClick={() => {}}
            >
              <div className="flex items-center justify-center px-4 py-2 w-full">
                <FontAwesomeIcon icon={faHashtag} size="lg" className="mr-2" />
                <h5 className="flex-grow text-center">Enter Class Code</h5>
              </div>
            </PathFinderButton> */}
          {/* <PathFinderButton
              color="pf-navy"
              outlined={true}
              onClick={() => {}}
            >
              <div className="flex items-center justify-center px-4 py-2 w-full">
                <FontAwesomeIcon icon={faGoogle} size="lg" className="mr-2" />
                <h5 className="flex-grow text-center">Sign up with Google</h5>
              </div>
            </PathFinderButton> */}
          {/* </div> */}
          <div className="flex mt-8 mb-6 justify-between  items-center">
            <hr className="border-t flex-grow border-pf-dark-grey" />
            {/* <p className="text-center mx-2 text-pf-dark-grey">or</p> */}
            <hr className="border-t flex-grow border-pf-dark-grey" />
          </div>
          <div>
            <h4 className="text-center">Sign up with your Email below</h4>
          </div>
          <div className="space-y-4">
            {formTextField(
              "name",
              "Your name",
              "Name",
              errors.name,
              errors.name?.message
            )}
            {formTextField(
              "email",
              "Your email",
              "Email",
              errors.email,
              errors.email?.message
            )}
            {formTextField(
              "password",
              "Your password",
              "Password",
              errors.password,
              errors.password?.message,
              true
            )}
          </div>

          <div className="flex justify-between">
            <div className="flex space-x-4 items-center mt-4">
              <TextLink text="Already have an account? Login" link="/login" />
            </div>

            <div className="mt-6">
              <PathFinderButton
                type="submit"
                color="pf-navy"
                onClick={handleSubmit(onSubmit)}
                loading={loading}
              >
                Sign Up
              </PathFinderButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Gets the correct form page to display based on the current page number
  // When you click next, the fields are validated and if they are valid, we move on to the next page
  // Clicking back takes you to the previous page without validating the current page's fields
  // On the final screen, complete calls the onSubmit function
  function formComponent() {
    return (
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {basicInfoPage()}
        <div className="w-full"></div>
      </form>
    );
  }

  return (
    <div className="flex flex-col h-screen">
      <div className="w-full px-6 mx-auto">
        <Header active="home" />
      </div>
      <div className="w-full h-full my-[-1rem] bg-pf-purple">
        <AccountsTemplate
          bg_img="bg-pf-navy bg-contain bg-cover bg-center bg-no-repeat
                  bg-[url('/src/assets/onboardingDiagram.png')]"
          title=""
          subtitle=""
          form_component={formComponent()}
        ></AccountsTemplate>
      </div>
    </div>
  );
};

export default CreateAccount;
