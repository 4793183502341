import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PathFinderButton from "../../../components/common/PathFinderButton";
import OverviewPage from "./OverviewPage";

interface SideMenuProps {
  showChats: boolean;
  setShowChats: (show: boolean) => void;
  notionLink: string;
  userChatsId: string;
  setThreadId: (threadId: string) => void;
  setParentId: (parentId: string) => void;
  setShowOverview: (show: boolean) => void;
  calculatedHeight2: string;
}

const SideMenu: React.FC<SideMenuProps> = ({
  showChats,
  setShowChats,
  notionLink,
  userChatsId,
  setThreadId,
  setParentId,
  setShowOverview,
  calculatedHeight2,
}) => {
  return (
    <div
      className={`bg-pf-grey dark:bg-white dark:bg-opacity-10 min-w-[300px] w-[25vw] max-w-[400px] ${calculatedHeight2} overflow-y-scroll mt-[-10px] ml-[-4rem] pl-[2rem] mr-6 my-4 border-r-2 border-pf-purple dark:border-pf-green relative`}
    >
      <div className="absolute top-[1rem] bg-pf-grey dark:bg-white dark:bg-opacity-0 w-[70%] py-[2px] left-[3rem] pt-2 mb-[-1rem] ml-2 mr-[-4]">
        <h3 className="font-bold text-black dark:text-white">Your builds:</h3>
      </div>
      <div className="absolute top-[1rem] right-[1rem] m-2 ">
        <PathFinderButton
          outlined={true}
          onClick={() => setShowChats(!showChats)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </PathFinderButton>
      </div>
      <OverviewPage
        url={notionLink}
        parentPageId={userChatsId}
        setThreadId={setThreadId}
        setParentId={setParentId}
        setShowOverview={setShowOverview}
      />
    </div>
  );
};

export default SideMenu;
