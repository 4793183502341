import RootPage from "../root";
import DashboardHeader, {
  DashboardHeaderProps,
} from "../../components/DashboardHeader";
import CurrentPathCard from "../../components/common/CurrentPathCard";
import ProjectCard from "../../components/common/ProjectCard";
import ExplorePathsCard from "../../components/common/ExplorePathsCard";
import AddProjectCard from "../../components/common/AddProjectCard";
import ModalRoot from "../../components/ModalRoot";
import { useEffect, useState } from "react";
import Authenticator from "../../services/authenticator";
import axios from "axios";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import AllUserProjectCards from "../../components/common/AllUserProjectCards";
import AllUserPathCards from "../../components/common/AllUserPathCards";
interface DashboardPathInfo {
  currentPageId: string;
  progress: number;
  Path: {
    id: string;
    title: string;
    numOfPages: number;
    numOfProjects: number;
  };
}

const pathCardBGUrls = {
  "Artificial Intelligence for Healthcare":
    "bg-[url('/src/assets/AIHealthcareRoadmapBG.png')]",
  "UI / UX Design": "bg-[url('/src/assets/UIUXRoadmapBG.png')]",
  "Data Science with Python":
    "bg-[url('/src/assets/CodingPythonRoadmapBG.png')]",
  "Search Engine Optimisation (SEO)":
    "bg-[url('/src/assets/SEORoadmapBG.png')]",
  "Management Consulting": "bg-[url('/src/assets/consultingRoadmapBG.png')]",
};

export interface DashboardProjectInfo {
  title: string;
  description: string;
  fileStorageLink: string;
  // showOnPortfolio?: boolean;
  // pathId?: number;
  // isPortfolio?: boolean;
}

const Dashboard = () => {
  const [myPaths, setMyPaths] = useState<DashboardPathInfo[]>([]);
  const [myProjects, setMyProjects] = useState<DashboardProjectInfo[]>([]);
  const [headerProps, setHeaderProps] = useState<DashboardHeaderProps>();
  const [pathsLoading, setPathsLoading] = useState<boolean>(true);
  const [projectsLoading, setProjectsLoading] = useState<boolean>(true);

  const getDashboardPathInfo = async () => {
    const userId = await Authenticator.getCurrentUserId();
    const responsePath = await axios.post(
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getUserPaths",
      {
        userId: userId,
      }
    );
    if (responsePath) {
      setMyPaths(responsePath.data);

      const paths = responsePath.data;
      if (paths.length > 0) {
        // sort paths by progress
        paths.sort((a, b) => (a.progress < b.progress ? 1 : -1));
        setHeaderProps({
          pathName: paths[0].Path.title,
          progress: paths[0].progress,
        });
      }
    }
    setPathsLoading(false);
  };

  const getDashboardProjectInfo = async () => {
    const userId = await Authenticator.getCurrentUserId();
    const response = await axios.post(
      // "http://127.0.0.1:8081/thepathfinderprojectbackendapi/us-central1/getAllUserProjects",
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getAllUserProjects",
      {
        userId: userId,
      }
    );
    if (response) {
      setMyProjects(response.data);
    }
    setProjectsLoading(false);
  };

  useEffect(() => {
    getDashboardPathInfo();
    getDashboardProjectInfo();
  }, []);

  return (
    <RootPage header="home">
      <div>
        {myPaths.length !== 0 && (
          <DashboardHeader
            pathName={headerProps.pathName}
            progress={headerProps.progress}
          />
        )}
        <div className="mb-20">
          <div className="m-2 md:m-10 px-4">
            <h2 className="text-3xl font-bold mb-4 mt-8 md:mt-0">Your Paths</h2>
            <AllUserPathCards isPortfolio={false} />
            <h2 className="text-3xl font-bold my-4 mt-12">Your Projects</h2>
            <AllUserProjectCards isPortfolio={false} />
          </div>
          <ModalRoot />
        </div>
        <ModalRoot />
      </div>
    </RootPage>
  );
};

export default Dashboard;
