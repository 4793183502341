import { ExtendedRecordMap } from "notion-types";
import { useState } from "react";
import "react-notion-x/src/styles.css";
import { CircularProgress } from "@mui/material";
import PathFinderButton from "../../../components/common/PathFinderButton";
import ReactPlayer from "react-player";
import LinkPreview from "../../../components/LinkPreview";
import { Checkbox } from "flowbite-react";
import OptionResourceButton from "./OptionResourceButton";
import axios from "axios";
import { getPageTitle } from "notion-utils";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NotionPageProps {
  teacherOverride: boolean;
  recordMap: ExtendedRecordMap;
  pageId: string;
  pageTitle?: string;
  setThreadId?: (threadId: string) => void;
  setParentId?: React.Dispatch<React.SetStateAction<string>>;
  setShowOverview?: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotionPage = ({
  teacherOverride,
  recordMap,
  pageId,
  pageTitle,
  setThreadId,
  setParentId,
  setShowOverview,
}: NotionPageProps) => {
  const [currRecordMap, setCurrRecordMap] = useState<ExtendedRecordMap | null>(
    recordMap
  );
  const [currPageTitle, setCurrPageTitle] = useState<string>(pageTitle);

  if (!currRecordMap) {
    return (
      <div
        className="h-full w-full"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const fetchNotionPage = async (newPageId: string) => {
    let response: any = null;
    try {
      response = await axios.get(
        `https://pathfinder-notion-web-content.vercel.app/api/${newPageId}`
      );
    } catch (error) {
      //   navigate("/error");
      return;
    }

    if (response.data.error) {
      console.error("Error:", response.data.error.message);
      //   navigate("/error");
    } else {
      setCurrRecordMap(response.data.recordMap);
      const pTitle = getPageTitle(response.data.recordMap);
      setCurrPageTitle(pTitle);
    }
  };

  const handlePageClick = (blockId: string) => {
    const newPageId = currRecordMap.block[blockId].value.id;
    fetchNotionPage(newPageId);
  };

  async function incrementPreviousChatsClicked() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/incrementPreviousChatsClick",
          { email: userEmail }
        );
      }
    } catch (error) {
      console.error("Error incrementing timesPrompted:", error);
    }
  }

  let foundExtraResources = false;
  let displayIndexes = false;
  let pageIndex = 1;

  return (
    <>
      <div
        className={`flex flex-col ${
          teacherOverride ? "pl-1" : "p-6"
        } space-y-6 w-full`}
      >
        {Object.keys(currRecordMap.block).map((blockId, index) => {
          if (foundExtraResources) {
            return null;
          }
          const blockType = currRecordMap.block[blockId].value.type;
          const blockText: string =
            currRecordMap.block[blockId].value.properties?.title?.[0][0];
          // console.log(
          //   "blockType",
          //   blockType,
          //   "blockText",
          //   blockText?.slice(0, 5),
          //   "blockContent",
          //   currRecordMap.block[blockId]
          // );

          if (blockText === "Extra resources") {
            foundExtraResources = true;

            // Get all blocks after the current one
            const extraResourcesBlocks = Object.keys(currRecordMap.block).slice(
              index + 1
            );

            // Filter blocks by type and group them into groups of 3
            const blockTypes = [
              "header",
              "sub_header",
              "sub_sub_header",
              "bookmark",
              "video",
              "text",
            ];

            const groupedBlocks = [];

            console.log("extra res: ", extraResourcesBlocks);
            for (let i = 0; i < extraResourcesBlocks.length; i += 3) {
              const group = extraResourcesBlocks.slice(i, i + 4);
              if (
                group.every((blockId) =>
                  blockTypes.includes(currRecordMap.block[blockId].value.type)
                )
              ) {
                groupedBlocks.push(group);
              }
            }

            return (
              <>
                <h4 className="font-bold text-black dark:text-white">
                  {blockText}
                </h4>
                <div className="w-full grid grid-cols-2 gap-2">
                  {groupedBlocks.map((group, index) => {
                    const title =
                      currRecordMap.block[group[0]].value.properties
                        ?.title?.[0][0];
                    let url;
                    if (currRecordMap.block[group[1]].value.type === "video") {
                      url =
                        currRecordMap.block[group[1]].value.properties
                          ?.source?.[0][0];
                    } else if (
                      currRecordMap.block[group[1]].value.type === "bookmark"
                    ) {
                      url =
                        currRecordMap.block[group[1]].value.properties
                          ?.link?.[0][0];
                    }
                    const description =
                      currRecordMap.block[group[2]].value.properties
                        ?.title?.[0][0];
                    // console.log(
                    //   "DISPLAYING EXTRAS BLOCKS: ",
                    //   title,
                    //   url,
                    //   description
                    // );

                    return (
                      <OptionResourceButton
                        key={index}
                        resource={{
                          title,
                          url,
                          description,
                        }}
                        selectedResource={{
                          title: "",
                          url: "",
                          description: "",
                        }}
                        onResourceSelected={() => {}}
                      />
                    );
                  })}
                </div>
              </>
            );
          } else {
            if (blockType === "page") {
              if (currPageTitle.includes("@")) {
                if (blockText.includes("@")) {
                  return <div className="pt-8"></div>;
                } else {
                  return (
                    <div className="hover:bg-white">
                      <button
                        className="hover:bg-pf-purple hover:font-bold hover:opacity-40 py-4 my-[-12px] border-b border-pf-dark-grey text-left w-full pl-[4px] pr-[1rem] hover:text-white text-black dark:text-white"
                        onClick={() => {
                          setThreadId(
                            blockText.includes("thread")
                              ? blockText.split("(")[1].replace(")", "")
                              : blockText
                          );
                          setShowOverview(true);
                          setParentId(blockId);
                          incrementPreviousChatsClicked();
                        }}
                      >
                        {blockText.includes("thread")
                          ? blockText.split("(")[0].trim().replace(/^I am interested in /, "")
                          : blockText.replace(/^I am interested in /, "")}
                      </button>
                    </div>
                  );
                }
              } else {
                if (!blockText.includes("@")) {
                  return blockText === currPageTitle &&
                    blockText.includes("thread") ? (
                    ((displayIndexes = true),
                    (
                      <>
                        {!teacherOverride ? (
                          <>
                            <h1 className="font-bold text-center text-black dark:text-white">
                              Your Learning Pages
                            </h1>
                            <p className="font-bold text-center text-black dark:text-white">
                              Below is the different pages you've made so far!
                              They are each steps on your learning path. Feel
                              free to always revisit them.
                            </p>
                          </>
                        ) : (
                          <h2 className="font-bold text-black dark:text-white">
                            Learning Pages
                          </h2>
                        )}
                      </>
                    ))
                  ) : blockText === currPageTitle ? (
                    <> </>
                  ) : blockText.includes("thread") ? (
                    <div className="">
                      <PathFinderButton
                        outlined={true}
                        onClick={() => {
                          handlePageClick(blockId);
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} color="pf-purple" />
                      </PathFinderButton>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-2">
                      {displayIndexes && (
                        <h5 className="mr-2 text-black dark:text-white">
                          {pageIndex++})
                        </h5>
                      )}
                      <PathFinderButton
                        outlined={true}
                        onClick={() => handlePageClick(blockId)}
                      >
                        {blockText}
                      </PathFinderButton>
                    </div>
                  );
                }
              }
            } else if (blockType === "header") {
              return (
                <h2 className="font-bold text-black dark:text-white">
                  {blockText}
                </h2>
              );
            } else if (blockType === "sub_header") {
              return (
                <h3 className="font-bold text-black dark:text-white">
                  {blockText}
                </h3>
              );
            } else if (blockType === "sub_sub_header") {
              return (
                <h4 className="font-bold text-black dark:text-white">
                  {blockText}
                </h4>
              );
            } else if (blockType === "text") {
              return <p className="text-black dark:text-white">{blockText}</p>;
            } else if (blockType === "video") {
              const resourceUrl =
                currRecordMap.block[blockId].value.properties?.source?.[0][0];
              // console.log("Block YouTube Video: ", resourceUrl);
              return (
                <div className="flex items-center text-center justify-center">
                  {ReactPlayer.canPlay(resourceUrl) ? (
                    <ReactPlayer
                      url={resourceUrl}
                      controls={true}
                      config={{
                        youtube: {
                          playerVars: {
                            controls: 1,
                          },
                        },
                      }}
                    />
                  ) : (
                    <div className="flex items-center text-center justify-center">
                      <LinkPreview url={resourceUrl} size="small" />
                    </div>
                  )}
                </div>
              );
            } else if (blockType === "bookmark") {
              const resourceUrl =
                currRecordMap.block[blockId].value.properties?.link?.[0][0];
              // console.log("Block Bookmark: ", resourceUrl);

              return (
                <div className="flex items-center text-center justify-center">
                  {ReactPlayer.canPlay(resourceUrl) ? (
                    <ReactPlayer
                      url={resourceUrl}
                      controls={true}
                      config={{
                        youtube: {
                          playerVars: {
                            controls: 1,
                          },
                        },
                      }}
                    />
                  ) : (
                    <div className="flex items-center text-center justify-center">
                      <LinkPreview url={resourceUrl} size="small" />
                    </div>
                  )}
                </div>
              );
            } else if (blockType === "to_do") {
              const index = currRecordMap.block[blockId].value.id;
              return (
                <div key={index} className="flex items-center space-x-3 -my-6">
                  <div className="border h-7 w-7 text-center items-center border-pf-purple dark:border-pf-green rounded-lg">
                    <Checkbox
                      id={`objective${index}`}
                      name={`objective${index}`}
                      className="form-checkbox h-5 w-5 bg-[#F4EBF4] dark:bg-pf-grey text-pf-complete border-2 "
                    />
                  </div>
                  <label
                    htmlFor={`objective${index}`}
                    className="ml-2 text-gray-700 dark:text-pf-grey"
                  >
                    {blockText}
                  </label>
                </div>
              );
            }
          }
        })}
      </div>
    </>
  );
};

export default NotionPage;
