import { TextInput } from "flowbite-react";
import React, { forwardRef, useState, useEffect, RefObject } from "react";
import TextArea from "../../../components/common/TextArea";
import CircularProgress from "@mui/material/CircularProgress";
import PathFinderButton from "../../../components/common/PathFinderButton";

interface AboutActivityCardProps {
  onGenerateActivities: (
    description: string,
    students: string,
    classroomUpdate: string,
    extraInfo: string
  ) => Promise<void>;
}

const AboutActivityCard = forwardRef<HTMLDivElement, AboutActivityCardProps>(
  (props, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [students, setStudents] = useState("");
    const [classroomUpdate, setClassroomUpdate] = useState("");
    const [extraInfo, setExtraInfo] = useState("");

    const handleGenerateActivities = async () => {
      setIsLoading(true);
      try {
        if (extraInfo === "") {
          await props.onGenerateActivities(
            description,
            students,
            classroomUpdate,
            "n/a"
          );
        } else {
          await props.onGenerateActivities(
            description,
            students,
            classroomUpdate,
            extraInfo
          );
        }
      } catch (error) {
        console.error("Error in handleGenerateActivities:", error);
        console.error("Error message:", error.message);
        if (error.response) {
          console.error("Server response:", error.response.data);
        }
        console.error("Error stack trace:", error.stack);
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (isLoading) {
        setTimeout(() => {
          setIsLoading(false);
          if (typeof ref === "object" && ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 180000);
      }
    }, [isLoading, ref]);

    return (
      <div ref={ref as RefObject<HTMLDivElement>}>
        <div className="grid grid-cols-3 mt-8">
          <div className="mt-4 col-span-1">
            <h1 className="text-4xl font-bold">About your activity</h1>
          </div>
          <div className="flex flex-col col-span-2 ml-12">
            <label className="font-semibold">
              What’d you want to design an activity for?
            </label>
            <label className="text-pf-dark-grey text-sm mb-2">
              (Goal, subject matter, type of activity etc.)
            </label>
            <TextArea
              h="5rem"
              placeholder="Write text here..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <label className="font-semibold mt-6">
              Who’s in your classroom?
            </label>
            <label className="text-pf-dark-grey text-sm mb-2">
              (Developmental ages, number of students and classroom dynamics to
              be aware of etc.)
            </label>
            <TextArea
              h="5rem"
              placeholder="Write text here..."
              value={students}
              onChange={(e) => setStudents(e.target.value)}
            />
            <label className="font-semibold mt-6">
              What’s going on in your classroom right now?
            </label>
            <label className="text-pf-dark-grey text-sm mb-2">
              (What they have been doing, what the students know etc.)
            </label>
            <TextArea
              h="5rem"
              placeholder="Write text here..."
              value={classroomUpdate}
              onChange={(e) => setClassroomUpdate(e.target.value)}
            />{" "}
            <label className="font-semibold mt-6 mb-2">
              Anything else you want me to know?
            </label>
            <TextArea
              h="5rem"
              placeholder="Write text here..."
              value={extraInfo}
              onChange={(e) => setExtraInfo(e.target.value)}
            />
            {/* <label className="font-semibold mt-6">
              How many weeks do you want the path to be?
            </label>
            <div className="mt-2">
              {Array.from({ length: 8 }, (_, i) => i + 1).map((number) => (
                <button
                  key={number}
                  className={`border p-2 px-4 mx-1 rounded-md ${
                    selectedWeeks === number
                      ? "text-white bg-pf-purple border-pf-purple"
                      : "border-pf-dark-grey bg-pf-grey"
                  }`}
                  onClick={() => setSelectedWeeks(number)}
                >
                  {number}
                </button>
              ))}
            </div> */}
          </div>
        </div>
        <div className="flex justify-end my-8">
          <PathFinderButton
            loading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await handleGenerateActivities();
              setIsLoading(false);
            }}
          >
            Next
          </PathFinderButton>
        </div>
        <div style={{ borderTop: "1px solid #D1D5DB" }}></div>
      </div>
    );
  }
);

export default AboutActivityCard;
