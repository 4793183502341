import React, { useRef, useState, useEffect } from "react";
import { Label, TextInput } from "flowbite-react";
import PathFinderButton from "../../../../components/common/PathFinderButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Authenticator from "../../../../services/authenticator";
import axios from "axios";

interface CreateAccountFormProps {
  idx: number;
  studentAccountDetails: { name: string; email: string; password: string }[];
  setStudentAccountDetails: React.Dispatch<
    React.SetStateAction<{ name: string; email: string; password: string }[]>
  >;
  numOfCreate: number;
  setNumOfCreate: React.Dispatch<React.SetStateAction<number>>;
  creationStatus: "idle" | "loading" | "completed"; // New prop
}

const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
  idx,
  studentAccountDetails,
  setStudentAccountDetails,
  numOfCreate,
  setNumOfCreate,
  creationStatus, // Use the new prop
}) => {
  useEffect(() => {
    if (creationStatus === "completed") {
      // console.log(`Student account creation for index ${idx} completed.`);
      // Additional actions upon completion can be added here
    }
  }, [creationStatus, idx]);

  let icon;
  switch (creationStatus) {
    case "loading":
      icon = (
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-6 h-6 text-pf-purple animate-spin dark:text-gray-600 fill-pf-grey"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      );
      break;
    case "completed":
      icon = <FontAwesomeIcon icon={faCheck} className="text-pf-complete" />;
      break;
    default:
      icon = <FontAwesomeIcon icon={faTimes} className="text-pf-red" />;
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const updatedAccounts = [...studentAccountDetails];
    updatedAccounts[idx] = { ...updatedAccounts[idx], [field]: e.target.value };
    setStudentAccountDetails(updatedAccounts);
  };

  return (
    <div className="flex w-full items-center justify-center gap-4">
      <div className="w-full">
        <Label htmlFor={`student_name_${idx}`}>Name</Label>
        <TextInput
          id={`student_name_${idx}`}
          type="text"
          placeholder="Name"
          value={studentAccountDetails[idx]?.name || ""}
          onChange={(e) => handleInputChange(e, "name")}
          className="mt-1 block w-full border border-pf-purple rounded-lg"
        />
      </div>
      <div className="w-full">
        <Label htmlFor={`student_email_${idx}`}>Email</Label>
        <TextInput
          id={`student_email_${idx}`}
          type="email"
          placeholder="Email"
          value={studentAccountDetails[idx]?.email || ""}
          onChange={(e) => handleInputChange(e, "email")}
          className="mt-1 block w-full border border-pf-purple rounded-lg"
        />
      </div>
      <div className="w-full">
        <Label htmlFor={`student_password_${idx}`}>Password</Label>
        <div className="flex gap-3">
          <TextInput
            id={`student_password_${idx}`}
            type="password"
            placeholder="Password"
            value={studentAccountDetails[idx]?.password || ""}
            onChange={(e) => handleInputChange(e, "password")}
            className="mt-1 block w-full border border-pf-purple rounded-lg"
          />
          <button
            type="button"
            onClick={() => {
              const filteredAccounts = studentAccountDetails.filter(
                (_, accountIdx) => accountIdx !== idx
              );
              setStudentAccountDetails(filteredAccounts);
              setNumOfCreate(numOfCreate - 1);
            }}
            aria-label="Remove create account field"
          >
            {icon}
          </button>
        </div>
      </div>
    </div>
  );
};

interface LinkStudentFormProps {
  idx: number;
  studentEmails: string[];
  setStudentEmails: React.Dispatch<React.SetStateAction<string[]>>;
  numOfAdd: number;
  setNumOfAdd: React.Dispatch<React.SetStateAction<number>>;
  submissionStatus: "idle" | "loading" | "completed"; // New prop
}

const LinkStudentForm: React.FC<LinkStudentFormProps> = ({
  idx,
  studentEmails,
  setStudentEmails,
  numOfAdd,
  setNumOfAdd,
  submissionStatus,
}) => {
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedEmails = [...studentEmails];
    updatedEmails[idx] = e.target.value;
    setStudentEmails(updatedEmails);
  };

  // Inside LinkStudentForm component, based on submissionStatus prop
  let icon;
  switch (submissionStatus) {
    case "loading":
      icon = (
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-6 h-6 text-pf-purple animate-spin dark:text-gray-600 fill-pf-grey"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      );
      break;
    case "completed":
      icon = <FontAwesomeIcon icon={faCheck} className="text-pf-complete" />;
      break;
    default:
      icon = <FontAwesomeIcon icon={faTimes} className="text-pf-red" />;
  }

  return (
    <div className="flex w-full gap-4">
      <div className="w-full">
        <Label htmlFor={`student_email_${idx}`}>Email</Label>
        <div className="flex gap-3">
          <TextInput
            id={`student_email_${idx}`}
            type="email"
            placeholder="Email"
            value={studentEmails[idx] || ""}
            onChange={handleEmailChange}
            className="mt-1 block w-full border border-pf-purple rounded-lg"
          />
          <button
            type="button"
            onClick={() => {
              const filteredEmails = studentEmails.filter(
                (_, emailIdx) => emailIdx !== idx
              );
              setStudentEmails(filteredEmails);
              setNumOfAdd(numOfAdd - 1);
            }}
            aria-label="Remove email field"
          >
            {icon}
          </button>
        </div>
      </div>
    </div>
  );
};

const AddStudentsForm: React.FC<{
  userEmail: string;
  handleLinking: (email: string, override?: boolean) => Promise<void>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ userEmail, handleLinking, setShowModal }) => {
  const [studentEmails, setStudentEmails] = useState<string[]>([]);
  const [studentAccountDetails, setStudentAccountDetails] = useState<
    { name: string; email: string; password: string }[]
  >([]);
  const [isSubmittingAdd, setIsSubmittingAdd] = useState(false);
  const [isSubmittingCreate, setIsSubmittingCreate] = useState(false);
  const [numOfCreate, setNumOfCreate] = useState<number>(0);
  const [numOfAdd, setNumOfAdd] = useState<number>(0);
  const form = useRef();
  const form2 = useRef();
  const [errorAdd, setErrorAdd] = useState<string | null>(null);
  const [errorCreate, setErrorCreate] = useState<string | null>(null);
  const [emailSubmissionStatus, setEmailSubmissionStatus] = useState<
    ("idle" | "loading" | "completed")[]
  >(new Array(studentEmails.length).fill("idle"));
  const [accountCreationStatus, setAccountCreationStatus] = useState<
    ("idle" | "loading" | "completed")[]
  >(new Array(studentAccountDetails.length).fill("idle"));

  // Function to validate all student account details
  const validateAllStudentDetails = () => {
    for (const details of studentAccountDetails) {
      if (!details.name || !details.email || !details.password) {
        return false; // Validation failed
      }
      // Add more detailed validation as per your requirements
    }
    return true; // Validation passed
  };

  const handleCreateAccountFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setErrorCreate(null); // Reset error state
    setIsSubmittingCreate(true);

    if (!validateAllStudentDetails()) {
      setErrorCreate("Please fill in all fields for each student.");
      setIsSubmittingCreate(false);
      return;
    }

    // Iterate over each student account detail to create accounts
    for (let i = 0; i < studentAccountDetails.length; i++) {
      const account = { ...studentAccountDetails[i], awsId: null };
      setAccountCreationStatus((statuses) =>
        statuses.map((status, idx) => (idx === i ? "loading" : status))
      );
      try {
        // Use Authenticator.signUp to create an account for each student
        const awsResponse = await Authenticator.signUp(
          account.email,
          account.password
        );
        account.email = account.email?.toLowerCase();
        account.awsId = awsResponse.userSub; // Assuming awsResponse.userSub contains the AWS ID
        // console.log(
        //   "Inputs to addPblUserToDB are:",
        //   account.name?.toLowerCase(),
        //   account.email,
        //   account.awsId
        // );
        // Make an API call to add the student to your database
        const response = await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/addPblUserToDB",
          {
            role: "Learner", 
            name: account.name,
            email: account.email,
            awsId: account.awsId,
          }
        );

        // console.log("Response: ", response.data);

        if (response.status === 200) {
          //   console.log(
          //     "Inputs to addStudentToTeacher are:",
          //     userEmail?.toLowerCase(),
          //     account.email,
          //     true
          //   );
          const addResponse = await axios.post(
            "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/addStudentToTeacher",
            {
              teacherEmail: userEmail,
              studentEmail: account.email,
              isApproved: true,
            }
          );
          // If the student was successfully added, refresh the student list
          await handleLinking(account.email);
          //   console.log(`Account successfully created for ${account.email}`);
          setAccountCreationStatus((statuses) =>
            statuses.map((status, idx) => (idx === i ? "completed" : status))
          );
          setShowModal(false);
        } else {
          console.error(`Failed to create account for ${account.email}`);
          setErrorCreate(`Failed to create account for ${account.email}`);
          // Optionally, handle the failure case
        }
      } catch (error) {
        console.error("Error creating account for: ", account.email, error);
        setErrorCreate(`Account already exists for: ${account.email}`);
      }
    }

    setIsSubmittingCreate(false);
    // Optionally, close the modal or navigate away after all accounts are processed
  };

  // Modify the sendEmail function to iterate over studentEmails
  const handleLinkStudentFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault(); // This line prevents the default form submission behavior
    setIsSubmittingAdd(true);

    for (let i = 0; i < studentEmails.length; i++) {
      if (studentEmails[i]) {
        setEmailSubmissionStatus((statuses) =>
          statuses.map((status, idx) => (idx === i ? "loading" : status))
        );
        // console.log(
        //   "Linking student with email: ",
        //   studentEmails[i],
        //   "progress:",
        //   emailSubmissionStatus[i]
        // );
        try {
          const addResponse = await axios.post(
            "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/addStudentToTeacher",
            {
              teacherEmail: userEmail.toLowerCase(),
              studentEmail: studentEmails[i].toLowerCase(),
              isApproved: false,
            }
          );
          // If the student was successfully added, refresh the student list
          await handleLinking(studentEmails[i].toLowerCase());
          setShowModal(false);
        } catch (error) {
          console.error(
            `Error linking student with email: ${studentEmails[i]}`,
            error
          );
          setErrorAdd(
            `Student email doesn't exist: ${studentEmails[i].toLowerCase()}`
          );
        }
        setEmailSubmissionStatus((statuses) =>
          statuses.map((status, idx) => (idx === i ? "completed" : status))
        );
      }
    }

    setIsSubmittingAdd(false);
  };

  return (
    <div className="flex flex-col p-4 w-full h-full">
      <form
        className="flex flex-col gap-4 h-full"
        ref={form}
        onSubmit={(e) => handleLinkStudentFormSubmit(e)} // Pass the event object to the handler
      >
        <div className="flex flex-col space-y-6">
          <h2 className="flex text-center w-full items-center justify-center">
            Add students to your dashboard
          </h2>
          {errorAdd && (
            <div
              className="border-4 border-pf-red text-pf-navy p-4 mb-8"
              role="alert"
            >
              <p className="font-bold">Oops something went wrong :( </p>
              <p className="underline">{errorAdd}</p>
            </div>
          )}
          <div className="flex flex-col space-y-2">
            {Array.from({ length: numOfAdd }).map((_, index) => (
              <div key={index}>
                <LinkStudentForm
                  idx={index}
                  studentEmails={studentEmails}
                  setStudentEmails={setStudentEmails}
                  numOfAdd={numOfAdd}
                  setNumOfAdd={setNumOfAdd}
                  submissionStatus={emailSubmissionStatus[index]} // Pass the status
                />
              </div>
            ))}
          </div>
          <button
            type="button"
            onClick={() => {
              setNumOfAdd(numOfAdd + 1);
            }}
          >
            <div className="w-full border-dashed border-2 border-pf-dark-grey rounded-lg p-4">
              <span className="font-bold text-pf-dark-grey">Add a student</span>
            </div>
          </button>
          <div className="xs:pt-6 flex justify-end">
            <PathFinderButton
              type="submit"
              loading={isSubmittingAdd}
              disabled={isSubmittingAdd}
            >
              Add students
            </PathFinderButton>
          </div>
        </div>
      </form>
      <div className="my-8 flex items-center justify-between">
        <div className="flex-grow border-t border-pf-dark-grey"></div>
        <span className="mx-4 text-pf-dark-grey">or</span>
        <div className="flex-grow border-t border-pf-dark-grey"></div>
      </div>
      <form
        className="flex flex-col gap-4 h-full"
        ref={form2}
        onSubmit={(e) => handleCreateAccountFormSubmit(e)} // Pass the event object to the handler
      >
        <div className="flex flex-col space-y-6">
          <h2 className="flex text-center w-full items-center justify-center">
            Create your students' accounts
          </h2>
          {errorCreate && (
            <div
              className="border-4 border-pf-red text-pf-navy p-4 mb-8"
              role="alert"
            >
              <p className="font-bold">Not quite done :( </p>
              <p className="underline">{errorCreate}</p>
            </div>
          )}
          <div className="flex flex-col space-y-2 mb-6">
            {Array.from({ length: numOfCreate }).map((_, index) => (
              <CreateAccountForm
                key={index}
                idx={index}
                studentAccountDetails={studentAccountDetails}
                setStudentAccountDetails={setStudentAccountDetails}
                numOfCreate={numOfCreate}
                setNumOfCreate={setNumOfCreate}
                creationStatus={accountCreationStatus[index]} // Pass the status
              />
            ))}
          </div>
          <button
            type="button"
            onClick={() => {
              setNumOfCreate(numOfCreate + 1);
            }}
          >
            <div className="w-full border-dashed border-2 border-pf-dark-grey rounded-lg p-4">
              <span className="font-bold text-pf-dark-grey">Add a student</span>
            </div>
          </button>
          <div className="xs:pt-6 flex justify-end">
            <PathFinderButton
              type="submit"
              loading={isSubmittingCreate}
              disabled={isSubmittingCreate}
            >
              Create accounts
            </PathFinderButton>
          </div>
        </div>
      </form>
      <div className="pt-8 bg-transparent"></div>
    </div>
  );
};

export default AddStudentsForm;
