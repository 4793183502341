import React, { useEffect, useRef } from "react";
import { Message } from "../objects";
import AssistantWritingSkeleton from "./AssistantWritingSkeleton";
import ChatMessage from "./ChatMessage";
import { useState } from "react";
import OptionButton from "./OptionButton";
import PathFinderButton from "../../../components/common/PathFinderButton";
import axios from "axios";

type ChatHistoryProps = {
  messages: Message[];
  assistantWriting: boolean;
  potentialPaths: string[];
  onPathSelected: ((path: string, index: number) => void) | null;
  messagesEndRef: React.RefObject<HTMLDivElement>;
  suggestedPrompts?: string[];
  handleSuggestedPromptSelection: (prompt: string) => Promise<void>;
  setUserPrompt: (prompt: string) => void;
};

const ChatHistory: React.FC<ChatHistoryProps> = ({
  messages,
  assistantWriting,
  potentialPaths,
  onPathSelected,
  messagesEndRef,
  suggestedPrompts,
  handleSuggestedPromptSelection,
  setUserPrompt,
}) => {
  const [selectedPath, setSelectedPath] = useState<string | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [allStreamed, setAllStreamed] = useState(false);
  const calculatedHeight = "pt-[calc(20vh-200px)] h-sm:pt-[calc(20vh)]";
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [positiveSelected, setPositiveSelected] = useState(false);
  const [negativeSelected, setNegativeSelected] = useState(false);
  const [loadingPositive, setLoadingPositive] = useState(false);
  const [loadingNegative, setLoadingNegative] = useState(false);
  const [showGoDeeperButton, setShowGoDeeperButton] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const [selectedText, setSelectedText] = useState("");
  const [isHighlightVisible, setIsHighlightVisible] = useState(false);
  const [highlightRects, setHighlightRects] = useState([]);
  const goDeeperButtonRef = useRef<HTMLButtonElement>(null);
  const [assistantLastMessageStreamed, setAssistantLastMessageStreamed] =
    useState(false);

  async function incrementGoDeeperClick() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        await axios.post(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/incrementGoDeeperClick",
          { email: userEmail }
        );
      }
    } catch (error) {
      console.error("Error incrementing GoDeeperClick:", error);
    }
  }

  useEffect(() => {
    // Reset showPrompts at the start of a new message streaming cycle
    setAllStreamed(false);
  }, [messages]);

  const handleStreamingComplete = () => {
    setTimeout(() => {
      setAllStreamed(true);
      setAssistantLastMessageStreamed(true);
    }, 15000);
  };

  const renderFeedbackButtons = () => {
    if (feedbackGiven) {
      // setTimeout(() => setFeedbackGiven(false), 0); // Reset feedbackGiven to allow for new feedback
      return (
        <div className="w-full text-center font-semibold text-lg mb-4 dark:text-white">
          Thanks for your feedback, you can now continue talking to Patty!
        </div>
      );
    }

    return (
      <>
        <div className="w-full text-center font-semibold text-lg mb-4 dark:text-white">
          Was the resource you selected helpful?
        </div>
        <div className="flex justify-center items-center space-x-16 my-12 w-full">
          <PathFinderButton
            outlined={!positiveSelected}
            loading={loadingPositive}
            onClick={() => {
              setLoadingPositive(true);
              handleFeedback("positive");
              setPositiveSelected(true);
              setNegativeSelected(false);
              setLoadingPositive(false);
            }}
          >
            👍
          </PathFinderButton>
          <PathFinderButton
            outlined={!negativeSelected}
            loading={loadingNegative}
            onClick={() => {
              setLoadingNegative(true);
              handleFeedback("negative");
              setNegativeSelected(true);
              setPositiveSelected(false);
              setLoadingNegative(false);
            }}
          >
            👎
          </PathFinderButton>
        </div>
      </>
    );
  };

  const handleFeedback = async (feedbackType: "positive" | "negative") => {
    const userEmail = localStorage.getItem("userEmail");
    if (!userEmail) {
      console.error("User email not found");
      return;
    }

    const voteType = feedbackType === "positive" ? "up" : "down";

    try {
      await axios.post(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/incrementResourceVote",
        { email: userEmail, voteType: voteType }
      );
      // console.log(`User gave ${feedbackType} feedback.`);
      setFeedbackGiven(true);
      setTimeout(() => setFeedbackGiven(false), 10000);
      setTimeout(() => setPositiveSelected(false), 10000);
      setTimeout(() => setNegativeSelected(false), 10000);
    } catch (error) {
      console.error(`Error incrementing ${voteType}vote:`, error);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current && messagesEndRef.current) {
      const { current: container } = chatContainerRef;
      const { current: endElement } = messagesEndRef;

      // Scroll to the bottom of the chat container by setting its scrollTop to the position of messagesEndRef
      container.scrollTop =
        endElement.offsetTop - container.offsetTop + endElement.clientHeight;
    }
  }, [
    messages,
    potentialPaths,
    suggestedPrompts,
    assistantWriting,
    messagesEndRef,
  ]); // Add any other dependencies that affect the chat's height

  // for highlting
  useEffect(() => {
    const handleMouseUp = () => {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        if (!range.collapsed) {
          const rects = Array.from(range.getClientRects());
          setButtonPosition({
            top: rects[rects.length - 1].bottom + window.scrollY,
            left: rects[rects.length - 1].left + window.scrollX,
          });
          setShowGoDeeperButton(true);
          // console.log("seleected text: " + selection.toString())
          setSelectedText(selection.toString());
          setHighlightRects(
            rects.map((rect) => ({
              top: rect.top + window.scrollY,
              left: rect.left + window.scrollX,
              width: rect.width,
              height: rect.height,
            }))
          );
          setIsHighlightVisible(true);
        } else {
          // setShowGoDeeperButton(false);
          setIsHighlightVisible(false);
        }
      }
    };

    const handleMouseDown = (event: MouseEvent) => {
      if (
        goDeeperButtonRef.current &&
        goDeeperButtonRef.current.contains(event.target as Node)
      ) {
        // Click on the "Go Deeper" button, do nothing
        return;
      }
      setIsHighlightVisible(false); // Clear highlight when clicking elsewhere
      setShowGoDeeperButton(false);
    };

    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousedown", handleMouseDown, true);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp, true);
      document.removeEventListener("mousedown", handleMouseDown, true);
    };
  }, []);

  const handleGoDeeperClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // Prevent the mousedown event from propagating
    // console.log("selected text: " + selectedText);
    setUserPrompt("Go deeper into " + selectedText);
    setShowGoDeeperButton(false);
    incrementGoDeeperClick();
  };

  return (
    <div
      ref={chatContainerRef}
      className="flex flex-col h-full items-start mb-2 pb-2 space-y-3 w-full"
    >
      {messages.map((message: Message, i) => {
        if (
          message &&
          "role" in message &&
          message.content[0] &&
          "text" in message.content[0]
        ) {
          return (
            <div className="w-full">
              <ChatMessage
                key={i}
                message={message}
                lastMessage={i === messages.length - 1}
                numMessages={messages.length}
                onStreamingComplete={
                  message.role === "assistant" && i === messages.length - 1
                    ? handleStreamingComplete
                    : undefined
                }
              />
            </div>
          );
        } else {
          console.error("Invalid message:", message);
          return null;
        }
      })}
      {potentialPaths.length > 0 ? (
        <div className="flex flex-wrap items-center justify-center text-center place-items-center m-2 pb-6">
          {potentialPaths.map((path, index) => (
            <OptionButton
              key={index}
              path={path}
              selectedPath={selectedPath}
              onPathSelected={(path) => {
                setSelectedPath(path);
                onPathSelected(path, index);
                setSelectedPath(null);
              }}
            />
          ))}
        </div>
      ) : (
        <>
          {" "}
          {assistantWriting && (
            <AssistantWritingSkeleton numMessages={messages.length} />
          )}
        </>
      )}
      {!assistantWriting &&
        messages.length > 0 &&
        messages[messages.length - 1].role === "assistant" &&
        messages[messages.length - 1].content.some(
          (content) =>
            content.type === "text" && content.text.value.includes("objectives")
        ) && <>{renderFeedbackButtons()}</>}
      {!assistantWriting &&
        allStreamed &&
        assistantLastMessageStreamed &&
        suggestedPrompts &&
        suggestedPrompts.length > 0 && (
          <div className="flex flex-wrap items-center justify-center text-center place-items-center m-2 pb-6">
            <div className="w-full text-center font-semibold text-lg mb-4 dark:text-white">
              Related Questions:
            </div>
            {suggestedPrompts.map((prompt, index) => (
              <OptionButton
                key={index}
                path={prompt}
                selectedPath={selectedPath} // No path is selected by default
                onPathSelected={(path) => {
                  handleSuggestedPromptSelection(path);
                  setAllStreamed(false);
                }}
              />
            ))}
          </div>
        )}
      <div
        className={`${calculatedHeight} max-h-[50%] w-full bg-transparent`}
      />
      <div className="pt-2 w-full bg-transparent" ref={messagesEndRef} />
      <div className="pt-2 w-full bg-transparent" ref={messagesEndRef} />
      {isHighlightVisible &&
        highlightRects.map((rect, index) => (
          <div
            key={index}
            style={{
              position: "absolute",
              top: `${rect.top - 12}px`,
              left: `${rect.left}px`,
              width: `${rect.width}px`,
              height: `${rect.height}px`,
              backgroundColor: "rgba(29, 161, 242, 0.3)",
              pointerEvents: "none",
            }}
          />
        ))}
      {showGoDeeperButton && (
        <div
          style={{
            position: "absolute",
            top: `${buttonPosition.top}px`, // Adjusted to account for scrolling
            left: `${buttonPosition.left}px`,
          }}
          className="mt-2" // Margin top to push it down a bit from the selected text
        >
          <button
            ref={goDeeperButtonRef}
            onMouseDown={(event) => event.stopPropagation()}
            onClick={handleGoDeeperClick}
            className="bg-black text-white p-3 rounded-lg text-sm font-bold" // Adjusted classes for styling
          >
            Go Deeper
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatHistory;
