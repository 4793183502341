import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const StepsCard = ({ aboutPathRef, editPathRef, accessShareRef, step }) => {
  let navigate = useNavigate();

  return (
    <div className="flex items-center justify-center text-pf-purple p-4 bg-white sticky top-0 z-50">
      <div className="flex items-center">
        <div
          className={`bg-${
            step >= 1 ? "pf-purple" : "gray-400"
          }  text-white rounded-full h-10 w-10 flex items-center justify-center`}
        >
          1
        </div>
        <div
          className={`ml-4 ${step >= 1 ? "text-pf-purple" : "text-gray-400"} ${
            step === 1 ? "font-bold" : ""
          }`}
        >
          About your classroom
        </div>
      </div>
      <div
        className={`border-t-2 ${
          step >= 1 ? "border-pf-purple" : "border-gray-400"
        } mx-4 flex-grow`}
      ></div>
      <div className="flex items-center">
        <div
          className={`bg-${
            step >= 2 ? "pf-purple" : "gray-400"
          } text-white rounded-full h-10 w-10 flex items-center justify-center`}
        >
          2
        </div>
        <div
          className={`ml-4 ${step >= 2 ? "text-pf-purple" : "text-gray-400"} ${
            step === 2 ? "font-bold" : ""
          }`}
        >
          Iterate on activities
        </div>
      </div>
      <div
        className={`border-t-2 ${
          step >= 2 ? "border-pf-purple" : "border-gray-400"
        } mx-4 flex-grow`}
      ></div>
      <div className="flex items-center">
        <div
          className={`bg-${
            step >= 3 ? "pf-purple" : "gray-400"
          } text-white rounded-full h-10 w-10 flex items-center justify-center`}
        >
          3
        </div>
        <div
          className={`ml-4 ${
            step >= 3 ? "text-pf-purple font-bold" : "text-gray-400"
          }`}
        >
          Finalise activities
        </div>
      </div>
    </div>
  );
};

export default StepsCard;
