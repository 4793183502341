import React, { useEffect, useState } from "react";
// import { NotionRenderer } from "react-notion";
// import { BlockMap } from "notion-types";
// import "react-notion-x/src/styles.css";

import axios from "axios";
import { ExtendedRecordMap } from "notion-types";
import { getPageTitle } from "notion-utils";
import { NotionRenderer } from "react-notion-x";
import "react-notion-x/src/styles.css";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface NotionPageProps {
  recordMap: ExtendedRecordMap;
  pageId: string;
  pathName?: string;
}

const NotionPage = ({ recordMap, pageId, pathName }: NotionPageProps) => {
  if (!recordMap) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const title = getPageTitle(recordMap);

  return (
    <>
      <div className="relative w-full mx-auto px-4 z-0">
        <NotionRenderer
          recordMap={recordMap}
          fullPage={true}
          darkMode={false}
          rootPageId={pageId}
          mapPageUrl={(pageId, recordMap) =>
            pathName ? `/${pathName}/${pageId}` : `/path/${pageId}`
          }
        />
      </div>
    </>
  );
};

export default NotionPage;
