import { Box, Button, CircularProgress } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Roadmap } from "../types";
import Grid from "@mui/material/Grid";

import SearchIntWidget from "../SearchIntWidget";
import HttpService from "../../../services/http";

interface SearchExtWidgetProps {
  query: string;
}

export async function searchForRoadmap(query: string): Promise<Roadmap[]> {
  //console.log("searching for roadmap");
  const response = await axios.post(
    "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/searchRoadmaps",
    {
      query: query,
    }
  );
  //console.log(response.data);
  return response.data;
}

export async function logSearchToDB(query: string): Promise<void> {
  const response = await axios.post(
    "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/logSearch",
    {
      query: query,
      debug: process.env.REACT_APP_RUN_TYPE === "debug" ? "true" : "",
    }
  );
}

const SearchExtWidget = ({ query }: SearchExtWidgetProps) => {
  const [allRoadmaps, setAllRoadmaps] = useState<Roadmap[]>([]);
  const [toDisplayRoadmaps, setToDisplayRoadmaps] = useState<Roadmap[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [oldQuery, setOldQuery] = useState<string>("");
  const [searchMessage, setSearchMessage] = useState<string>(
    "We're sorry but our servers are currently down :( \nWe are working hard to fix it so please search again later..."
  );

  const dataFetchedRef = useRef(false);

  const fetchAllRoadmaps = async () => {
    const roadmaps = await axios
      .post(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getAllRoadmaps"
      )
      .catch((err) => {
        //console.log("Error: ", err);
        return { data: [] };
      })
      .then((res) => {
        //console.log("Fetched all roadmaps res: ", res);
        return res.data;
      });

    setAllRoadmaps(roadmaps);
    setToDisplayRoadmaps(roadmaps);
    setLoading(false);
  };

  const fetchSearchedRoadmaps = async (query: string) => {
    if (query === "") {
      setToDisplayRoadmaps(allRoadmaps);
      //console.log("empty query");
    } else {
      if (query !== oldQuery) {
        //console.log("query changed to " + query);
        setLoading(true);
        const roadmaps = await searchForRoadmap(query);
        setToDisplayRoadmaps(roadmaps);
        setSearchMessage(
          "We've heard you! This roadmap will be in development soon."
        );
        setLoading(false);
        logSearchToDB(query);
        setOldQuery(query);
      } else {
        //console.log("query did not change");
      }
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAllRoadmaps();
  }, []);

  useEffect(() => {
    fetchSearchedRoadmaps(query);
  }, [query]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box
          sx={{
            mt: 5,
            alignSelf: "center",
            borderRadius: 6,
            p: 2,
            // width: 1050,
            height: "flex",
            backgroundColor: "#E3D2E5",
          }}
        >
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress color="secondary" />
            </Box>
          ) : toDisplayRoadmaps.length === 0 ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <p style={{ fontWeight: 600 }}>{searchMessage}</p>
            </Box>
          ) : (
            toDisplayRoadmaps.map((roadmap) => (
              <SearchIntWidget
                key={roadmap.link}
                link={roadmap.link}
                personal={roadmap.personal}
                rating={roadmap.rating}
                title={roadmap.title}
                topic_names={roadmap.topic_names}
                description={roadmap.description}
              />
            ))
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchExtWidget;
