import React, { useState, useEffect } from "react";
import PathFinderButton from "../../../../components/common/PathFinderButton";
import { Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import DeleteModal from "../../../../components/common/DeleteModal";

type Student = {
  name: string;
  email: string;
  isApproved: boolean;
};

type StudentMetrics = {
  lastLoggedIn: string | null;
  pagesCreated: number;
};

type DisplayStudentsProps = {
  teacherEmail: string;
  students: Student[];
  handleViewPages: (studentSelected: Student) => void;
  fetchStudents: () => void;
  vertical?: boolean;
  loading?: boolean;
};

const DisplayStudents: React.FC<DisplayStudentsProps> = ({
  teacherEmail,
  students,
  handleViewPages,
  fetchStudents,
  vertical,
  loading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [studentEmailToUnlink, setStudentEmailToUnlink] = useState("");
  const [studentMetrics, setStudentMetrics] = useState<Record<string, StudentMetrics>>({});

  useEffect(() => {
    students.forEach(student => {
      fetchStudentMetrics(student.email);
    });
  }, [students]);

  const fetchStudentMetrics = async (email: string) => {
    try {
      const response = await axios.get("https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getStudentMetrics", 
        { 
          params: {
            email: email,
          }
        }
      );
      setStudentMetrics(prev => ({
        ...prev,
        [email]: {
          lastLoggedIn: response.data.lastLoggedIn,
          pagesCreated: response.data.pagesCreated,
        }
      }));
    } catch (error) {
      console.error("Error fetching student metrics:", error);
    }
  };

  const handleDelete = (student: Student) => {
    setStudentEmailToUnlink(student.email);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    // console.log("Teacher email", teacherEmail);
    // console.log("Student email", studentEmailToUnlink);
    try {
      setDeleting(true);
      const response = await axios.post(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/deleteStudentFromTeacher",
        {
          teacherEmail: teacherEmail,
          studentEmail: studentEmailToUnlink,
        }
      );
      if (response.status === 200) {
        // console.log(`Student ${studentEmailToUnlink} deleted successfully.`);
        // Optionally, refresh the students list or handle UI updates here
      } else {
        console.error(`Failed to delete student ${studentEmailToUnlink}.`);
      }
    } catch (error) {
      console.error(`Error deleting student ${studentEmailToUnlink}:`, error);
    }
    fetchStudents();
    setDeleting(false);
    setStudentEmailToUnlink("");
    setShowModal(false);
  };

  if (loading) {
    return (
      <div
        className={`flex ${
          vertical ? "flex-col w-full space-y-4" : "flex-wrap gap-4 p-4"
        }`}
      >
        {[...Array(5)].map((_, index) => (
          <div key={index}>
            <Skeleton
              variant="rectangular"
              width={240}
              height={120}
              className="rounded-lg"
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      <div
        className={`flex ${
          vertical ? "flex-col w-full space-y-4" : "flex-wrap gap-4 p-4"
        }`}
      >
        {students.map((student, index) => (
          <div
            key={index}
            className={`bg-white dark:bg-pf-dark-grey border border-pf-purple dark:border-pf-grey rounded-lg p-4 ${
              vertical ? "w-full" : "w-60"
            } flex flex-col justify-between relative`}
          >
            <button
              onClick={() => {
                handleDelete(student);
              }}
              className="absolute top-2 right-2 text-pf-purple dark:text-pf-green hover:text-pf-dark-purple dark:hover:text-pf-light-green"
              style={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} className="text-pf-red" />
            </button>
            <h4 className="font-semibold mb-2 text-black dark:text-white">
              {student.name}
            </h4>
            {student.isApproved && (
              <>
                <p className="font-semibold mb-2 text-black dark:text-white">
                  Last active on: <span className="text-pf-purple dark:text-pf-green">{studentMetrics[student.email]?.lastLoggedIn || "Not available"}</span>
                </p>
                <p className="font-semibold mb-2 text-black dark:text-white">
                  No. resource pages: <span className="text-pf-purple dark:text-pf-green">{studentMetrics[student.email]?.pagesCreated || 0}</span>
                </p>
              </>
            )}
            {student.isApproved ? (
              <div className="flex justify-center mt-2">
                <PathFinderButton onClick={() => handleViewPages(student)}>
                  View Activity
                </PathFinderButton>
              </div>
            ) : (
              <p className="text-pf-purple dark:text-pf-green text-lg font-bold">
                Pending student approval
              </p>
            )}
          </div>
        ))}
      </div>
      {showModal && (
        <DeleteModal
          showModal={showModal}
          handleConfirmDelete={handleConfirmDelete}
          setShowModal={setShowModal}
          loading={deleting}
          text="Are you sure you want to unlink this student from your dashboard?"
        />
      )}
    </>
  );
};

export default DisplayStudents;
