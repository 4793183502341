import { TextInput } from "flowbite-react";
import React, { forwardRef, useState, useEffect, RefObject } from "react";
import TextArea from "../../../components/common/TextArea";
import CircularProgress from "@mui/material/CircularProgress";
import PathFinderButton from "../../../components/common/PathFinderButton";

interface AboutPathCardProps {
  onGeneratePath: (
    title: string,
    weeks: number,
    learner: string,
    goal: string
  ) => Promise<void>;
}

const AboutPathCard = forwardRef<HTMLDivElement, AboutPathCardProps>(
  (props, ref) => {
    const [selectedWeeks, setSelectedWeeks] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [learner, setLearner] = useState("");
    const [goal, setGoal] = useState("");

    const handleGeneratePath = async () => {
      setIsLoading(true);
      try {
        await props.onGeneratePath(title, selectedWeeks, learner, goal);
      } catch (error) {
        console.error("Error in handleGeneratePath:", error);
        console.error("Error message:", error.message);
        if (error.response) {
          console.error("Server response:", error.response.data);
        }
        console.error("Error stack trace:", error.stack);
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (isLoading) {
        setTimeout(() => {
          setIsLoading(false);
          if (typeof ref === "object" && ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 180000);
      }
    }, [isLoading, ref]);

    return (
      <div ref={ref as RefObject<HTMLDivElement>}>
        <div className="grid grid-cols-3 mt-8">
          <div className="mt-4 col-span-1">
            <h1 className="text-4xl font-bold">About your path</h1>
          </div>
          <div className="flex flex-col col-span-2 ml-12">
            <label className="font-semibold mb-2">
              What do you want to teach/learn?
            </label>
            <TextArea
              h="2.25rem"
              placeholder="UX/UI Design"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <label className="font-semibold mt-6 mb-2">
              Who is being taught?
            </label>
            <TextArea
              h="2.25rem"
              placeholder="Product people without any background in design"
              value={learner}
              onChange={(e) => setLearner(e.target.value)}
            />

            <label className="font-semibold mt-6 mb-2">
              What is your goal for this path?
            </label>
            <TextArea
              h="10rem"
              placeholder="Get them to building high fidelity prototypes with Figma easily"
              value={goal}
              onChange={(e) => setGoal(e.target.value)}
            />
            <label className="font-semibold mt-6">
              How many weeks do you want the path to be?
            </label>
            <div className="mt-2">
              {Array.from({ length: 8 }, (_, i) => i + 1).map((number) => (
                <button
                  key={number}
                  className={`border p-2 px-4 mx-1 rounded-md ${
                    selectedWeeks === number
                      ? "text-white bg-pf-purple border-pf-purple"
                      : "border-pf-dark-grey bg-pf-grey"
                  }`}
                  onClick={() => setSelectedWeeks(number)}
                >
                  {number}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-end my-8">
          <PathFinderButton
            loading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await handleGeneratePath();
              setIsLoading(false);
            }}
          >
            Generate Path
          </PathFinderButton>
        </div>
        <div style={{ borderTop: "1px solid #D1D5DB" }}></div>
      </div>
    );
  }
);

export default AboutPathCard;
