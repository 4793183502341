interface AccountsTemplateProps {
  bg_img: string;
  title: string;
  subtitle: string;
  form_component: JSX.Element;
}

const AccountsTemplate = (props: AccountsTemplateProps) => {
  return (
    <div className="bg-white">
      {/* Make a grid with 2 rows using Tailwind, adjust to 2/3 screen height and center */}
      <div className="h-2/3 md:h-2/3 screen my-auto md:grid md:grid-cols-2">
        {/* First row is the header */}
        <div className={props.bg_img}></div>
        {/* Second row is the body */}
        {/* Use flexbox to center content vertically and horizontally */}
        <div className="bg-white m-10 flex flex-col justify-center items-center">
          <div className="w-full">
            {/* Adjust width as needed */}
            <h3 className="text-4xl text-center mt-4 mb-6">{props.title}</h3>
            <h4 className="text-center pb-4">{props.subtitle}</h4>
            {props.form_component}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountsTemplate;
