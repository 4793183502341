import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/Header/header";
import Authenticator from "../../../services/authenticator";
import OverviewPage from "../components/OverviewPage";
import { useUser } from "../../../contexts/UserContext";
import RootNoFooterPage from "../../rootNoFooter";
import PathFinderButton from "../../../components/common/PathFinderButton";
import Modal from "react-modal";
import DisplayStudents from "./components/DisplayStudents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Student } from "./components/types";
import AddStudentsForm from "./components/AddStudentsForm";

Modal.setAppElement("#root"); // replace with your root element id
const isSmallDevice = window.innerWidth <= 768;

type TitleInfo = {
  title: string;
  bracketThread: string;
};

const TeacherDashboard = () => {
  const { user } = useUser();
  const [email, setEmail] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [students, setStudents] = useState<Student[]>([]);
  const [chatSummary, setChatSummary] = useState<string | null>(null);
  const [addingStudent, setAddingStudent] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fetchingStudents, setFetchingStudents] = useState<boolean>(false);

  // const handleViewPages = async (name: string, email: string) => {
  //   const pageId = await Authenticator.getUserPageIdByEmail(email);
  //   // console.log(`Viewing pages for student with page ID: ${pageId}`);
  //   // Find the student in the students state to get the isApproved status
  //   const student = students.find((student) => student.email === email);
  //   const isApproved = student ? student.isApproved : false; // Default to false if not found
  //   setSelectedStudent({ name, email, pageId, isApproved });

  //   let response: any = null;
  //   try {
  //     response = await axios.get(
  //       `https://pathfinder-notion-web-content.vercel.app/api/${pageId}`
  //     );
  //     const threadIds = response.data.recordMap.block[pageId].value.content;
  //     // console.log("THREAD IDs: " + threadIds);
  //     setThreadIds(threadIds);

  //     const tempTitleMap = new Map();
  //     let bracketThreads = [];

  //     // Fetch and store titles
  //     for (const threadId of threadIds) {
  //       const response = await axios.get(
  //         `https://pathfinder-notion-web-content.vercel.app/api/${threadId}`
  //       );
  //       let fullTitle =
  //         response.data.recordMap.block[threadId].value.properties.title[0][0];
  //       let title = fullTitle;
  //       let bracketThread = "";

  //       // Check if title includes brackets and extract the content
  //       if (fullTitle.includes("(")) {
  //         const splitTitle = fullTitle.split("(");
  //         title = splitTitle[0].trim();
  //         bracketThread = splitTitle[1].split(")")[0].trim();
  //         bracketThreads.push(bracketThread);
  //       }
  //       tempTitleMap.set(threadId, { title, bracketThread });
  //     }
  //     // console.log("TITLE MAP: ", Array.from(tempTitleMap));
  //     setTitleMap(tempTitleMap);
  //     setShowPages(true);
  //     // console.log("NAME: ", name)
  //     if (bracketThreads.length > 0) {
  //       await fetchAndSummarizeAllMessages(bracketThreads, name);
  //     }
  //     navigate(`/teacher-dashboard/${name}`, {
  //       state: {
  //         selectedStudent: { name, email, pageId },
  //         students,
  //         titleMap: tempTitleMap,
  //         overallChatSummary: chatSummary,
  //       },
  //     });
  //   } catch (error) {
  //     // console.log("ERORR: ", error);
  //     return;
  //   }
  // };

  const handleViewPages = async (studentSelected: Student) => {
    // Find the student in the students state to get the isApproved status
    const isApproved = studentSelected.isApproved
      ? studentSelected.isApproved
      : false; // Default to false if not found

    // Navigate first and pass the necessary data for Axios calls in the StudentOverviewPage
    if (isApproved) {
      navigate(`/teacher-dashboard/${studentSelected.name}`, {
        state: {
          studentName: studentSelected.name,
          studentEmail: studentSelected.email,
          studentsParent: students,
        },
      });
    } else {
      alert("This student is not approved to view pages.");
    }
  };

  const fetchStudents = async () => {
    try {
      setFetchingStudents(true);
      const response = await axios.get(
        "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getStudentsByTeacherEmail",
        {
          params: {
            email: user.email,
          },
        }
      );
      setStudents(response.data);
      setFetchingStudents(false);
    } catch (error) {
      console.error("Error fetching students for teacher:", error);
    }
  };

  useEffect(() => {
    // Check if students are passed in location state
    const stateStudents = location.state?.students;
    if (stateStudents) {
      setStudents(stateStudents);
    } else if (user && students.length === 0) {
      fetchStudents();
    }
  }, [user, location.state]); // Add location.state as a dependency

  useEffect(() => {
    // Only fetch students if the user is defined and the students list is empty
    if (user && students.length === 0) {
      fetchStudents();
    }
  }, [user, students.length]); // Add students.length as a dependency

  const customStyles = {
    content: {
      width: "90%",
      maxWidth: "1000px",
      height: "80%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const handleSubmit = async (email: string, override?: boolean) => {
    console.log("Handling the link");
    setAddingStudent(true);
    const newStudent = {
      name: "Loading name...", // Placeholder name, replace with actual data if available
      email: email,
      isApproved: override ? true : false, // Assuming new students need approval
    };
    setStudents((prevStudents) => [...prevStudents, newStudent]);
    setAddingStudent(false);
    fetchStudents();
    setEmail("");
    setAddingStudent(false);
  };

  return (
    <RootNoFooterPage header="Teacher Dashboard">
      <div>
        <div className="flex flex-col space-y-8">
          <div>
            <div className="bg-pf-purple dark:bg-pf-green w-full mt-[-18px] py-10 px-6 flex justify-between items-center">
              <div>
                <h2 className="text-white dark:text-pf-navy">
                  Welcome, {user.name}
                </h2>
                {/* <h4 className="text-white">YOUR SCHOOL NAME</h4> */}
              </div>
              <PathFinderButton
                outlined={true}
                onClick={() => {
                  navigate("/");
                }}
              >
                <h2 className="text-lg">Go back to chat</h2>
              </PathFinderButton>
            </div>
          </div>
          <div>
            <div className="flex flex-col w-full sm:flex-row justify-start sm:justify-between items-start sm:items-center space-y-4 sm:space-y-0 px-4">
              <h3 className="font-bold w-full text-black dark:text-white">
                Your Students
              </h3>
              {/* <form
              onSubmit={handleSubmit}
              className="flex w-full items-center gap-4 justify-end"
            >
              <TextInput
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter student email"
                required={true}
                className="w-[60%] border border-pf-purple dark:border-pf-grey rounded-lg"
              />
              <PathFinderButton type="submit" loading={addingStudent}>
                Add Student
              </PathFinderButton>
            </form> */}
              <div className="flex w-full items-center gap-4 justify-end">
                <PathFinderButton
                  onClick={() => {
                    setIsModalOpen(!isModalOpen);
                  }}
                >
                  <div className="flex flex-row w-full items-center justify-center gap-2">
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="w-full">Add Students</span>
                  </div>
                </PathFinderButton>
              </div>
            </div>
            <hr className="border-t-2 mt-4 border-black dark:border-white"></hr>
          </div>
          <DisplayStudents
            teacherEmail={user.email}
            students={students}
            handleViewPages={handleViewPages}
            loading={fetchingStudents}
            fetchStudents={fetchStudents}
          />
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={customStyles} // customize styles as needed
          contentLabel="Application Modal"
        >
          {
            <AddStudentsForm
              userEmail={user.email}
              handleLinking={handleSubmit}
              setShowModal={setIsModalOpen}
            />
          }
        </Modal>
      </div>
    </RootNoFooterPage>
  );
};

export default TeacherDashboard;
