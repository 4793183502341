import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player/youtube";
import {
  faGripVertical,
  faChevronUp,
  faChevronDown,
  faArrowsRotate,
  faUpload,
  faPaperPlane,
  faCheck,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import PathFinderButton from "../PathFinderButton";
import { ReactTinyLink } from "react-tiny-link";
import TextArea from "../TextArea";
import LinkPreview from "../../LinkPreview";
import { Links } from "../../../pages/GeneratePaths";

interface DraggableCardProps {
  id: string;
  index: number;
  title: string;
  link: string;
  isYouTubeLink: boolean;
  isSubtopic: boolean;
  children?: React.ReactNode;
  handleRegenerate: (title: string) => Promise<void>;
  className?: string;
  setCurrentLinksMap: React.Dispatch<React.SetStateAction<Links>>;
  currentLinksMap: Links;
}

const DraggableCard: React.FC<DraggableCardProps> = ({
  id,
  index,
  title,
  link,
  isYouTubeLink,
  isSubtopic,
  children,
  handleRegenerate,
  className,
  setCurrentLinksMap,
  currentLinksMap,
}) => {
  const [collapse, setCollapse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [userLink, setUserLink] = useState("");

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedTitle(e.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const regenerateLink = async () => {
    setIsLoading(true);
    try {
      await handleRegenerate(title);
    } catch (error) {
      console.error("Error regenerating link:", error);
    }
    setIsLoading(false);
  };

  const handleUserLinkSubmit = (title: string) => {
    setCurrentLinksMap((prevLinksMap) => ({
      ...prevLinksMap,
      [title]: userLink,
    }));
    setUserLink(""); // Clear the input field
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`p-4 bg-pf-grey rounded-xl my-2 border border-pf-dark-grey ${
            collapse ? "collapsed" : ""
          } ${className}`}
        >
          <div className="flex items-center">
            <div
              {...provided.dragHandleProps}
              className="cursor-grab text-[#323334] text-xs"
              title="Drag to reorder"
            >
              <FontAwesomeIcon icon={faGripVertical} />
            </div>
            <div className="flex flex-col ml-4 w-full ">
              <button
                onClick={() => setCollapse(!collapse)}
                className="focus:outline-none w-full text-left"
              >
                <div className="flex justify-between items-center">
                  <div className="flex w-full pr-8 items-center gap-4">
                    {isEditing ? (
                      <div className="w-full flex gap-4">
                        <TextArea
                          h="2.25rem"
                          placeholder={editedTitle}
                          value={editedTitle}
                          onChange={handleTitleChange}
                        />
                      </div>
                    ) : (
                      <p className="font-bold text-lg">{editedTitle}</p>
                    )}
                    <button onClick={handleEditClick}>
                      <FontAwesomeIcon
                        icon={isEditing ? faCheck : faEdit}
                        className="text-pf-dark-grey"
                      />
                    </button>
                  </div>
                  <div className="flex items-center gap-4">
                    {isSubtopic && (
                      <PathFinderButton
                        onClick={regenerateLink}
                        loading={isLoading}
                      >
                        <FontAwesomeIcon icon={faArrowsRotate} />
                      </PathFinderButton>
                    )}
                    <FontAwesomeIcon
                      icon={collapse ? faChevronUp : faChevronDown}
                    />
                  </div>
                </div>
              </button>
              <div className={`${collapse ? "block" : "hidden"} py-2`}>
                {children}
                {isSubtopic && (
                  <div className="flex w-full flex-col gap-y-4">
                    {}
                    {isYouTubeLink ? (
                      <ReactPlayer url={currentLinksMap[title]} width="100%" />
                    ) : (
                      <div>
                        <LinkPreview
                          url={currentLinksMap[title]}
                          size="large"
                        />
                      </div>
                    )}
                    <div className="flex w-full gap-4 items-center">
                      <PathFinderButton onClick={null}>
                        <FontAwesomeIcon icon={faUpload} />
                      </PathFinderButton>
                      <TextArea
                        placeholder="Submit your own resource here (https://youtube.com/)"
                        h="2.25rem"
                        value={userLink}
                        onChange={(e) => setUserLink(e.target.value)}
                      />
                      <PathFinderButton
                        onClick={() => handleUserLinkSubmit(title)}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </PathFinderButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableCard;
