import React, { useState } from "react";
import { Card } from "flowbite-react";
import open from "../../../assets/open.png";
import axios from "axios";
import Authenticator from "../../../services/authenticator";
import JSZip from "jszip";
import projectCard from "../../../assets/projectCard.png";
import closeWindow from "../../../assets/close.png";
import DeleteModal from "../DeleteModal";

export interface ProjectCardProps {
  title: string;
  description: string;
  fileStorageLink: string;
  removeProjCardCallback: any;
  pathId: any;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  title,
  description,
  fileStorageLink,
  removeProjCardCallback,
  pathId,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setDeleting(true);
    const userId = await Authenticator.getCurrentUserId();
    const response = await axios.post(
      // "http://127.0.0.1:8081/thepathfinderprojectbackendapi/us-central1/removeUserFromProject",
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/removeUserFromProject",
      {
        userId: userId,
        fileStorageLink: fileStorageLink,
      }
    );
    console.log(response);

    removeProjCardCallback();

    setDeleting(false);
    setShowModal(false);
  };

  const getProjectZip = async () => {
    const userId = await Authenticator.getCurrentUserId();
    const result = await axios.post(
      "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getAProject",
      {
        userId: userId,
        filename: title,
      }
    );
    // Convert the base64 string in result.data to zip file and download it
    const zipFile = result.data;
    // console.log(zipFile);
    // Decode the base64 encoded string
    const decodedZip = window.atob(zipFile);
    const zip = new JSZip();
    await zip.loadAsync(decodedZip);

    const blob = await zip.generateAsync({ type: "blob" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${title}.zip`;
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <div className="max-w-sm rounded-3xl shadow-lg hover:shadow-xl">
      <Card className="rounded-3xl">
        <div className="relative">
          <button
            className="absolute top-2 right-2 rounded-full p-1"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
          >
            <img src={closeWindow} alt="Close" />
          </button>
          <img
            src={projectCard}
            alt="Project"
            className="w-full h-full rounded-2xl"
          />
        </div>
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
        <text className="font-normal text-gray-700 dark:text-gray-400 text-ellipsis overflow-hidden">
          {description}
        </text>
        <button
          className="flex items-center mt-6 px-4 py-2 ml-auto bg-pf-purple text-white font-semibold rounded-md shadow-md hover:bg-home-card-1 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 disabled:opacity-50"
          onClick={() => getProjectZip()}
        >
          View project
          <img src={open} alt="upload" className="w-5 h-5 ml-2" />
        </button>

        {showModal && (
          <DeleteModal
            showModal={showModal}
            handleConfirmDelete={handleConfirmDelete}
            setShowModal={setShowModal}
            loading={deleting}
            text="Are you sure you want to delete this project?"
          />
        )}
      </Card>
    </div>
  );
};

export default ProjectCard;
