import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

const LinkPreview = ({ url, size }) => {
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("Fetching preview for:", url);
        const response = await axios.get(
          "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getLinkData",
          {
            params: {
              url: url
            }
          }
        );
        // console.log("Response:", response.data);
        setPreviewData(response.data);
        setLoading(false);
      } catch (error) {
        // console.log("Error:", error);
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  if (!previewData) {
    return (
      <div className="h-full text-center items-center py-4">
        <text>
          Failed to fetch link preview. Click{" "}
          <a
            className="underline text-blue-500"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{" "}
          to see the resource
        </text>
      </div>
    );
  }

  const handleClick = () => {
    window.open(url, "_blank");
  };

  return (
    <div
      onClick={handleClick}
      style={{ cursor: "pointer" }}
      className="border border-pf-dark-grey bg-white w-full border-dashed p-4 m-2"
    >
      {size === "small" && (
        <div className="flex">
          {previewData.favicons && previewData.favicons.length > 0 ? (
            <div className="flex-none">
              {(() => {
                const favicon = previewData.favicons.find((favicon) => favicon);
                return favicon ? (
                  <img
                    src={favicon}
                    alt="Favicon"
                    className="w-8 h-8 object-cover"
                  />
                ) : null;
              })()}
            </div>
          ) : (
            previewData.images && (
              <div className="flex-none">
                <img
                  className="object-cover h-full w-full"
                  src={previewData.images[0]}
                  alt="Link Preview"
                />
              </div>
            )
          )}
          <div className="flex-grow pl-4 h-full items-center justify-center text-left overflow-hidden text-ellipsis">
            {previewData.siteName ? (
              <div>
                <p className="font-bold">{previewData.siteName}</p>
                <p className="font-bold text-pf-purple">Click to open this article</p>
              </div>
            ) : (
              <div>
                <p className="font-bold text-pf-purple">Click to open this article</p>
                <p className="text-sm text-pf-dark-grey">{url}</p>
              </div>
            )}
          </div>
        </div>
      )}
      {size === "large" && (
        <div className="flex">
          {previewData.favicons && previewData.favicons.length > 0 ? (
            <div className="flex-none">
              {(() => {
                const favicon = previewData.favicons.find((favicon) => favicon);
                return favicon ? (
                  <img
                    src={favicon}
                    alt="Favicon"
                    className="w-16 h-16 object-cover"
                  />
                ) : null;
              })()}
            </div>
          ) : (
            previewData.images && (
              <div className="flex-none">
                <img
                  className="object-cover h-full w-full"
                  src={previewData.images[0]}
                  alt="Link Preview"
                />
              </div>
            )
          )}
          <div className="flex-grow pl-4 text-left overflow-hidden text-ellipsis">
            <p className="font-bold">{previewData.title}</p>
            <p>{previewData.siteName}</p>
            <text>{previewData.description}</text>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkPreview;
