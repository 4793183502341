import amplitude from 'amplitude-js';
import { integer } from 'aws-sdk/clients/cloudfront';
import axios from 'axios';

const amplitudeInstance = amplitude.getInstance();
amplitudeInstance.init("221fb43727cc773e832384e9d4b952c2");

const testEmails = [
  "amaankahmad@gmail.com",
  "amaankahmad2@gmail.com",
  "sid4@gmail.com",
  "sid5@gmail.com",
  "sidharthjain20022@gmail.com",
];

var check = true;

export const addAmplitudeIdToUser = async (amplitudeId: integer, email: string) => {
  try {
    await axios.post("https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/addAmplitudeId", {
      email,
      amplitudeId,
    });
    // console.log('Amplitude ID added:', response.data);
  } catch (error) {
    // console.error('Error adding Amplitude ID:', error);
  }
};

export const generateUniqueUserId = (email: string): number => {
  let hash = 0;
  if (testEmails.includes(email)) {
    check = false; // Set check to false if email is in testEmails
    return hash; // Return 0 as hash value
  }
  for (let i = 0; i < email.length; i++) {
    const char = email.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  addAmplitudeIdToUser(hash, email);
  return hash;
};

export const logEvent = (eventName: string, eventProperties?: object) => {
  if (check) { // Only log event if check is true
    amplitudeInstance.logEvent(eventName, eventProperties);
  }
  check = true;
};

export default amplitudeInstance;