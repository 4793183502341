import { forwardRef, useState, useEffect, RefObject } from "react";
import WeekCard from "../../../components/common/WeekCard";
import axios from "axios";
import jsPDF from "jspdf";
import PathFinderButton from "../../../components/common/PathFinderButton";
import SkeletonActivityCard from "../../../components/common/SkeletonWeekCard";
import ActivityOptionCard from "./activityOptionCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import TextArea from "../../../components/common/TextArea";
import { Activity } from "../index";

interface EditActivitiesCardProps {
  activities: Activity[];
  handleIterateAllActivities: (changesRequested: string) => void;
  handleIterateSingleActivity: (
    iterateActivityIndex: number,
    changesRequested: string
  ) => Promise<Activity>;
}

const EditActivitiesCard = forwardRef<HTMLDivElement, EditActivitiesCardProps>(
  (
    { activities, handleIterateAllActivities, handleIterateSingleActivity },
    ref
  ) => {
    const [command, setCommand] = useState("");
    const [loading, setLoading] = useState(false);

    return (
      <div ref={ref as RefObject<HTMLDivElement>}>
        <div className="grid grid-cols-3 mt-8">
          <div className="mt-4 col-span-1">
            <h1 className="text-4xl font-bold">Iterate your activities</h1>
          </div>
          <div className="flex mt-2 flex-col col-span-2 ml-12">
            {!activities || loading ? (
              Array.from({ length: 5 }).map((_, index) => (
                <SkeletonActivityCard key={index} />
              ))
            ) : (
              <>
                <div className="space-y-2">
                  {activities.map((activity, index) => {
                    return (
                      <ActivityOptionCard
                        title={activity.title}
                        description={activity.description}
                        implementation={activity.implementation}
                        handleIterateSingleActivity={
                          handleIterateSingleActivity
                        }
                        activitiesIndex={index}
                      />
                    );
                  })}
                </div>
                {activities.length === 0 && (
                  <h5 className="mt-3 ml-6 w-full">
                    Sorry something went wrong. Refresh page.
                  </h5>
                )}
                {activities.length && (
                  <div className="flex flex-col mt-4">
                    <label className="font-semibold mb-2">
                      Not good enough? Tell me more of what you want
                    </label>
                    <div className="flex w-full gap-4 items-center">
                      <TextArea
                        placeholder="Iterate on the above activity here"
                        h="2.25rem"
                        value={command}
                        onChange={(e) => setCommand(e.target.value)}
                      />
                      <PathFinderButton
                        onClick={async () => {
                          setCommand("");
                          setLoading(true);
                          await handleIterateAllActivities(command);
                          setLoading(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </PathFinderButton>
                    </div>
                  </div>
                )}
                <div className="mt-8 w-full flex justify-end">
                  <PathFinderButton onClick={() => {}}>Next</PathFinderButton>
                </div>
              </>
            )}
          </div>
        </div>
        <div style={{ borderTop: "1px solid #D1D5DB" }} className="mt-8"></div>
      </div>
    );
  }
);

export default EditActivitiesCard;
