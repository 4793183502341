import React, { useEffect, useState } from "react";
import axios from "axios";
import { ExtendedRecordMap } from "notion-types";
import { getPageTitle } from "notion-utils";
import { useNavigate } from "react-router-dom";
import NotionPage from "./notionPage";
import ChatInterface from "../../components/common/ChatInterface";
import { useParams } from "react-router-dom";
import RootNoFooterPage from "../rootNoFooter";
import { CircularProgress } from "@mui/material";

const ParentComponent = ({ pathName }: { pathName?: string }) => {
  const navigate = useNavigate();
  const [recordMap, setRecordMap] = useState<ExtendedRecordMap | null>(null);
  const [contextOnPath, setContextOnPath] = useState("");
  const { pageId } = useParams<{ pageId: string }>();

  const fetchNotionPage = async () => {
    let response: any = null;
    try {
      response = await axios.get(
        `https://pathfinder-notion-web-content.vercel.app/api/${pageId}`
      );
    } catch (error) {
      navigate("/error");
      return;
    }

    if (response.data.error) {
      console.error("Error:", response.data.error.message);
      navigate("/error");
    } else {
      setRecordMap(response.data.recordMap);
      const title = getPageTitle(response.data.recordMap);

      const blockMap = response.data.recordMap.block;
      let allText = "";

      for (let blockId in blockMap) {
        const block = blockMap[blockId].value;
        if (
          block?.type === "text" &&
          block?.properties &&
          block?.properties.title
        ) {
          allText += block.properties.title[0][0] + "\n";
        }
      }

      setContextOnPath(
        "I am on the " +
          title +
          " page on the UI/UX path. Are you ready to help me? Load in the relevant information you need from the uiux.json file that you need to assist me. Here is the content of the page: " +
          allText
      );
    }
  };

  useEffect(() => {
    fetchNotionPage();
  }, [pageId]);

  if (!recordMap) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <RootNoFooterPage header={"roadmap"} pathName={pathName}>
      <div className="flex items-center justify-center z-0">
        <NotionPage recordMap={recordMap} pageId={pageId} pathName={pathName} />
        <ChatInterface contextOnPath={contextOnPath} />
      </div>
    </RootNoFooterPage>
    // <>
    //   <NotionPage recordMap={recordMap} pageId={pageId} pathName={pathName} />
    //   <ChatInterface contextOnPath={contextOnPath} />
    // </>
  );
};

export default ParentComponent;
